import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getLiteralsForCulture } from '../../literals/Literals';
import { PricingLiterals } from '../../literals/pricing/PricingLiterals';
import { AppState } from '../AppState';
import { Price } from './Price';

interface PricingTierProps 
{
    planName: string;
    price: Price;
    volumeOfSubscriptions: string;
    appState: AppState;
    showingAnnual: boolean;
}
export class FlatFeePricingTier extends Component<PricingTierProps> {
    constructor(props: PricingTierProps) {
        super(props);
    }
    
    render() 
    {
        const literals = getLiteralsForCulture(PricingLiterals, this.props.appState.currentCulture) as any;
        
        const pricingBody = (<div  className="pricing-body">
                            <ul  className="pricing-features">
                                <li><span dangerouslySetInnerHTML={{__html: literals.propietaryAndCommercialUseLabel}}></span></li>
                                <li ><em >{this.props.volumeOfSubscriptions}</em> {literals.userSubscriptionsLabel}</li>
                                <li ><span dangerouslySetInnerHTML={{__html: literals.commercialSupportLabel}}></span><br/></li>
                                <li> <span dangerouslySetInnerHTML={{__html: literals.flatFeeDescLabel}}></span></li>
                                <li></li>
                            </ul>
                            </div>);

        const notAvailableBody = (<div  className="pricing-body">
                                <ul  className="pricing-features">
                                    <li>{literals.notAvailableLabel}<br/><br/><br/></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                                </div>);

        return (<ul  className="pricing-wrapper">
                    <li  className="is-visible is-ended" data-type="yearly">
                        <header  className="pricing-header">
                            <h2 >{this.props.planName}</h2>
                            <div  className="price">
                                <span  className="value">{this.props.showingAnnual ? this.props.price.amount : '-'}</span>
                                {this.props.showingAnnual && <span  className="currency">{this.props.price.currency.symbol} </span>}
                                {this.props.showingAnnual && <span  className="duration">{literals.yearLabel} </span>}
                            </div>
                        </header>
                        {this.props.showingAnnual ? pricingBody : notAvailableBody}
                        <footer  className="pricing-footer">
                            <Link className='select' to="/quote">{literals.getQuoteLabel}</Link>
                        </footer>
                    </li>
                </ul>);
    }
}