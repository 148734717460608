import React, { Component } from 'react';
import { HomeLiterals } from '../../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

class TestimonialsState
{
    currentTestimonialIndex: number = 0;
    maxTestimonials: number = 4;
}

export class Testimonials extends Component<AppProps, TestimonialsState> 
{
    constructor(props: AppProps)
    {
        super(props);
        this.state = new TestimonialsState();
    }

    componentDidMount()
    {
        var self = this;
        setInterval(function()
        {
            self.setState({
                currentTestimonialIndex: (self.state.currentTestimonialIndex + 1) % self.state.maxTestimonials
            })
        }, 5000);
    }

    render() {
    const literals = getLiteralsForCulture(HomeLiterals, this.props.appState.currentCulture) as any;
    
    return (

        <section className="theme-dark-gray bordered" style={{overflowX: "hidden"}}>
            <div className="row animation-element fade-in">
                <div className="col-md-12 text-center">
                    <h1>{literals.testimonials} <i className="bi bi-chat-quote-fill"></i></h1>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-8 col-sm-offset-2 col-md-offset-2 col-md-8 text-center" style={{minHeight: 200}}>
                    <div className="">
                        <h3 className={this.state.currentTestimonialIndex === 0 ? "testimonial-element fade-in in-view" : "testimonial-element fade-in"}> 
                            "Now that I discovered this way of working, I would NEVER go back to writing code without unit tests" 
                            <hr/>
                            <p>- Ben, former senior analyst a highly respected UK University, and now senior developer at a CRM Consultancy</p>
                        </h3>
                        <h3 className={this.state.currentTestimonialIndex === 1 ? "testimonial-element fade-in in-view" : "testimonial-element fade-in"}>
                            "Helps us massively with code coverage, and allows us to hit all of our KPIs alongside the rest of our engineering practice" 
                            <hr/>
                            <p>- Lead Developer at a Central Bank</p>
                        </h3>
                        <h3 className={this.state.currentTestimonialIndex === 2 ? "testimonial-element fade-in in-view" : "testimonial-element fade-in"}>
                            "Allows us to do more with the same number of developers." 
                            <hr/>
                            <p>- Technical Architect</p> 
                        </h3>
                        <h3 className={this.state.currentTestimonialIndex === 3 ? "testimonial-element fade-in in-view" : "testimonial-element fade-in"}>
                            "Cuts dev time down significantly as can test very easily. I find bugs almost instantly now rather than with other methods." 
                            <hr/>
                            <p>- Principal Architect</p> 
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}