import React, { Component } from 'react';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { CodeComponent } from './CodeComponent';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";
import { Link } from 'react-router-dom';

export class Queries extends Component {

  render () {
    var literals = getLiteralsForCulture(ContactLiterals, 'en-GB') as any;
    const textContent = `

//  Welcome to the Queries example!
//
//  Whenever you run a query with the fake service, it will be executed against the list of entities you passed in the .Initialize method.
//
//  This will allow you to unit test queries even, being able to detect potential issues in queries during development, not integration nor live.
//

[Fact]
public void QueryByAttributeExample()
{
    var fakedContext = new XrmFakedContext();
    var fakedService = fakedContext.GetOrganizationService();

    var contact = new Contact
    {
        Id = Guid.NewGuid(),
        FirstName = null,
        LastName = "asdf"
    };

    fakedContext.Initialize(new List<Entity> { contact });

    QueryByAttribute query = new QueryByAttribute("contact");
    query.ColumnSet = new ColumnSet("firstname", "lastname");
    var results = fakedService.RetrieveMultiple(query);

    Assert.True(results.Entities[0].Attributes.ContainsKey("lastname"));
    Assert.True(results.Entities[0].Attributes.ContainsKey("firstname"));
    Assert.Equal(null, results.Entities[0]["firstname"]);
}

[Fact]
public void QueryExpressionExample()
{
    var context = new XrmFakedContext { ProxyTypesAssembly = Assembly.GetExecutingAssembly() };
    var service = context.GetOrganizationService();

    service.Create(new Contact { FirstName = "Jimmy" });

    var qe = new QueryExpression("contact");
    qe.Criteria.AddCondition("firstname", ConditionOperator.Like, "JIM%");

    Assert.Equal(1, service.RetrieveMultiple(qe).Entities.Count);
}

[Fact]
public void FetchXmlExample()
{
    var context = new XrmFakedContext();
    context.ProxyTypesAssembly = Assembly.GetAssembly(typeof(Account));

    var contactId1 = Guid.NewGuid();
    var contactId2 = Guid.NewGuid();

    var account1 = new Account();
    account1.Id = Guid.NewGuid();
    account1.PrimaryContactId = new EntityReference(Contact.EntityLogicalName, contactId1);

    var account2 = new Account();
    account2.Id = Guid.NewGuid();
    account2.PrimaryContactId = new EntityReference(Contact.EntityLogicalName, contactId2);

    context.Initialize(new List<Entity>
    { account1, account2 });

    var fetchXml =
    @"<fetch>
            <entity name='account'>
                    <attribute name='name' />
                    <filter type='and'>
                            <condition attribute='primarycontactid' operator='eq' value='{0}' />
                    </filter>
            </entity>
    </fetch>";

    fetchXml = string.Format(fetchXml, contactId1);
    var rows = context.GetOrganizationService().RetrieveMultiple(new FetchExpression(fetchXml));
    Assert.Equal(rows.Entities.Count, 1);

}

[Fact]
public void LinqExample()
{
    var context = new XrmFakedContext { ProxyTypesAssembly = Assembly.GetExecutingAssembly() };
    var service = context.GetOrganizationService();

    service.Initialize(new List<Entity>() {
        new Contact() { Id = Guid.NewGuid(), FirstName = "Jimmy" }
    });

    using(var orgSrvContext = new XrmServiceContext(service)) {
        var contact = (from c in orgSrvContext.CreateQuery<Contact>()
                        where c.FirstName.StartsWith("Jim")
                        select c).FirstOrDefault();
            
        Assert.NotNull(contact);
    }
}
    `;

    return (
      <GetStarted isQueries={true}>
        <div>
        <div className="row">
            <h1>Unit testing with Queries</h1>
        </div>
        <div className="row">
            <p>FakeXrmEasy has a built-in query engine capable of running QueryByAttribute, QueryExpression, CRM LINQ and FetchXml queries.</p>
            <p>For a comparison between mocking queries with FakeXrmEasy and other existing .NET mocking frameworks, <Link to="/blog">check out this post!.</Link></p>
        </div>
        <div className="row">
            <CodeComponent code={textContent}></CodeComponent>
        </div>
      </div>
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
