import React from "react";


interface CardProperties {
    body: JSX.Element | string;
    title: JSX.Element | string;
    subtitle?: JSX.Element | string;
}
const Card = ({title, body, subtitle}: CardProperties) => {
    return (
        <div className="card text-white bg-dark border-dark">
            <div className="card-body">
                <h3 className="card-title mb-4">{title}</h3>
                {subtitle && <h6 className="card-subtitle mb-2 text-muted">{subtitle}</h6>}
                {body}
            </div>
        </div>
    );
}

export { Card };