const CookiesPolicyLiterals = 
{
    cookiesPolicyLabel: {
        "en-GB": "Cookies Policy",
        "es-ES": "Política de Cookies",
        "ca-ES": "Política de Cookies"
    },
    lastUpdate: {
        "en-GB": "December 9th, 2022",
        "es-ES": "9 de Diciembre de 2022",
        "ca-ES": "9 de Desembre de 2022"
    },
    cookieDefinitionTitle: {
        "en-GB": "Definition and Purpose of Cookies",
        "es-ES": "Definición y función de las cookies",
        "ca-ES": "Definició i funció de les cookies"
    },
    cookieDefinitionParagraph01: {
        "en-GB": "A cookie is a small block of data that is downloaded to your computer or device when you access certain web sites.",
        "es-ES": "Una cookie es un pequeño bloque de datos que se descarga en su ordenador o dispositivo al acceder a determinadas páginas web.",
        "ca-ES": "Una cookie és un petit bloc de dades que es descarrega al seu ordenador o dispositiu al accedir a determinades pàgines web."
    },
    cookieDefinitionParagraph02: {
        "en-GB": "Cookies allow a web page, among other things, to store and retrieve information about the browsing habits of a user on his computer or device and, depending on the information they contain and the way he uses his computer, they can be used to recognize the user.",
        "es-ES": "Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.",
        "ca-ES": "Les galetes permeten una pàgina web, entre altres coses, emmagatzemar i recuperar informació sobre els hàbits de navegació d'un usuari o del seu equip i, depenent de la informació que conté i de la forma en què utilitzi el seu equip, es poden utilitzar per reconèixer a l'usuari."
    },
    cookieDefinitionParagraph03: {
        "en-GB": "This website, like most sites on the Internet, uses cookies to improve and optimize the user experience. Below you will find detailed information on the type of cookies used by this website, on how you can disable them in your browser and how to specifically block the installation of third-party cookies.",
        "es-ES": "Este sitio web, al igual que la mayoría de los sitios en Internet, utiliza Cookies para mejorar y optimizar la experiencia del usuario. A continuación, encontrará información detallada sobre la tipología de Cookies que utiliza este sitio web, sobre cómo puede desactivarlas en su navegador y de cómo bloquear específicamente la instalación de Cookies de terceros.",
        "ca-ES": "Aquest lloc web, igual que la majoria dels llocs a Internet, utilitza galetes per millorar i optimitzar l'experiència de l'usuari. A continuació, trobareu informació detallada sobre la tipologia de Cookies que utilitza aquest lloc web, sobre com podeu desactivar-les al vostre navegador i de com bloquejar específicament la instal·lació de Cookies de tercers."
    },
    cookieDeclarationTitle: {
        "en-GB": "Cookies used on this website",
        "es-ES": "Cookies utilitzadas en este sitio web",
        "ca-ES": "Cookies utilitzades en aquest lloc web"
    },
    thirdPartyAccessTitle: {
        "en-GB": "Third parties with access to the data",
        "es-ES": "Terceros con accesso a los datos",
        "ca-ES": "Tercers amb accés a les dades"
    },
    disablingCookiesTitle: {
        "en-GB": "What happens if cookies are disabled",
        "es-ES": "Qué ocurre si se deshabilitan las cookies",
        "ca-ES": "Què passa si es deshabiliten les cookies"
    },
    disablingCookiesDesc: {
        "en-GB": "You can access your browser settings at any time to modify and/or block the installation of Cookies, but this may imply that some functionalities are disabled and that access to some content may be limited. The quality of the operation of the services may be affected.",
        "es-ES": "En todo momento podrá acceder a la configuración de su navegador para modificar y/o bloquear la instalación de las Cookies, pero ello puede implicar que algunas funcionalidades queden deshabilitadas y que pueda verse limitado el acceso a algunos contenidos. La calidad del funcionamiento de los servicios puede verse afectada.",
        "ca-ES": "En tot moment podreu accedir a la configuració del vostre navegador per modificar i/o bloquejar la instal·lació de les Cookies, però això pot implicar que algunes funcionalitats quedin deshabilitades i que es pugui veure limitat l'accés a alguns continguts. La qualitat del funcionament dels serveis es pot veure afectada."
    },
    howToDisableCookiesTitle: {
        "en-GB": "How to deactivate or remove cookies",
        "es-ES": "Forma de desactivar o eliminar las cookies",
        "ca-ES": "Forma de desactivar o eliminar les cookies"
    },
    howToDisableCookiesParagraph01: {
        "en-GB": "You can accept or reject the cookies used on the website through the configuration panel provided for this purpose by DYNAMICS VALUE, S.L. In addition, to allow, know, block or eliminate the cookies installed on your computer, you can do so by configuring the options of the browser installed on your computer. Keep in mind that if you accept third-party cookies, you must delete them from the browser options.",
        "es-ES": "Puede aceptar o rechazar las cookies utilizadas en el sitio web a través del panel de configuración dispuesto con tal finalidad por DYNAMICS VALUE, S.L. Además, para permitir, conocer, bloquear o eliminar las cookies instaladas en su equipo, puede hacerlo mediante la configuración de las opciones del navegador instalado en su ordenador. Tenga en cuenta que si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador.",
        "ca-ES": "Podeu acceptar o rebutjar les cookies utilitzades al lloc web a través del panell de configuració disposat amb tal finalitat per DYNAMICS VALUE, S.L. A més, per permetre, conèixer, bloquejar o eliminar les cookies instal·lades al vostre equip, podeu fer-ho mitjançant la configuració de les opcions del navegador instal·lat al vostre ordinador. Tingueu en compte que si accepteu les galetes de tercers, haureu d'eliminar-les des de les opcions del navegador."
    },
    howToDisableCookiesParagraph02: {
        "en-GB": "You can find information on how to do it if you use one of the following browsers:",
        "es-ES": "Puede encontrar información de cómo hacerlo en el caso que utilice uno de los siguientes navegadores: ",
        "ca-ES": "Podeu trobar informació de com fer-ho en el cas que utilitzeu un dels navegadors següents: "
    },
    howToDisableCookiesParagraph03: {
        "en-GB": "To obtain instructions on how to manage and delete cookies, go to the \"Help\" menu option of your browser.",
        "es-ES": "Para obtener indicaciones de cómo gestionar y eliminar las cookies, diríjase a la opción “Ayuda” de su navegador.",
        "ca-ES": "Per obtenir indicacions de com gestionar i eliminar les galetes, adreceu-vos a la opció “Ajuda” del vostre navegador."
    },
    crossBorderDataTransfersTitle: {
        "en-GB": "Cross Border Data Transfers",
        "es-ES": "Transferencias internacionales de datos",
        "ca-ES": "Transferències internacionals de dades"
    },
    crossBorderDataTransfersDesc: {
        "en-GB": "It is probable that, in case of resorting to third-party cookies, the data obtained from them may be transferred to third countries. You can find out about transfers to third countries made by third parties identified in this policy in their corresponding privacy policies.",
        "es-ES": "Es probable que, en caso de recurrir a cookies de terceros, los datos obtenidos de las mismas sean transferidos a terceros países. Puede informarse de las transferencias a terceros países que realicen los terceros identificados en esta política en sus correspondientes políticas de privacidad.",
        "ca-ES": "És probable que, en cas de recórrer a cookies de tercers, les dades obtingudes de les mateixes siguin transferides a tercers països. Podeu informar-vos de les transferències a tercers països que realitzin els tercers identificats en aquesta política en les seves polítiques de privacitat corresponents."
    },
    profilesTitle: {
        "en-GB": "Profiling",
        "es-ES": "Elaboración de perfiles",
        "ca-ES": "Elaboració de perfils"
    },
    profilesDesc: {
        "en-GB": "If you consent to the use of cookies for profiling advertising purposes, your browsing will be controlled to show you advertising based on your interests based on a profile created based on said browsing.",
        "es-ES": "Si consiente el uso de las cookies para fines de publicidad comportamental, se controlará su navegación para mostrarle publicidad basada en tus intereses a partir de un perfil elaborado en base a dicha navegación.",
        "ca-ES": "Si consenteix l'ús de les galetes per a fins de publicitat comportamental, es controlarà la seva navegació per mostrar publicitat basada en els teus interessos a partir d'un perfil elaborat sobre la base d'aquesta navegació."
    },
    dataRetentionTitle: {
        "en-GB": "Data Retention",
        "es-ES": "Período de conservación de los datos",
        "ca-ES": "Període de conservació de les dades"
    },
    dataRetentionDesc: {
        "en-GB": "The data will be processed while the authorizations granted by the user remain in force.",
        "es-ES": "Los datos serán tratados mientras permanezcan vigentes las autorizaciones otorgadas por el usuario.",
        "ca-ES": "Les dades seran tractades mentre romanguin vigents les autoritzacions atorgades per lusuari."
    },
    moreInfoTitle: {
        "en-GB": "More information about treatment of user data",
        "es-ES": "Más información acerca del tratamiento de datos del usuario",
        "ca-ES": "Més informació sobre el tractament de dades de l'usuari"
    },
    moreInfoDesc: {
        "en-GB": "The interested party can consult all the information about the processing of their data by DYNAMICS VALUE, S.L. in the Privacy Policy of our website.",
        "es-ES": "El interesado puede consultar toda la información acerca del tratamiento de sus datos por DYNAMICS VALUE, S.L. en la Política de Privacidad de nuestra página web.",
        "ca-ES": "L'interessat pot consultar tota la informació sobre el tractament de les dades per DYNAMICS VALUE, S.L. a la Política de Privadesa de la nostra pàgina web."
    },
    cookiePolicyUpdatesTitle: {
        "en-GB": "Update of our Cookies Policy",
        "es-ES": "Actualización de nuestra Política de Cookies",
        "ca-ES": "Actualització de la nostra Política de Cookies"
    },
    cookiePolicyUpdatesDesc: {
        "en-GB": "It is possible that we update the Cookies Policy of our Website, therefore we recommend that you review this policy each time you access our Website in order to be adequately informed about how and for what we use cookies. Please note that browsers can also change their policies and the location of the information.",
        "es-ES": "Es posible que actualicemos la Política de Cookies de nuestro Sitio Web, por ello le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies. Tengan en cuenta que los navegadores pueden también cambiar sus políticas y la ubicación de la información.",
        "ca-ES": "És possible que actualitzem la Política de Cookies del nostre Lloc Web, per això us recomanem revisar aquesta política cada vegada que accediu al nostre Lloc Web amb l'objectiu d'estar adequadament informat sobre com i per a què usem les cookies. Tingueu en compte que els navegadors també poden canviar les seves polítiques i la ubicació de la informació."
    },


};

interface ICookiesPolicyLiterals
{
    cookiesPolicyLabel: string;
    lastUpdate: string;
    cookieDefinitionTitle: string;
    cookieDefinitionParagraph01: string;
    cookieDefinitionParagraph02: string;
    cookieDefinitionParagraph03: string;
    cookieDeclarationTitle: string;
    thirdPartyAccessTitle: string;
    disablingCookiesTitle: string;
    disablingCookiesDesc: string;
    howToDisableCookiesTitle: string;
    howToDisableCookiesParagraph01: string;
    howToDisableCookiesParagraph02: string;
    howToDisableCookiesParagraph03: string;
    crossBorderDataTransfersTitle: string;
    crossBorderDataTransfersDesc: string;
    profilesTitle: string;
    profilesDesc: string;
    dataRetentionTitle: string;
    dataRetentionDesc: string;
    moreInfoTitle: string;
    moreInfoDesc: string;
    cookiePolicyUpdatesTitle: string;
    cookiePolicyUpdatesDesc: string;
}

export { CookiesPolicyLiterals };
export type { ICookiesPolicyLiterals };