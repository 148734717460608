import React, { Fragment } from "react";
import { Component } from "react"
import { RouteComponentProps, withRouter } from "react-router"
import { AppState } from "./AppState";
import { Footer } from "./Footer";

interface ScrollToTopProps extends RouteComponentProps<any>
{
  appState: AppState;
}
class ScrollToTop extends Component<ScrollToTopProps> {
    componentDidUpdate(prevProps: any) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      const isHome = this.props.location.pathname == '/' || this.props.location.pathname == '/home';

      return (isHome ? this.props.children : <Fragment>{this.props.children}<Footer appState={this.props.appState}></Footer></Fragment>);
    }
}

export default withRouter(ScrollToTop)