const ResetPasswordLiterals = {
    resetPasswordTitle: 
    {
        "en-GB": "Reset Password",
        "es-ES": "Cambiar contraseña",
        "ca-ES": "Canviar contrassenya"
    },
    resetPasswordSubHeading:
    {
        "en-GB": "Please, fill in your email and a brand new password. Password and confirm password fields must match.",
        "es-ES": "Por favor, introduce tu email y una nueva contraseña. Los campos contraseña y confirmar contraseña deben coincidir.",
        "ca-ES": "Si us plau, introdueix un email i una nova contrassenya. Els camps contrassenya i confirmar contrassenya han de coincidir."       
    },
    resetPasswordSent: 
    {
        "en-GB": "Thank you!",
        "es-ES": "Gracias!",
        "ca-ES": "Gràcies"
    },
    resetPasswordSentDesc: 
    {
        "en-GB": "Your password has been changed correctly. Please proceed to sign in with your new credentials",
        "es-ES": "Tu contraseña se cambió correctamente. Ya puedes iniciar sesión con tus nuevas credenciales.",
        "ca-ES": "La teva contrassenya s'ha canviat correctament. Ja pots iniciar sessió amb les teves noves credencials."
    },
};

export { ResetPasswordLiterals };
