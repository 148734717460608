import React, { useEffect, useState } from 'react';
import { DashboardLiterals } from '../../literals/account/DashboardLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { GenericResponse } from '../Api/GenericResponse';
import { AppProps } from '../AppState';
import { Help } from '../Help/Help';
import { Loading } from '../Loading';
import { UserProfile } from './UserProfile';



export const Dashboard = (appProps: AppProps) => 
{
  const [ isLoading, setIsLoading ] = useState(true);
  
  var literals = getLiteralsForCulture(DashboardLiterals, appProps.appState.currentCulture) as any;

  useEffect(() => {
    const getAuthenticated = async() => {
      await appProps.appState.isAuthenticated();
      setIsLoading(false);
    };
    getAuthenticated();
  }, []);
  
   return (<div>
            <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                      <h2>{literals.myAccountLabel }</h2>
                  </div>
              </div>
              {isLoading && <Loading />}
              {!isLoading && <div className="row">
                  <div className="col-md-offset-1 col-md-5">
                    <UserProfile appState={appProps.appState} ></UserProfile>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-offset-2 col-md-10">
                        <Help appState={appProps.appState} />
                      </div>
                    </div>
                </div>
              </div>}
            </section>
          </div>);
 }
      
