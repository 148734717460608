import React from 'react';
import { Link } from 'react-router-dom';
import { HomeLiterals } from '../../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';


export const ResearchStudy = (appProps: AppProps) => 
{
    const literals = getLiteralsForCulture(HomeLiterals, appProps.appState.currentCulture) as any;
    
    return (

        <section className="theme-dark-gray bordered home-research-study" style={{overflowX: "hidden"}}>
            <div className="row animation-element fade-in">
                <div className="col-md-12 text-center">
                    <h1>{literals.researchStudy} <i className="bi bi-file-text"></i></h1>
                </div>
            </div>

            <div className="row animation-element fade-in">
                <div className="col-md-12 text-center">
                      <p>{literals.researchStudyDescription}</p>
                      <p>{literals.outcome}</p>
                      
                </div>
            </div>

            <div className='row'>
                <div className="col-xs-6 col-sm-offset-2 col-sm-4 text-center">
                    <span dangerouslySetInnerHTML={{__html: literals.escapedDefectsReduction}}></span>
                </div>
                
                <div className="col-xs-6 col-sm-4 text-center">
                    <span dangerouslySetInnerHTML={{__html: literals.increasedEfficiency}}></span>
                </div>
            </div>

            <div className="row">
                <div className="col-xs-offset-2 col-xs-4 text-center">

                        <div className="animation-element fade-in gauge-container gauge-container-left gauge-container-scale">
                            <div className="gauge-a"></div>
                            <div className="gauge-b"></div>
                            <div className="gauge-c gauge-191"></div>
                            <div className="gauge-data">
                                <h1 id="percent">191%</h1>
                            </div>
                        </div>
                  
                    
                    
                </div>
                <div className="col-xs-4 text-center">

                        <div className="animation-element fade-in gauge-container gauge-container-right gauge-container-scale">
                            <div className="gauge-a"></div>
                            <div className="gauge-b"></div>
                            <div className="gauge-c gauge-33"></div>
                            <div className="gauge-data">
                                <h1 id="percent">33%</h1>
                            </div>
                        </div>
         
                </div>
            </div>

            <div className="row" style={{marginTop: '250px'}}>
                <div className="col-md-12 text-center">
                    <Link className="btn btn-lg btn-primary" to="/white-paper">{literals.getTheStudyNow}</Link>
                </div>
            </div>
            
        </section>
    );
  }
