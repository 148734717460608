const LicensingLiterals = 
{
    title: {
        "en-GB": "Licensing",
        "es-ES": "Licenciamiento",
        "ca-ES": "Llicenciament"
    },
    desc: {
        "en-GB": "You can find all information about licensing at our Docs site here: <a href='https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/' target='_blank'>https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/</a>",
        "es-ES": "Puedes encontrar información sobre el licenciamiento y preguntas frecuentes de nuestro software aquí (sólo en inglés) <a href='https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/' target='_blank'>https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/</a>",
        "ca-ES": "Pots trobar informació sobre el llicenciament i preguntes freqüents del nostre software aquí (només en anglès) <a href='https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/' target='_blank'>https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/</a>"
    }
};

interface ILicensingLiterals {
    title: string;
    desc: string;
};

export { LicensingLiterals };
export type { ILicensingLiterals };