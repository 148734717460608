import React from "react";
import { AppState } from "./AppState"

interface AppVersionProps {
    appState: AppState;
}

const AppVersion = ({appState} : AppVersionProps) => 
{
    return (
        <p style={{fontSize: "11px"}}>Version: {appState.settings.version}</p>
    );
};

export { AppVersion };