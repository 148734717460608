import React, { Component, FocusEvent } from 'react';

import { ValidationResult, DropwDownInputProps, InputState} from './types';

export class DropDownInput extends Component<DropwDownInputProps, {}> {

    constructor(props: DropwDownInputProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    validate = (value: string) : ValidationResult => 
    {
        if(this.props.isMandatory === true && value.trim() === '') 
        {
            return {
                isValid: false,
                errorMessage: this.props.mandatoryErrorMessage
            };  
        }

        return {isValid: true};
    }

    onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        var newValue = e.target.value;
        var inputState = new InputState();
        inputState.value = newValue;

        var validationResult = this.validate(newValue);
        inputState.isValid = validationResult.isValid;
        inputState.validationErrorMessage = validationResult.errorMessage;
        
        this.props.onChange(inputState);
    }

    render () {
        var validationResult = this.validate(this.props.value ? this.props.value : "");

        var options = this.props.options.map(o => <option key={o.value} value={o.value}>{o.label}</option>);

        if(validationResult.isValid || !this.props.displayValidation)
        {
            return (
                <div>
                <select className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value}>
                    {options}
                </select>
                </div>
            );
        }
        else 
        {
            return (
                <div className="has-error">
                <select className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value} >
                    {options}
                </select>
                <label data-formvalidator-renderedmessage>{validationResult.errorMessage}</label>
                </div>
            );
        }
    }
}
