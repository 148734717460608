import React from "react";

const Loading = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" style={{width: "4rem", height: "4rem"}}  role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export { Loading };

/*
<i className="bi-arrow-clockwise" style={{fontSize: "3em"}}></i>
*/