const ForgotPasswordLiterals = {
    resetPassword: {
        "en-GB": "Reset password",
        "es-ES": "Resetear contraseña",
        "ca-ES": "Restaurar contrassenya"
    },
    resetPasswordDesc: {
        "en-GB": "Please, fill in your email address. You'll receive a password reset link if your account is registered with us.",
        "es-ES": "Por favor, indica tu email. Recibirás un enlace en tu correo para cambiar la contraseña de tu cuenta si tu cuenta está registrada con nosotros.",
        "ca-ES": "Si us plau, indica el teu email. Rebràs un enllaç al teu email per a poder restaurar la teva contrassenya si el teu compte està registrat amb nosaltres."
    },
    forgotPasswordSent: {
        "en-GB": "Thank you!",
        "es-ES": "Gracias!",
        "ca-ES": "Gràcies"
    },
    forgotPasswordSentDesc: {
        "en-GB": "We have received your request successfully. If the email belongs to our records, you'll receive an email soon with further instructions.",
        "es-ES": "Hemos recibido tu solicitud correctamente. Si el email pertenece a unos de nuestros registros, en breve recibirás un correo con más instrucciones.",
        "ca-ES": "Hem rebut la teva sol·licitud correctament. Si el email pertany als nostres registres, en breu rebràs un email amb més instruccions."
    },
};

export { ForgotPasswordLiterals };