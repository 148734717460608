import React, { Component, ChangeEvent } from 'react';

import { ValidationResult, CheckBoxInputProps, CheckBoxInputState } from './types';

export class CheckBoxInput extends Component<CheckBoxInputProps, {}> {

    constructor(props: CheckBoxInputProps) {
        super(props);
        this.state = new CheckBoxInputState();

        this.onChange = this.onChange.bind(this);
    }

    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        var inputState = new CheckBoxInputState();
        inputState.checked = e.target.checked;

        var validationResult = this.validate(inputState.checked);
        inputState.isValid = validationResult.isValid;
        inputState.validationErrorMessage = validationResult.errorMessage;

        this.props.onChange(inputState);
    }

    validate = (checked: boolean) : ValidationResult => 
    {
        if(this.props.isMandatory === true && !checked) 
        {
            return {
                isValid: false,
                errorMessage: this.props.mandatoryErrorMessage
            };  
        }

        return {isValid: true};
    }

    render () {
        var validationResult = this.validate(this.props.checked != undefined ?  this.props.checked : false);

        const errorMessage = <label data-formvalidator-renderedmessage>{validationResult.errorMessage}</label>;

        const checkBox = (<div>
            <input type="checkbox" checked={this.props.checked} id={this.props.id} name={this.props.name} onChange={this.onChange} onBlur={this.onChange} style={{marginRight: "5px"}}/>
            {this.props.children}
        </div>);

        if(validationResult.isValid || !this.props.displayValidation)
        {
            return (
                <div>
                   {checkBox} 
                </div>
            );
        }
        else 
        {
            return (
                <div className="has-error">
                    {checkBox}
                    <br />
                    {errorMessage}
                </div>
            );
        }
    }
}
