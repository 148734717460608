import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getLiteralsForCulture } from '../../literals/Literals';
import { PricingLiterals } from '../../literals/pricing/PricingLiterals';
import { AppState } from '../AppState';
import { Price } from './Price';

interface PricingTierProps 
{
    planName: string;
    pricePerMonth: Price;
    pricePerYear: Price;
    volumeOfSubscriptions: string;
    showingAnnual: boolean;
    appState: AppState;
}
export class PricingTier extends Component<PricingTierProps> {
    constructor(props: PricingTierProps) {
        super(props);
    }
    
    render() 
    {
        const literals = getLiteralsForCulture(PricingLiterals, this.props.appState.currentCulture) as any;
        
        const pricingBody = (<div  className="pricing-body">
                            <ul  className="pricing-features">
                                <li><span dangerouslySetInnerHTML={{__html: literals.propietaryAndCommercialUseLabel}}></span></li>
                                <li ><em >{this.props.volumeOfSubscriptions}</em> {literals.userSubscriptionsLabel}</li>
                                <li ><span dangerouslySetInnerHTML={{__html: literals.commercialSupportLabel}}></span><br/><br/></li>
                                <li></li>
                                <li></li>
                            </ul>
                            </div>);

        return (<ul  className="pricing-wrapper">
                    <li  className="is-visible is-ended" data-type="yearly">
                        <header  className="pricing-header">
                            <h2 >{this.props.planName}</h2>
                            <div  className="price">
                                <span  className="value">{this.props.showingAnnual ? this.props.pricePerYear.amount : this.props.pricePerMonth.amount}</span>
                                <span  className="currency">{this.props.pricePerMonth.currency.symbol}  </span>
                                <span  className="duration">{literals.userLabel} </span>
                                <span  className="duration">{this.props.showingAnnual ? literals.yearLabel : literals.moLabel} </span>
                            </div>
                        </header>
                        {pricingBody}
                        <footer  className="pricing-footer">
                            <Link className='select' to="/quote">{literals.getQuoteLabel}</Link>
                        </footer>
                    </li>
                    
                </ul>);
    }
}