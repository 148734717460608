import React, { Component } from 'react';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, DropdownToggle, UncontrolledDropdown, NavbarText, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import dynamicsValueLogo from '../images/DynamicsValueLogoNav.svg';
import { AppState } from './AppState';
import { PostJson } from '../services/FetchService';
import { getLiteralsForCulture } from '../literals/Literals';
import { NavMenuLiterals } from '../literals/nav/NavMenuLiterals';


interface NavMenuProps
{
  appState: AppState;
}

class NavMenuState
{
  collapsed: boolean = true;
  redirect?: string = undefined;

  constructor()
  {

  }
}

export class NavMenu extends Component<NavMenuProps, NavMenuState> {
  static displayName = NavMenu.name;

  constructor (props: any) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.changeCultureEnglish = this.changeCultureEnglish.bind(this);
    this.changeCultureSpanish = this.changeCultureSpanish.bind(this);
    this.changeCultureCatalan = this.changeCultureCatalan.bind(this);
    this.signOut = this.signOut.bind(this);

    this.state = new NavMenuState();
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  changeCultureEnglish()
  {
    if(this.props.appState.changeCultureFn)
    {
      this.props.appState.changeCultureFn('en-GB');
    }
    this.toggleNavbar();
  }

  changeCultureSpanish()
  {
    if(this.props.appState.changeCultureFn)
    {
      this.props.appState.changeCultureFn('es-ES');
    }
    this.toggleNavbar();
  }

  changeCultureCatalan()
  {
    if(this.props.appState.changeCultureFn)
    {
      this.props.appState.changeCultureFn('ca-ES');
    }
    this.toggleNavbar();
  }

  signOut() 
  {
    PostJson("/api/account/signout", {}, this.props.appState, result => {
      if(result.succeeded)
      {
        if(this.props.appState.setAuthenticatedFn)
        {
          this.props.appState.setAuthenticatedFn(false);
        }
        this.setState({redirect: '/home'});
      }
    }, this.props.appState.handleApiErrorFn);
  }

  render () {
    if(this.state.redirect)
    {
      var redirectUrl = this.state.redirect;
      this.setState({
        redirect: undefined
      });
      return <Redirect to={redirectUrl} />;
    }

    var literals = getLiteralsForCulture(NavMenuLiterals, this.props.appState.currentCulture) as any;
    
    const isOpen = !this.state.collapsed;

    return (
      <div>
        <Navbar className="navbar-inverse navbar-fixed-top" expand="md">
          <NavbarBrand href="/"><img className="logo" src={dynamicsValueLogo} width={''}/></NavbarBrand>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink tag={Link} to="/" onClick={this.toggleNavbar}>{literals.homeLabel}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/white-paper" onClick={this.toggleNavbar}>{literals.whyLabel}</NavLink>
              </NavItem>
              <NavItem>
                <a className='nav-link' href="https://dynamicsvalue.github.io/fake-xrm-easy-docs/" target={"_blank"} onClick={this.toggleNavbar}>{literals.docsLabel}</a>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/get-started/overview" onClick={this.toggleNavbar}>{literals.getStartedLabel}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/customer-stories" onClick={this.toggleNavbar}>{literals.customerStoriesLabel}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/pricing" onClick={this.toggleNavbar}>{literals.pricingLabel}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/training" onClick={this.toggleNavbar}>{literals.trainingLabel}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/contact" onClick={this.toggleNavbar}>{literals.contactLabel}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/newsletter" onClick={this.toggleNavbar}>Newsletter</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {literals.languageSelected}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={this.changeCultureEnglish}>
                    English
                  </DropdownItem>
                  <DropdownItem onClick={this.changeCultureSpanish}>
                    Castellano
                  </DropdownItem>
                  <DropdownItem onClick={this.changeCultureCatalan}>
                    Català
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {this.props.appState.isLoggedIn && <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                {literals.myAccountLabel}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink tag={Link} to="/account/dashboard" onClick={this.toggleNavbar}>Dashboard</NavLink>
                    <NavLink tag={Link} to="/account/billing" onClick={this.toggleNavbar}>Billing</NavLink>
                  </DropdownItem>
                  <DropdownItem onClick={this.signOut}>
                    {literals.signOutLabel}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
