import React, { Component, FocusEvent } from 'react';

import { ValidationResult, EmailInputProps, InputState} from './types';
import { ValidationError } from './ValidationError';

export class EmailInput extends Component<EmailInputProps, {}> {

    private emailRegex: string = "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$";

    constructor(props: EmailInputProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    validate = (value: string) : ValidationResult => 
    {
        if(this.props.isMandatory === true && value.trim() === '') 
        {
            return {
                isValid: false,
                errorMessage: this.props.mandatoryErrorMessage
            };  
        }

        var regExp = new RegExp(this.emailRegex);
        if(!regExp.test(value)) {
            return {
            isValid: false,
            errorMessage: this.props.validationErrorMessage
            }; 
        }

        return {isValid: true};
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newValue = e.target.value;
        var inputState = new InputState();
        inputState.value = newValue;

        var validationResult = this.validate(newValue);
        inputState.isValid = validationResult.isValid;
        inputState.validationErrorMessage = validationResult.errorMessage;

        this.props.onChange(inputState);
    }

    render () {

        var validationResult = this.validate(this.props.value ? this.props.value : "");

        const isValidElement = validationResult.isValid ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-envelope" aria-hidden="true"></i>;
        const invalidElement = <i className="fa fa-ban" aria-hidden="true"></i>;

        const showValidationError = !(validationResult.isValid || !this.props.displayValidation);

        return (
            <div>
                <div className="input-group mb-3">
                    <input type="email" className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value} autoComplete={"off"} />
                    <div className="input-group-append">
                        {!showValidationError && <span className="input-group-text">{isValidElement}</span>}
                        {showValidationError && <span className="input-group-text">{invalidElement}</span>} 
                    </div>
                </div>
                {showValidationError && <ValidationError message={validationResult.errorMessage} />}
            </div>
        );
    }
}
