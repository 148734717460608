import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ConfirmEmailLiterals } from '../../literals/account/ConfirmEmailLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { getDefaultJsonAjaxHeaders } from '../../services/AuthService';
import { GenericResponse } from '../Api/GenericResponse';
import { AppProps, AppState } from '../AppState';
import * as qs from 'query-string';
import { Button } from 'reactstrap';

const ConfirmEmailAsync = async (appState: AppState, userId: string, code: string) => {
  const requestOptions = {
    method: "POST",
    headers: getDefaultJsonAjaxHeaders(appState),
    body: JSON.stringify({UserId: userId, Code: code})
  };

  var response = await fetch("/api/account/confirm-email", requestOptions);
  var result = await response.json() as GenericResponse;
  return result;
}

const ConfirmEmail = ({appState}: AppProps) => {
  const history = useHistory();
  const location = useLocation();
  const initialConfirmResult: GenericResponse = {
    succeeded: false,
    errorMessage: ""
  };
  const [ confirmResult, setConfirmResult ] = useState(initialConfirmResult);
  const [ isConfirming, setIsConfirming ] = useState(true);

  const query = qs.parse(location.search);

  const literals = getLiteralsForCulture(ConfirmEmailLiterals, appState.currentCulture) as any;

  const userId = query.userId ? query.userId as string : "";
  const code = query.code ? query.code as string : "";

  const navigateToSignIn = () => {
    history.push("/account/signin");
  };

  useEffect(() => {
    const fetchData = async() => {
      const result = await ConfirmEmailAsync(appState, userId, code);
      setConfirmResult(result);
      setIsConfirming(false);
    };
    fetchData();
  }, []);
  
  return (
    <div>
        <section className="fit theme-dark-gray">
            <div className="row">
                <div className="form-group col-md-offset-2 col-md-10">
                    <h2>{literals.confirmEmail}</h2>

                    {isConfirming && <p>{literals.confirmingLabel}</p>}
                    {!isConfirming && confirmResult.succeeded && 
                      <div>
                        <p>{literals.confirmed}</p>
                        <Button className="btn btn-primary" onClick={navigateToSignIn}>{literals.signIn}</Button>
                      </div>
                    }
                    {!isConfirming && !confirmResult.succeeded && 
                      <div>
                        <p>{literals.notConfirmed}</p>
                        <p>{confirmResult.errorMessage}</p>
                      </div>}
                </div>
            </div>
        </section>
    </div>
  );
}

export { ConfirmEmail }

