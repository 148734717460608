const WhitePaperLiterals = {

    researchStudyLabel: {
        "en-GB": "Research Study",
        "es-ES": "Estudio",
        "ca-ES": "Estudi"
    },
    researchStudyDescription: 
    {
        "en-GB": "With help from independent professional developers of different organizations, sizes and countries, we’ve conducted a research study to measure the impact FakeXrmEasy had on these companies.",
        "es-ES": "Con la ayuda de programadores profesionales independientes que trabajan para diferentes organizaciones de distintos tamaños y países, hemos realizado un estudio para medir el impacto que FakeXrmEasy tuvo en dichas organizaciones.",
        "ca-ES": "Amb l'ajuda de programadors professionals independents que treballen per a diferents organitzacions de diversos tamanys i països, hem realitzat un estudi per mesurar l'impacte que FakeXrmEasy ha tingut en aquestes organitzacions."
    },
    outcome: 
    {
        "en-GB": "The outcome of the study was:",
        "es-ES": "Las conclusiones del estudio fueron:",
        "ca-ES": "Les conclusions de l'estudi van ser:" 
    },
    escapedDefectsReduction: 
    {
        "en-GB": "<b>191% Pro Dev increased efficiency</b> due to the reduction of escaped defects*.",
        "es-ES": "<b>Eficiencia de desarrollo incrementada en un 191%</b> debido a la reducción de escape de bugs*.",
        "ca-ES": "<b>Eficiència de desenvolupament incrementada en un 191%</b> degut a la reducció d'escapament de bugs*." 
    },
    increasedEfficiency: 
    {
        "en-GB": "<b>33% Pro Dev increased efficiency</b> due to other time savings.",
        "es-ES": "<b>Eficiencia de desarrollo incrementada en un 33%</b> debido a otros factores.",
        "ca-ES": "<b>Eficiència de desenvolupament incrementada en un 33%</b> degut a altres factors." 
    },
    escapedDefectMeaning: 
    {
        "en-GB": "* : a 'escaped' defect, for the purposes of the study, is a bug that made it to subsequent environments because it wasn't identified during the development phase",
        "es-ES": "* : un bug 'escapado', es un defecto que llegó a otros entornos o fases porque no se llegó a identificar durante la fase de desarrollo",
        "ca-ES": "* : un bug 'escapat', es un defecte que s'ha arribat a desplegar a altres entorns o fases donat que no es va identificar durant la fase de desenvolupament" 
    },
    getTheStudyAtYourInbox: 
    {
        "en-GB": "Want to know more? Get the study in PDF delivered to your inbox by filling in your email address below:",
        "es-ES": "¿Quiere saber más? Obtenga el estudio en PDF en su correo, sin ningún compromiso.",
        "ca-ES": "Vol saber més? Obtingui l'estudi en PDF al seu correu, sense cap compromís." 
    },
    emailAddressLabel: 
    {
        "en-GB": "Email Address",
        "es-ES": "Correo electrónico",
        "ca-ES": "Correu electrònic"
    },
    firstNameLabel: 
    {
        "en-GB": "First Name",
        "es-ES": "Nombre",
        "ca-ES": "Nom"
    },
    messageLabel: 
    {
        "en-GB": "Message",
        "es-ES": "Mensaje",
        "ca-ES": "Missatge"
    },
    emailNotValidLabel: {
        "en-GB": "Please, email doesn't have a valid format",
        "es-ES": "Por favor, el email no tiene un formato adecuado",
        "ca-ES": "Si us plau, el email no té un format adequat"
    },
    emailMandatoryErrorLabel: {
        "en-GB": "Please, email is mandatory",
        "es-ES": "Por favor, introduce un correo electrónico",
        "ca-ES": "Si us plau, introdueix un correu electrònic"
    },
    yourEmailPlaceHolder: {
        "en-GB": "youremail@mail.com",
        "es-ES": "tuemail@mail.com",
        "ca-ES": "elteuemail@mail.com"
    },
    firstNameMandatoryErrorLabel: {
        "en-GB": "Please, first name is mandatory",
        "es-ES": "Por favor, introduce un nombre",
        "ca-ES": "Si us plau, introdueix un nom"
    },
    messageMandatoryErrorLabel: {
        "en-GB": "Please, message is mandatory",
        "es-ES": "Por favor, introduce un mensaje",
        "ca-ES": "Si us plau, introdueix un missatge"
    },
    messageHerePlaceholder: 
    {
        "en-GB": "Your message here...",
        "es-ES": "Escribe aquí tu mensaje...",
        "ca-ES": "Escriu aquí el teu missatge..."
    },
    dontAllowEmailMarketingLabel: 
    {
        "en-GB": "I do not wish to receive the latest news about products, services or announcements that may interest me.",
        "es-ES": "No deseo recibir información de las úlimas noticias sobre productos, servicios o novedades que me puedan interesar",
        "ca-ES": "No vull rebre informació de les últimes notícies sobre productes, serveis o novetats que em puguin interessar"
    },
    studySentLabel: {
        "en-GB": "Research Study has been Sent",
        "es-ES": "Estudio Enviado",
        "ca-ES": "Estudi Enviat"
    },
    contactSentParagraphLabel: {
        "en-GB": "Thanks a million for getting in touch. You should receive an email shortly with the white paper attached. Any questions please do let us know.",
        "es-ES": "Muchísimas gracias solicitar el estudio. En breve lo deberías recibir en tu email. Si tienes cualquier duda, por favor, háznoslo saber.",
        "ca-ES": "Moltíssimes gràcies per sol·licitar el nostre estudi. En breu l'hauries de rebre al teu email. Si tens qualsevol dubte, si us plau, fes-nos-ho saber."
    },
    contactGetBackParagraphLabel: {
        "en-GB": "We'll review your request and we'll get back to you as soon as possible.",
        "es-ES": "Revisaremos to solicitud de contacto y nos pondremos en contacto contigo tan pronto como sea posible.",
        "ca-ES": "Revisarem la teva sol·licitud de contacte i ens posarem amb contacte tant aviat com sigui possible."
    },
    consentText: {
        "en-GB": "<b>Consent</b>. We need your email to send you the white paper to your inbox and any other questions that may arise from it. You can exercise your rights according to our <a href='/privacy-policy' target='_blank'>Privacy Policy</a>",
        "es-ES": "<b>Consiento el tratamiento de mis datos</b>. Trataremos sus datos con la finalidad de enviarle nuestro estudio y cualquier otra consulta relacionada que pudiera derivarse. Puede ejercer sus derechos de acceso, modificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra <a href='/privacy-policy' target='_blank'>Política de Privacidad y Aviso Legal</a>",
        "ca-ES": "<b>Consentiment tractament de dades</b>. Trataremos sus datos con la finalidad de d'enviar-li l'estudi i qualsevol altra consulta que es derivi d'aquest. Puede ejercer sus derechos de accesso, modificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra <a href='/privacy-policy' target='_blank'>Política de Privacidad y Aviso Legal</a>"
    },
    consentNeeded: {
        "en-GB": "Please, we need your consent.",
        "es-ES": "Por favor, necesitamos su consentimiento.",
        "ca-ES": "Si us plau, necessitem el seu consentiment."
    },
    mustAcceptedTosLabel: {
        "en-GB": " Please, we need your consent.",
        "es-ES": " Por favor, necesitamos su consentimiento.",
        "ca-ES": " Si us plau, necessitem el seu consentiment."
    },
    submitLabel: {
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "ca-ES": "Envia"
    },
    submittingLabel: {
        "en-GB": "Submitting...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    }

};


export { WhitePaperLiterals };