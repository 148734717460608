import React, { Component } from 'react';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { CodeComponent } from './CodeComponent';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";

export class HowToTestAPlugin extends Component {

  render () {
    var literals = getLiteralsForCulture(ContactLiterals, 'en-GB') as any;
    const textContent = `
//  Welcome to your First Plugin Example
//
//  There are different method overloads you can use to test Dynamics CRM plugins
//
//  We are going to start with the most basic one....
//
//  If you just want to pass an Entity target, then just use ExecuPluginWithTarget. 
//
//  However, if you need to inject plugin steps (like PreOperation) or messages (like "Create" or "Update") 
//
//  because your plugins needs them, better to use the general purpose method where you can pass a 
//  
//  default plugin context.
//
//  ExecutePluginWithTarget
//  
//  This method executes a plugin passing an entity as the target
//
//  Here is an example which uses the AccountNumberPlugin from the SDK which
//  just adds a new attribute to an account record
//

[Fact]
public void When_the_account_number_plugin_is_executed_it_adds_a_random_number_to_an_account_entity()
{
    var fakedContext = new XrmFakedContext();

    var guid1 = Guid.NewGuid();
    var target = new Entity("account") { Id = guid1 };

    //Execute our plugin against a target that doesn't contains the accountnumber attribute
    var fakedPlugin = fakedContext.ExecutePluginWithTarget<AccountNumberPlugin>(target);

    //Assert that the target contains a new attribute
    Assert.True(target.Attributes.ContainsKey("accountnumber"));

}

//  If you need to not only pass the target, but also collect some output parameters,
//  there is another overload which you can use to pass input and output parameters
//
//  For example, the FollowUpPlugin from the SDK creates a new task for an account when an account is created,
//  and then associates the resulting account Id (output parameter) to the task's Regarding Object Id
//
//

[Fact]
public void When_the_followup_plugin_is_executed_for_an_account_after_create_it_should_create_a_new_task_with_a_regardingid()
{
    var fakedContext = new XrmFakedContext();
    fakedContext.ProxyTypesAssembly = Assembly.GetExecutingAssembly(); //Needed to be able to return early bound entities

    var guid1 = Guid.NewGuid();
    var target = new Entity("account") { Id = guid1 };

    ParameterCollection inputParameters = new ParameterCollection();
    inputParameters.Add("Target", target);

    ParameterCollection outputParameters = new ParameterCollection();
    outputParameters.Add("id", guid1);

    fakedContext.ExecutePluginWith<FollowupPlugin>(inputParameters,outputParameters,null,null);

    //The plugin creates a followup activity, check that that one exists
    var tasks = (from t in fakedContext.CreateQuery<Task>()
    select t).ToList();

    Assert.True(tasks.Count == 1);
    Assert.True(tasks[0].Subject.Equals("Send e-mail to the new customer."));
    Assert.True(tasks[0].RegardingObjectId != null && tasks[0].RegardingObjectId.Id.Equals(guid1));
}

//  There will be cases where you even need to check some properties of the plugin context within your plugin
//  for example, the message name (Create, Update, Associate, ...), or primary or secondary entity names,
//  the plugin depth, initiating user id,... etc
//
//
//  If that's the case, you can use the GetDefaultPluginContext which will retrieve a plugin context
//  which will use some default properties you can override to suit your needs
//
//

[Fact]
public void When_initializing_the_context_with_Properties_Plugins_Can_Access_It()
{
    var context = new XrmFakedContext();

    ParameterCollection inputParameters = new ParameterCollection();
    inputParameters.Add("Target", new Entity());

    //Let's get a default plugin context, and then tell the framework this plugin context emulates a "Create" message with a target entity
    var plugCtx = context.GetDefaultPluginContext();
    plugCtx.MessageName = "Create";
    plugCtx.InputParameters = inputParameters;

    Assert.DoesNotThrow(() => context.ExecutePluginWith<TestContextPlugin>(plugCtx));
}

//  You can use that method then to pass other custom plugin context properties (images, depth, message names, etc..)


//  Finally,...
//  If you have plugins with secure or insecure configurations, there is a method for that too:

[Fact]
public void When_A_Plugin_Is_Executed_Configurations_Can_Be_Used()
{
    var fakedContext = new XrmFakedContext();

    var guid1 = Guid.NewGuid();
    var target = new Entity("contact") { Id = guid1 };

    var inputParams = new ParameterCollection { new KeyValuePair<string, object>("Target", target) };

    var unsecureConfiguration = "Unsecure Configuration";
    var secureConfiguration = "Secure Configuration";

    //Execute our plugin against the selected target
    var plugCtx = fakedContext.GetDefaultPluginContext();
    plugCtx.InputParameters = inputParams;

    fakedContext.ExecutePluginWithConfigurations<ConfigurationPlugin>(plugCtx, unsecureConfiguration, secureConfiguration);

    Assert.True(target.Contains("unsecure"));
    Assert.True(target.Contains("secure"));
    Assert.Equal((string)target["unsecure"], unsecureConfiguration);
    Assert.Equal((string)target["secure"], secureConfiguration);
}

//  And... that's it!
//
//  As you can see, you can test plugins using many different ways
//
//  This covers examples about plugins...
//  if you want to know how to test code activities..
//  then please click on the Code Activities section.
//
//  Happy Testing!
//

    `;

    return (
      <GetStarted isHowToTestAPlugin={true}>
        <div>
        <div className="row">
            <h1>How to Test a Plugin</h1>
        </div>
        <div className="row">
            <CodeComponent code={textContent}></CodeComponent>
        </div>
      </div>
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
