var HomeLiterals = {
    keepYourCodeLabel: 
    {
        "en-GB": "Painful regression ",
        "es-ES": "¿Pierdes demasiado tiempo en ",
        "ca-ES": "Malgastes massa temps en "
    },
    greenLabel: {
        "en-GB": "bugs?",
        "es-ES": "bugs?",
        "ca-ES": "bugs?"
    },
    getStartedWithFakeXrmEasyLabel: 
    {
        "en-GB": "Get Started with FakeXrmEasy",
        "es-ES": "Empieza con FakeXrmEasy",
        "ca-ES": "Comença amb FakeXrmEasy"
    },
    dynamicsCrmAutomatedFrameworkLabel: {
        "en-GB" : "Boost your productivity with the leading OSS test driven development framework for Dynamics 365 / Dataverse",
        "es-ES" : "Maximiza la productividad con el framework open source líder de desarrollo guiado por pruebas para Dynamics 365 / Dataverse",
        "ca-ES" : "Maximitza la productivitat amb el framework open source líder de desenvolupament guiat per proves per a Dynamics 365 / Dataverse"
    },
    easyFastFreeFoereverLabel: {
        "en-GB" : "Trusted by Tech Giants. Loved by Developers.",
        "es-ES" : "Con la confianza de las Grandes Tecnológicas. Amado por programadores.",
        "ca-ES" : "Amb la confiança de les Grans Tecnològiques. Amat per programadors",
    },
    whatIsFakeXrmEasyLabel: {
        "en-GB": "What is Fake Xrm Easy?",
        "es-ES": "¿Qué es Fake Xrm Easy?",
        "ca-ES": "Què és Fake Xrm Easy?"
    },
    fakeXrmEasyDescLabel: {
        "en-GB": "FakeXrmEasy is an open source framework built on top of Fake It Easy which adds a layer specifically designed for Dynamics 365 / Dataverse to mock IOrganizationService calls for you. It keeps track of every entity from plugins, code activities, queries, and any other 3rd party app accessing the IOrganizationService in a context which runs In Memory, blazingly fast.",
        "es-ES": "FakeXrmEasy es un framework de código abierto construido sobre Fake It Easy, que añade una capa diseñada específicamente para Dynamics 365 / Dataverse para interceptar llamadas a IOrganizationService por ti. También realiza un seguimiento de todas las entidades desde plugins , code activities , consultas y cualquier otra aplicación de terceros que use el OrganizationService, en un contexto que se ejecuta en memoria, super rápido.",
        "ca-ES": "FakeXrmEasy és un framework de codi obert construït sobre Fake It Easy , que afegeix una capa dissenyada específicament per Dynamics 365 / Dataverse per interceptar crides al IOrganizationService per tu. També realitza un seguiment de totes les entitats des de plugins, code activities , consultes i qualsevol altra aplicació de tercers que utilitzi el OrganizationService, en un context que s'executa en memòria, super ràpid."
    },
    fakeXrmEasyHealthyLabel: {
        "en-GB": "FakeXrmEasy supports both server side development with the SDK and client-side development with the brand new DataverseClient, and it helps you effectively maintain a healthy Dataverse / CRM ecosystem by reducing the time needed to write tests for:",
        "es-ES": "FakeXrmEasy soporta tanto código de servidor usando el SDK tradicional como código cliente usando el nuevo DataverseClient en .net core, y le ayuda a mantener efectivamente un ecosistema Dataverse / CRM saludable al reducir el tiempo necesario para escribir pruebas para:",
        "ca-ES": "FakeXrmEasy suporta tant codi servidor fent servir el SDK tradicional com codi client fent servir el nou DataverseClient en .net core, i l'ajuda a mantenir efectivament un ecosistema Dataverse / CRM saludable en reduir el temps necessari per escriure proves per:"
    },
    otherLabel: { 
        "en-GB": "Other",
        "es-ES": "Otros",
        "ca-ES": "Altres"
    },
    whyFakeXrmEasyLabel: {
        "en-GB": "Why Fake Xrm Easy?",
        "es-ES": "¿Por qué Fake Xrm Easy?",
        "ca-ES": "Per què Fake Xrm Easy?"
    },
    forYourBusinessLabel: {
        "en-GB": "For Your Business",
        "es-ES": "Para su Negocio",
        "ca-ES": "Per al seu Negoci"
    },
    qualityLabel: {
        "en-GB": "Quality",
        "es-ES": "Calidad",
        "ca-ES": "Qualitat"
    },
    confidenceLabel: {
        "en-GB": "Confidence",
        "es-ES": "Confianza",
        "ca-ES": "Confiança"
    },
    efficiencyLabel: {
        "en-GB": "Efficiency",
        "es-ES": "Eficiencia",
        "ca-ES": "Eficiència",
    },
    addedvalueLabel: {
        "en-GB": "Added Value",
        "es-ES": "Valor Añadido",
        "ca-ES": "Valor Afegit"
    },
    qualityDescLabel: {
        "en-GB": "With FakeXrmEasy your organization will develop more robust code, avoiding issues in the earliest stages of your development lifecycle.",
        "es-ES": "Con FakeXrmEasy su organización desarrollará código más robusto, evitando incidencias en la fase más temprana de sus evolutivos: el desarrollo.",
        "ca-ES": "Amb FakeXrmEasy la seva organització desenvoluparà codi més robust, evitant incidències en la fase més primerenca de les seus evolutius: el desenvolupament."
    },
    confidenceDescLabel: {
        "en-GB": "Because bugs can be detected even earlier to put your code into production, it helps build confidence in your solutions from a customer perspective.",
        "es-ES": "Porque los bugs pueden ser detectados incluso previamente a las puestas en producción, se genera una confianza en un software más robusto.",
        "ca-ES": "Perquè els bugs poden ser detectats fins i tot prèviament a les posades en producció , es genera una confiança en un programari més robust."
    },
    efficiencyDescLabel: {
        "en-GB": "Since development teams in your organization can run hundreds of automated tests in seconds .",
        "es-ES": "Dado que sus equipos de desarrollo pueden ejecutar cientos de tests en cuestión de segundos, de manera totalmente automatizada.",
        "ca-ES": "Atès que els equips de desenvolupament a la seva organització poden executar centenars de tests en qüestió de segons, de manera totalment automatitzada ."
    },
    addedValueDescLabel: {
        "en-GB": "Obtained from the improved efficiency of performing much shorter development lifecycles, making it possible to deliver much more functionality than ever.",
        "es-ES": "Que se obtiene de la mejora en eficiencia al poder realizar ciclos evolutivos mucho más cortos, siendo posible entregar mucho más funcionalidad que antes, mucho antes.",
        "ca-ES": "Que s'obté de la millora en eficiència en poder realitzar cicles evolutius molt més curts, sent possible lliurar molta més funcionalitat que abans, molt abans."
    },
    forDevelopersLabel: {
        "en-GB": "For Pro Developers",
        "es-ES": "Para Pro Developers",
        "ca-ES": "Per a Pro Developers",
    },
    testLabel: {
        "en-GB": "Apply test-driven best practices...",
        "es-ES": "Aplica buenas prácticas de desarrollo orientado por pruebas...",
        "ca-ES": "Aplica bones pràctiques de desenvolupament orientat per proves...",
    },
    fromVisualStudioLabel: {
        "en-GB": "From Visual Studio",
        "es-ES": "Desde Visual Studio",
        "ca-ES": "Des de Visual Studio"
    },
    withoutBeingAnExpertLabel: {
        "en-GB": "Without being an expert",
        "es-ES": "Sin ser un experto",
        "ca-ES": "Sense ser un expert"
    },
    superFastLabel: {
        "en-GB": "Super Fast",
        "es-ES": "Super Rápido",
        "ca-ES": "Super Ràpid"
    },
    enjoyLabel: {
        "en-GB": "Enjoy",
        "es-ES": "Disfruta",
        "ca-ES": "Gaudeix"
    },
    fromVisualStudioDescLabel: {
        "en-GB": "With FakeXrmEasy you can test your plugins and code activities straight from Visual Studio. This is much more convenient and effective than to open a bunch of different windows in Dynamics / Dataverse just to test a small part of the functionality which is relevant for your scenarios.",
        "es-ES": "Con FakeXrmEasy puedes testear tus plugins y workflow activities directamente desde Visual Studio. Esto es mucho más comodo y eficaz que abrir infinidad de ventanas en Dynamics / Dataverse para sólo acabar testeando una pequeña parte de la funcionalidad.",
        "ca-ES": "Amb FakeXrmEasy pots testejar els vostres connectors i workflow activities directament des de Visual Studio. Això és molt més còmode i eficaç que obrir infinitat de finestres en Dynamics / Dataverse només per acabar testejant una petita part de la funcionalitat."
    },
    withoutBeingAnExpertDescLabel: {
        "en-GB": "Write tests using the IOrganizationService, something you are already familiar with, and by using a framework that handles mocks or fakes for you.",
        "es-ES": "Escribe tests usando la interfaz IOrganizationService, algo que ya conoces, y además usando un framework que se encarga de hacer mocks o fakes por ti. ",
        "ca-ES": "Escriu tests usant la interfaz IOrganizationService, cosa que ja coneixes, i a més utilitzant un framework que s'encarrega de fer els mocks o fakes per tu."
    },
    superFastDescLabel: {
        "en-GB": "Blazingly fast. Because you can run hundreds of tests from Visual Studio in a matter of seconds. No kidding.",
        "es-ES": "Como el rayo. Dado que puedes ejecutar cientos de tests desde Visual Studio en cuestión de segundos. No es broma.",
        "ca-ES": "Com un llamp. Atès que pots executar centenars de tests des de Visual Studio en qüestió de segons. No és broma."
    },
    enjoyDescLabel: {
        "en-GB": "Forget about bugs or the stress of live issues. By re-running your tests as soon as you introduce changes you will be able to see many errors even before the testers. Ah! And without debugging.",
        "es-ES": "Olvídate de bugs o el estrés de incidencias en producción. Al introducir cambios en el sistema y volver a ejecutar tus tests serás capaz de ver posibles errores incluso antes que los testers. Ah! Y sin depurar.",
        "ca-ES": "Oblida't de bugs o l'estrès d'incidències en producció. TNat aviat com introduexis canvis al sistema i tornis a executar els teus tests, seràs capaç de veure possibles errors fins i tot abans que els testers. Ah! I sense depurar."
    },
    backedByLabel: {
        "en-GB": "Backed by 4000+ tests",
        "es-ES": "Soportado por 4000+ tests",
        "ca-ES": "Suportat per 4000+ tests"
    },
    communityLabel: {
        "en-GB": "Community",
        "es-ES": "Comunidad",
        "ca-ES": "Comunitat"
    },
    testimonials: {
        "en-GB": "Testimonials",
        "es-ES": "Testimonios",
        "ca-ES": "Testimonis"
    },
    researchStudy: {
        "en-GB": "Research Study",
        "es-ES": "Estudio",
        "ca-ES": "Estudi"
    },
    getTheStudyNow: {
        "en-GB": "Get the White Paper Now",
        "es-ES": "Obtenga el Estudio Ahora",
        "ca-ES": "Obtingui l'Estudi Ara"
    },
    researchStudyDescription: 
    {
        "en-GB": "With help from independent professional developers of different organizations, sizes and countries, we’ve conducted a research study to measure the impact FakeXrmEasy had on these companies.",
        "es-ES": "Con la ayuda de programadores profesionales independientes que trabajan para diferentes organizaciones de distintos tamaños y países, hemos realizado un estudio para medir el impacto que FakeXrmEasy tuvo en dichas organizaciones.",
        "ca-ES": "Amb l'ajuda de programadors professionals independents que treballen per a diferents organitzacions de diversos tamanys i països, hem realitzat un estudi per mesurar l'impacte que FakeXrmEasy ha tingut en aquestes organitzacions."
    },
    outcome: 
    {
        "en-GB": "The outcome of the study was:",
        "es-ES": "Las conclusiones del estudio fueron:",
        "ca-ES": "Les conclusions de l'estudi van ser:" 
    },
    escapedDefectsReduction: 
    {
        "en-GB": "<b>191% Pro Dev increased efficiency</b> <br/> due to the reduction of escaped defects*.",
        "es-ES": "<b>Eficiencia de desarrollo incrementada en un 191%</b> <br/> debido a la reducción de escape de bugs*.",
        "ca-ES": "<b>Eficiència de desenvolupament incrementada en un 191%</b> <br/> degut a la reducció d'escapament de bugs*." 
    },
    increasedEfficiency: 
    {
        "en-GB": "<b>33% Pro Dev increased efficiency</b> <br/> due to other time savings.",
        "es-ES": "<b>Eficiencia de desarrollo incrementada en un 33%</b> <br/>debido a otros factores.",
        "ca-ES": "<b>Eficiència de desenvolupament incrementada en un 33%</b> <br/>degut a altres factors." 
    },
    escapedDefectMeaning: 
    {
        "en-GB": "* : a 'escaped' defect, for the purposes of the study, is a bug that made it to subsequent environments because it wasn't identified during the development phase",
        "es-ES": "* : un bug 'escapado', es un defecto que llegó a otros entornos o fases porque no se llegó a identificar durante la fase de desarrollo",
        "ca-ES": "* : un bug 'escapat', es un defecte que s'ha arribat a desplegar a altres entorns o fases donat que no es va identificar durant la fase de desenvolupament" 
    },
    getQuoteLabel: 
    {
        "en-GB": "Get a Quote",
        "es-ES": "Solicite Presupuesto",
        "ca-ES": "Demani pressupost" 
    }
};

export { HomeLiterals };