import React, { Component, FocusEvent } from 'react';

import { ValidationResult, TextInputProps, InputState} from './types';

export class PasswordInput extends Component<TextInputProps, {}> {

    constructor(props: TextInputProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    validate = (value: string) : ValidationResult => 
    {
        if(this.props.isMandatory === true && value.trim() === '') 
        {
            return {
                isValid: false,
                errorMessage: this.props.mandatoryErrorMessage
            };  
        }

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if(!strongRegex.test(value)) {
            return {
            isValid: false,
            errorMessage: this.props.validationErrorMessage
            }; 
        }

        return {isValid: true};
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newValue = e.target.value;
        var inputState = new InputState();
        inputState.value = newValue;

        var validationResult = this.validate(newValue);
        inputState.isValid = validationResult.isValid;
        inputState.validationErrorMessage = validationResult.errorMessage;

        this.props.onChange(inputState);
    }

    render () {
        const validationResult = this.validate(this.props.value ? this.props.value : "");
        
        const errorMessage =  validationResult.errorMessage;
        
        const isValidElement = validationResult.isValid ? <i className="fa fa-check" aria-hidden="true"></i> : <span></span>;
        const invalidElement = <i className="fa fa-ban" aria-hidden="true"></i>;

        if(validationResult.isValid || !this.props.displayValidation)
        {
            return (
                <div className="input-group">
                    <input type="password" className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value} autoComplete="new-password" />
                    <div className="input-group-append">
                        <span className="input-group-text">{isValidElement}</span>
                    </div>
                </div>
            );
        }
        else 
        {
            return (
                <div className="has-error">
                    <div className="input-group">
                        <input type="password" className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value} autoComplete="new-password" />
                        <div className="input-group-append">
                            <span className="input-group-text">{invalidElement}</span>
                        </div>
                    </div>
                    <label data-formvalidator-renderedmessage>{errorMessage}</label>
                </div>
            );
        }
    }
}
