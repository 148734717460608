const BasicDetailsLiterals = {

    myProfileTitle: {
        "en-GB": "My Profile",
        "es-ES": "Mi Perfil",
        "ca-ES": "El meu Perfil"
    },
    firstName: {
        "en-GB": "First Name",
        "es-ES": "Nombre",
        "ca-ES": "Nom"
    },
    lastName: {
        "en-GB": "Last Name",
        "es-ES": "Apellidos",
        "ca-ES": "Cognoms"
    },
    language: {
        "en-GB": "Preferred Language",
        "es-ES": "Idioma preferido",
        "ca-ES": "Idioma preferit"
    },
    save: {
        "en-GB": "Save",
        "es-ES": "Guardar",
        "ca-ES": "Guardar"
    },
    saving: {
        "en-GB": "Saving...",
        "es-ES": "Guardando...",
        "ca-ES": "Guardant..."
    }
};

interface IBasicDetailsLiterals
{
    myProfileTitle: string;
    firstName: string;
    lastName: string;
    language: string;
    save: string;
    saving: string;
};

export { BasicDetailsLiterals };
export type { IBasicDetailsLiterals };
