var PricingLiterals = {

    pricingLabel: 
    {
        "en-GB": "Pricing",
        "es-ES": "Precios",
        "ca-ES": "Preus"
    },
    pricingDescLabel: 
    {
        "en-GB": "Pricing is tiered based on the number of developers that will use FakeXrmEasy versions 2 or later, with different tiers based on the team size below using a license subscription model. For example, for 13 users, you would pay 5 users at the Boutique plan, 5 users at the Team plan, and 3 users at the Large plan. For above 16 users and above, the Business, Enterprise or Ultimate options might be better.",
        "es-ES": "El precio es un modelo suscripción escalonado que va en función del número de desarrolladores que usen FakeXrmEasy versión 2 o posterior. Abajo puedes encontrar los diferentes planes. Por ejemplo, para 13 usuarios, pagarías 5 en el plan Boutique, 5 en el plan Team, y 3 en el plan Large. Para más usuarios quizá te convenga más las opciones Business, Enterprise or Ultimate.",
        "ca-ES": "El preu és un model de subscripció esglaonat que va en funció del nombre de programadors que hagin que fer servir FakeXrmEasy versió 2 o posterior. Per exemple, per a 13 usuaris, pagaries 5 al preu Boutique, 5 al preu Team i 3 al preu Large. Per a més usuaris, potser et convingui més les opcions Business, Enterprise o Ultimate."
    },
    costEffectivePricingLabel: 
    {
        "en-GB": "The subscription includes all the frequent new features and fixes, very detailed documentation, and up to date release notes so you don't have to maintain these yourself. Please see details of increased developer efficiency from our research study ",
        "es-ES": "La suscripción incluye toda las nuevas funcionalidades y mejoras que van saliendo, documentación detallada, y notas de lanzamiento actualizadas, para que no tengas que hacerlo tú mismo. Echa un vistazo a nuestro estudio sobre la mejora en eficiencia de desarrollo ",
        "ca-ES": "La subscripció inclou tota la nova funcionalitat i les millores que van sortint, documentació detallada i notes de release actualitzades, per a que no tinguis que fer tú mateix. Dóna un cop d'ull al nostre estudi sobre la millora en eficiència de desenvolupament "
    },
    hereLabel: 
    {
        "en-GB": "here.",
        "es-ES": "aquí.",
        "ca-ES": "aquí."
    },
    userDescLabel: 
    {
        "en-GB": "A user is NOT a Dynamics/Dataverse end user, but an individual who needs to write or debug code that uses directly or indirectly FakeXrmEasy.",
        "es-ES": "Un usuario NO es un usuario final de Dynamics/Dataverse, sino una persona que necesite escribir o depurar código que use directa o indirectamente FakeXrmEasy.",
        "ca-ES": "Un usuari NO és un usuari final de Dynamics/Dataverse, sinó una persona que necessiti escriure o depurar codi que faci servir, directa o indirectament FakeXrmEasy."
    },
    quoteInOtherCurrencies: 
    {
        "en-GB": "Our operational currency and price list defaults to EUR but we can quote you in a different currency. Choose a currency below or ",
        "es-ES": "Nuestra divisa es EUR por defecto pero le podemos hacer oferta en otra divisa, si así lo desea. Elige una divisa o ",
        "ca-ES": "La nostra divisa és EUR per defecte però podem fer-li una oferta en una altra divisa, si així ho desitja. Trïi una divisa o "
    },
    contractorsLabel: 
    {
        "en-GB": "Contractors working for Non-commercial organisations that use FakeXrmEasy version 2 or later for a commercial purpose without making available the source code where FakeXrmEasy is used will need a commercial license.",
        "es-ES": "Empresas o autónomos subcontratados por empresas No comerciales (Non-Commercial Organizations) que usen FakeXrmEasy versión 2 o posterior para uso comercial sin poner a disposición de la comunidad el código fuente donde se haya hecho servir FakeXrmEasy necesitan una licencia comercial.",
        "ca-ES": "Empreses o autònoms subcontractats per empreses No comercials (Non-Commercial Organizations) que facin servir FakeXrmEasy versió 2 o posterior per a ús comercial sense posar a disposició de la comunitat el codi font on s'hagi fet servir FakeXrmEasy necessiten una llicència comercial." 
    },
    moLabel: {
        "en-GB": "mo",
        "es-ES": "mes",
        "ca-ES": "mes"
    },

    yearLabel: 
    {
        "en-GB": "Year",
        "es-ES": "Año",
        "ca-ES": "Any"
    },
    userLabel: 
    {
        "en-GB": "User",
        "es-ES": "Usuario",
        "ca-ES": "Usuari"
    },
    annualPlanLabel: {
        "en-GB": "Annual Plan (save 2 months)",
        "es-ES": "Plan Anual (ahorra 2 meses)",
        "ca-ES": "Pla Anual (estalvia 2 mesos)"
    },
    monthlyPlanLabel: {
        "en-GB": "Monthly Plan (ideal for short projects, go for Annual to save 2 months)",
        "es-ES": "Plan mensual (ideal para proyectos cortos, o bien ahorra 2 meses con el plan anual)",
        "ca-ES": "Pla mensual (ideal per a projectes curts, o bé estalvia 2 mesos amb el pla anual)"
    },
    propietaryAndCommercialUseLabel: {
        "en-GB": "For <em>propietary</em> <br/>and <em>commercial</em> use.",
        "es-ES": "Para uso <em>propietario</em> <br/>y <em>comercial</em>.",
        "ca-ES": "Per a ús <em>propietari</em> <br/>i <em>comercial</em>."
    },
    userSubscriptionsLabel: {
        "en-GB": "user subscriptions",
        "es-ES": "usuarios",
        "ca-ES": "usuaris"
    },
    commercialSupportLabel: {
        "en-GB": "<em>Commercial</em> support",
        "es-ES": "Soporte <em>comercial</em>",
        "ca-ES": "Suport <em>comercial</em>"
    },
    flatFeeDescLabel: 
    {
        "en-GB": "<em >Flat-fee</em> for all users within <br/>the subscription range",
        "es-ES": "<em >Tarifa plana</em> para todos los usuarios <br/>dentro del rango",
        "ca-ES": "<em >Tarifa plana</em> per a tots els usuaris <br/>dins del rang"
    },
    getQuoteLabel: {
        "en-GB": "Get a Quote",
        "es-ES": "Pedir Presupuesto",
        "ca-ES": "Demani Pressupost"
    },
    notAvailableLabel: {
        "en-GB": "Not available in the monthly plan",
        "es-ES": "No disponible en el plan mensual",
        "ca-ES": "No disponible en el pla mensual"
    },
    communitySupportLabel: {
        "en-GB": "<em>Community</em> support",
        "es-ES": "Soporte de la <em>comunidad</em>",
        "ca-ES": "Suport de la <em>comunitat</em>"
    },
    forOSSProjectsLabel: {
        "en-GB": "For <em>OSS</em> projects or,",
        "es-ES": "Para proyectos <em>OSS</em> o,",
        "ca-ES": "Per a projectes <em>OSS</em> o,"
    },
    nonCommercialUseLabel: {
        "en-GB": "For <em>Non-Commercial use</em> or,",
        "es-ES": "Para uso <em>No-Comercial</em> o,",
        "ca-ES": "Per a ús <em>No-Comercial</em> o,"
    },
    nonCommercialOrganizationsLabel: {
        "en-GB": "<em>Non-Commercial Organizations*</em>",
        "es-ES": "<em>Organizaciones No-Comerciales*</em>",
        "ca-ES": "<em>Organitzacions No-Comercials*</em>"
    },
    diyLabel: {
        "en-GB": "<em>DIY</em>: Do It Yourself",
        "es-ES": "<em>DIY</em>: Hazlo tú mismo",
        "ca-ES": "<em>DIY</em>: Fes-ho tú mateix"
    },
    freeLabel: {
        "en-GB": "Free",
        "es-ES": "Gratis",
        "ca-ES": "Gratuït"
    },
    checkOutOur: {
        "en-GB": "Check out our",
        "es-ES": "Echa un vistazo a nuestra",
        "ca-ES": "Dóna un cop d'ull a la nostra"
    },
    licence: {
        "en-GB": "LICENSE",
        "es-ES": "LICENCIA",
        "ca-ES": "LLICÈNCIA"
    },
    andOur: {
        "en-GB": "and our",
        "es-ES": "y nuestra sección de",
        "ca-ES": "i la nostra secció de"
    },
    licensingFaq: {
        "en-GB": "licensing FAQ",
        "es-ES": "preguntas frecuentes sobre licenciamiento",
        "ca-ES": "preguntes freqüents sobre llicenciament"
    },
    ifYouHaveAnyQuestions: {
        "en-GB": "if you have any questions",
        "es-ES": "si tienes cualquier duda",
        "ca-ES": "si tens qualsevol dubte"
    },
    chooseYourCurrency: {
        "en-GB": "Choose your currency",
        "es-ES": "Elige una divisa",
        "ca-ES": "Tria una divisa"
    }
}

export { PricingLiterals };