const LayoutLiterals = {
    newVersionAvailable: {
        "en-GB": "There is a new update of this app available than the currently installed version ",
        "es-ES": "Hay una versión más reciente de esta app que la actualmente instalada ",
        "ca-ES": "Hi ha una nova versió d'aquesta app que la actualment instal·lada "
    },
    closeAndReopenTabs: {
        "en-GB": "Please close all tabs and open a new tab to use it. ",
        "es-ES": "Por favor, cierra todas las pestañas y abre una nueva para usarla.",
        "ca-ES": "Si us plau, tanca totes les pestanyes i obre una de nova per usar-la. "
    },
    checkingNewVersion: {
        "en-GB": "We're checking if there is a newer version...",
        "es-ES": "Estamos comprobando si hay una ueva versión...",
        "ca-ES": "Estem comprovant si hi ha una nova versió... "
    }
}

interface ILayoutLiterals {
    newVersionAvailable: string;
    closeAndReopenTabs: string;
    checkingNewVersion: string;
}

export { LayoutLiterals };
export type { ILayoutLiterals };