const PrivacyPolicyLiterals = {
    privacyPolicyTitle: {
        "en-GB": "I. Privacy Policy",
        "es-ES": "I. Política de Privacidad",
        "ca-ES": "I. Política de Privacitat"
    },
    lastUpdated: {
        "en-GB": "December 13th, 2022",
        "es-ES": "13 de Diciembre de 2022",
        "ca-ES": "13 de Desembre de 2022"
    },
    privacyPolicyDesc: {
        "en-GB": "In compliance with REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of April 27, 2016, (hereinafter, \"RGPD\"), regarding the protection of natural persons with regard to data processing and the free circulation of these data and Organic Law 3/2018, of December 5, Protection of Personal Data and guarantee of digital rights (hereinafter, \"LOPDgdd\"), you are informed that the personal data provided by you through the website <a href='https://dynamicsvalue.com/' target='_blank'>https://dynamicsvalue.com/</a> (hereinafter the website), will be treated in the following terms:",
        "es-ES": "En cumplimiento del REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de Abril de 2016, (en adelante, \"RGPD\"), relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y a Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (en adelante, \"LOPDgdd\"), se le informa que los datos personales proporcionados por usted a través del sitio web <a href='https://dynamicsvalue.com/' target='_blank'>https://dynamicsvalue.com/</a> (en adelante el sitio web), serán tratados en los siguientes términos:",
        "ca-ES": "En compliment del REGLAMENT (UE) 2016/679 DEL PARLAMENT EUROPEU I DEL CONSELL de 27 d'abril de 2016, (d'ara endavant, \"RGPD\"), relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals ia la lliure circulació d'aquestes dades ia la Llei Orgànica 3/2018, de 5 de desembre, de Protecció de Dades Personals i garantia dels drets digitals (d'ara endavant, \"LOPDgdd\"), se us informa que les dades personals proporcionades per vostè a través del lloc web <a href='https://dynamicsvalue.com/' target='_blank'>https://dynamicsvalue.com/</a> (d'ara endavant el lloc web), seran tractats a els termes següents:"
    },
    dataControllerTitle: {
        "en-GB": "Name of the Person Responsible",
        "es-ES": "Responsable del Tratamiento",
        "ca-ES": "Responsable del Tractament"
    },
    dataControllerDesc: {
        "en-GB": "The personal data collected through this website will be processed by DYNAMICS VALUE, S.L., (hereinafter, \"RESPONSIBLE\"), with address for the exercise of rights at Calle Salamanca 8, 08620-Sant Vicenç dels Horts (Barcelona). <br/> Email: <a href='mailto:privacy@dynamicsvalue.com'>privacy@dynamicsvalue.com</a>.",
        "es-ES": "Los datos personales recabados a través de este sitio web serán tratados por DYNAMICS VALUE, S.L., (en adelante, \"RESPONSABLE\"), con domicilio para el ejercicio de derechos en Calle Salamanca 8, de 08620-Sant Vicenç dels Horts (Barcelona). <br/> Email: <a href='mailto:privacy@dynamicsvalue.com'>privacy@dynamicsvalue.com</a>.",
        "ca-ES": "Les dades personals demanades a través d'aquest lloc web seran tractades per DYNAMICS VALUE, S.L., (d'ara endavant, \"RESPONSABLE\"), amb domicili per a l'exercici de drets al Carrer Salamanca 8, de 08620-Sant Vicenç dels Horts (Barcelona). <br/> Email: <a href='mailto:privacy@dynamicsvalue.com'>privacy@dynamicsvalue.com</a>."
    },
    purposeOfTreatmentTitle: {
        "en-GB": "Purposes of Treatment",
        "es-ES": "Finalidades del tratamiento",
        "ca-ES": "Finalitats del tractament"
    },
    purposeBulletPoint01: {
        "en-GB": "<b>Information requests</b>. Inform you about our products and/or services, as well as any related questions, comments and or suggestions, when you request about them via our contact form or via email. <br/> Legal basis: Consent.",
        "es-ES": "<b>Solicitudes de contacto</b>. Informarle sobre nuestros productos y/o servicios, así como cualquier duda, comentario y/o sugerencia relacionada, cuando solicite sobre los mismos a través de nuestro formulario de contacto o vía correo electrónico. <br/>Base legal: Consentimiento.",
        "ca-ES": "<b>Sol·licituds d'informació</b>. Informar-vos dels nostres productes i/o serveis, així com de qualsevol pregunta, comentari o suggeriment relacionats, quan ho sol·liciti a través del nostre formulari de contacte o per correu electrònic. <br/>Base legal: Consentiment."
    },
    purposeBulletPoint02: {
        "en-GB": "<b>Support</b>. We need to process your personal data in response to some support requests about our products that you might have when you have a subscription with us. <br/> Legal basis: Performance of a Contract.",
        "es-ES": "<b>Soporte</b>. Necesitamos procesar sus datos personales en respuesta a algunas solicitudes de soporte sobre nuestros productos que pueda tener cuando tiene una suscripción con nosotros. <br/> Base legal: Ejecución de un Contrato.",
        "ca-ES": "<b>Suport</b> Hem de processar les vostres dades personals en resposta a algunes sol·licituds d'assistència sobre els nostres productes que podríeu tenir quan tingueu una subscripció amb nosaltres. <br/> Base legal: Execució d'un Contracte."
    },
    purposeBulletPoint03: {
        "en-GB": "<b>Subscription administration</b>. If you subscribe to our products, we need to process your personal data to manage your subscription and receive payment from you. This includes information like quotes, orders, invoices, payment receipts and so on. <br/> Legal basis: Performance of a Contract.",
        "es-ES": "<b>Administración de suscripciones</b>. Si se suscribe a nuestros productos, necesitamos procesar sus datos personales para administrar su suscripción y recibir su pago. Esto incluye información como cotizaciones, pedidos, facturas, recibos de pago, etc. <br/> Base legal: Ejecución de un contrato. ",
        "ca-ES": "<b>Administració de la subscripció</b>. Si us subscriu als nostres productes, necessitem processar les vostres dades personals per gestionar la vostra subscripció i rebre el vostre pagament. Això inclou informació com pressupostos, comandes, factures, rebuts de pagament, etc. <br/> Execució d'un contracte."
    },
    purposeBulletPoint04: {
        "en-GB": "<b>Operating our website</b>. To operate our website and infrastructure efficiently, securely and professionally, we process your personal data to maintain an adequate level of security, confidentiality and availablity, and to troubleshoot any errors and perform optimizations. <br/> Legal basis: Legitimate interest.",
        "es-ES": "<b>Funcionamiento de nuestro sitio web</b>. Para operar nuestro sitio web e infraestructura de manera eficiente, segura y profesional, procesamos sus datos personales para mantener un nivel adecuado de seguridad, confidencialidad y disponibilidad, y para solucionar cualquier error y realizar optimizaciones. <br/> Base legal: interés legítimo.",
        "ca-ES": "<b>Funcionament del nostre lloc web</b>. Per operar el nostre lloc web i la nostra infraestructura de manera eficient, segura i professional, tractem les vostres dades personals per mantenir un nivell adequat de seguretat, confidencialitat i disponibilitat, i per solucionar qualsevol error i realitzar optimitzacions. <br/> Base legal: interès legítim."
    },
    purposeBulletPoint05: {
        "en-GB": "<b>Newsletter</b>. We promote our activities, services and/or our products via our newsletter. You can unsubscribe any time. <br/> Legal basis: Consent.",
        "es-ES": "<b>Newsletter</b>. Promocionamos nuestras actividades, servicios y/o nuestros productos a través de nuestro boletín informativo. Puede anular su suscripción en cualquier momento. <br/> Base legal: Consentimiento.",
        "ca-ES": "<b>Newsletter</b> Promocionem les nostres activitats, serveis i/o els nostres productes a través del nostre butlletí. Pots donar-te de baixa en qualsevol moment. <br/> Base legal: Consentiment."
    },
    legitimateTitle: {
        "en-GB": "Legal Basis for Processing",
        "es-ES": "Legitimación",
        "ca-ES": "Legitimació"
    },
    consentDesc: {
        "en-GB": "<u>Consent</u> (GDPR art. 6 (1)(a)). The personal data obtained through the forms of the website will be processed solely based on the consent granted by the interested party, through the acceptance box provided for this purpose. This consent can be withdrawn at any time.",
        "es-ES": "<u>Consentimiento</u> (RGPD art. 6 (1)(a)). Los datos personales obtenidos a través de los formularios del sitio web serán tratados únicamente en base al consentimiento otorgado por el interesado, a través de la aceptación de la casilla dispuesta para tal fin. Este consentimiento puede ser retirado en cualquier momento.",
        "ca-ES": "<u>Consentiment</u> (RGPD art. 6 (1)(a)). Les dades personales obtenides a través dels formularios del lloc web seràn tractats únicament en base al consentiment otorgat per l'interessat, a través de l'acceptació de la casella que es disposa per a tal finalitat. Aquest consentiment pot ser retirat en qualsevol moment."
    },
    legitimateInterest: {
        "en-GB": "<u>Legitimate interest of the controller</u> (GDPR art. 6 (1)(f)). DynamicsValue deems that the following listed interests are legitimate and not overridden by your interests or fundamental rights or freedoms.",
        "es-ES": "<u>Interés legítimo del responsable</u> (RGPD art. 6 (1)(f)). DynamicsValue considera que los siguientes intereses enumerados son legítimos y no anulados por sus intereses o derechos o libertades fundamentales.",
        "ca-ES": "<u>Interès legítim del responsable</u> (RGPD art. 6 (1)(f)). DynamicsValue considera que els interessos enumerats a continuació són legítims i no anul·lats pels vostres interessos o drets o llibertats fonamentals."
    },
    contractLegitimateInterest: {
        "en-GB": "<u>Performance of a contract</u> (GDPR art. 6 (1)(b)). It is necessary to process your personal data to perform a contract or a pre-contract between you and DynamicsValue.",
        "es-ES": "<u>Ejecución de un contrato</u> (RGPD art. 6 (1)(b)). Es necesario tratar sus datos personales para realizar un contrato o un precontrato entre usted y DynamicsValue.",
        "ca-ES": "<u>Execució d'un contrate</u> (RGPD art. 6 (1)(b)). És necessari tractar les teves dades personals per dur a terme un contracte o un precontracte entre tu i DynamicsValue."
    },
    legitimateInterest01: {
        "en-GB": "Gaining insight into how our website is used from a usability perspective.",
        "es-ES": "Obtener información sobre cómo se utiliza nuestro sitio web desde una perspectiva de usabilidad.",
        "ca-ES": "Obtenir informació sobre com s'utilitza el nostre lloc web des d'una perspectiva d'usabilitat."
    },
    legitimateInterest02: {
        "en-GB": "Developing and enhancing our website.",
        "es-ES": "Desarrollar y mejorar nuestro sitio web.",
        "ca-ES": "Desenvolupament i millora del nostre lloc web."
    },
    legitimateInterest03: {
        "en-GB": "Determining and improving the effectiveness of our communication",
        "es-ES": "Determinar y mejorar la eficacia de nuestra comunicación",
        "ca-ES": "Determinar i millorar l'eficàcia de la nostra comunicació"
    },
    legitimateInterest04: {
        "en-GB": "Increasing security and preventing abuse or fraud on our website, as well as ensuring the availability and integrity of your personal data.",
        "es-ES": "Aumentar la seguridad y prevenir abusos o fraudes en nuestro sitio web, así como garantizar la disponibilidad e integridad de sus datos personales.",
        "ca-ES": "Augmentar la seguretat i prevenir l'abús o frau al nostre lloc web, així com garantir la disponibilitat i integritat de les seves dades personals."
    },
    recipientsTitle: {
        "en-GB": "Recipients",
        "es-ES": "Destinatarios",
        "ca-ES": "Destinataris"
    },
    recipientsDesc: {
        "en-GB": "We do not sell, trade or otherwise transfer to outside parties any personally identifiable information. We may share your personal data with service providers but we will remain as data controllers for your personal data. When engaging a service provider we will require the same level of protection and compliance as the one provided to you. No service provider may use your personal data for any other purposes than those set out above.",
        "es-ES": "No vendemos, intercambiamos ni transferimos a terceros ninguna información de identificación personal. Podemos compartir sus datos personales con proveedores de servicios, pero permaneceremos como controladores de datos para sus datos personales. Al contratar a un proveedor de servicios, exigiremos el mismo nivel de protección y cumplimiento que el que se le proporcionó a usted. Ningún proveedor de servicios puede utilizar sus datos personales para fines distintos a los establecidos anteriormente.",
        "ca-ES": "No venem, comercialitzem ni transferim a tercers cap informació d'identificació personal. Podem compartir les vostres dades personals amb proveïdors de serveis, però seguirem com a controladors de dades de les vostres dades personals. En contractar un proveïdor de serveis, exigirem el mateix nivell de protecció i compliment que el que us proporcionem. Cap proveïdor de serveis pot utilitzar les vostres dades personals per a altres finalitats que les que s'estableixen anteriorment."
    },
    conservation: {
        "en-GB": "Data Retention",
        "es-ES": "Conservación",
        "ca-ES": "Conservació"
    },
    conservationParagraph01: {
        "en-GB": "The personal data provided by the user to receive commercial communications will be kept as long as the interested party does not request their deletion or withdraw their consent.",
        "es-ES": "Los datos personales facilitados por el usuario para recibir comunicaciones comerciales, serán conservados mientras el interesado no solicite su supresión o retire su consentimiento.",
        "ca-ES": "Les dades personals facilitades per l'usuari per rebre comunicacions comercials, seran conservades mentre l'interessat no sol·licite la supressió o retiri el consentiment."
    },
    conservationParagraph02: {
        "en-GB": "When the user sends their data to contact the RESPONSIBLE, make queries or suggestions, the data will be kept as long as it is necessary for this purpose and responsibilities for the treatment may arise.",
        "es-ES": "Cuando el usuario envíe sus datos para contactar con el RESPONSABLE, realizar consultas o sugerencias, los datos se conservarán mientras sea necesario para tal fin y puedan derivarse responsabilidades del tratamiento.",
        "ca-ES": "Quan l'usuari enviï les seves dades per contactar amb el RESPONSABLE, fer consultes o suggeriments, les dades es conservaran mentre sigui necessària per a tal fi i es puguin derivar responsabilitats del tractament."
    },
    conservationParagraph03: {
        "en-GB": "Traffic data, statistics and web visits: the data will be kept for a period of three years.",
        "es-ES": "Datos de tráfico, estadísticos y visitas web: los datos serán conservados durante el plazo de tres años.",
        "ca-ES": "Dades de trànsit, estadístiques i visites web: les dades seran conservades durant el termini de tres anys."
    },
    rights: {
        "en-GB": "Rights",
        "es-ES": "Derechos",
        "ca-ES": "Drets"
    },
    rightsParagraph01: {
        "en-GB": "You can exercise your rights of access, rectification, deletion and portability of your data, limitation and opposition to its treatment, by writing accompanied by an official document that identifies you addressed to Calle Salamanca 8, 08620-Sant Vicenç dels Horts (Barcelona).",
        "es-ES": "Puede ejercer sus derechos de acceso, rectificación, supresión y portabilidad de sus datos, de limitación y oposición a su tratamiento, mediante escrito acompañado de documento oficial que le identifique dirigido a Calle Salamanca 8, de 08620-Sant Vicenç dels Horts (Barcelona).",
        "ca-ES": "Puede exercir els seus drets d'accès, rectificació, supressió i portabilitat de les seves dades, de limitació i oposició al seu tratactament, mediant escrit acompanyat de document oficial que l'identifiqui dirigit a Carrer Salamanca 8, de 08620-Sant Vicenç dels Horts (Barcelona)."
    },
    rightsParagraph02: {
        "en-GB": "Email: <a href='mailto:privacy@dynamicsvalue.com'>privacy@dynamicsvalue.com</a>",
        "es-ES": "Email: <a href='mailto:privacy@dynamicsvalue.com'>privacy@dynamicsvalue.com</a>",
        "ca-ES": "Email: <a href='mailto:privacy@dynamicsvalue.com'>privacy@dynamicsvalue.com</a>"
    },
    rightsParagraph03: {
        "en-GB": "In case of disagreement with the treatment, you also have the right to raise a complaint with the Spanish Data Protection Agency (<a href='www.aepd.es' target='_blank'>www.aepd.es</a> ).",
        "es-ES": "En caso de disconformidad con el tratamiento, también tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos (<a href='www.aepd.es' target='_blank'>www.aepd.es</a>).",
        "ca-ES": "En cas de disconformitat amb el tractament, també teniu dret a presentar una reclamació davant l'Agència Espanyola de Protecció de Dades (<a href='www.aepd.es' target='_blank'>www.aepd.es</a> )."
    },
    minorsProtection: {
        "en-GB": "Child Protection Policy",
        "es-ES": "Política de protección de menores",
        "ca-ES": "Política de protecció de menors"
    },
    minorsProtectionDesc: {
        "en-GB": "Whoever provides the data through the forms on this website and accepts its treatment formally declares to be over 18 years of age. The access and use of the portal to those under 18 years of age is prohibited. Dynamics Value reminds people of legal age who are in charge of minors, that it will be their sole responsibility if a minor enters their data to request a product. It also informs them that there are computer programs to limit browsing by filtering or blocking certain content.",
        "es-ES": "Quien facilita los datos a través de los formularios de esta Web y acepta su tratamiento declara formalmente ser mayor de 18 años. Queda prohibido el acceso y uso del portal a los menores de 18 años de edad. Dynamics Value recuerda a las personas mayores de edad que tengan a su cargo menores, que será de su exclusiva responsabilidad si algún menor incorpora sus datos para solicitar algún producto. También les informa que existen programas informáticos para acotar la navegación mediante el filtro o bloqueo a determinados contenidos.",
        "ca-ES": "Quien facilita los datos a través de los formularios de esta Web y acepta su tratamiento declara formalmente ser mayor de 18 años. Queda prohibido el acceso y uso del portal a los menores de 18 años de edad. Dynamics Value recuerda a las personas mayores de edad que tengan a su cargo menores, que será de su exclusiva responsabilidad si algún menor incorpora sus datos para solicitar algún producto. También les informa que existen programas informáticos para acotar la navegación mediante el filtro o bloqueo a determinados contenidos."
    },
    socialNetworks: {
        "en-GB": "Treatment derived from the use of social networks",
        "es-ES": "Tratamiento derivado del uso de las redes sociales",
        "ca-ES": "Tractament derivat de l'ús de xarxes socials"
    },
    socialNetworksParagraph01: {
        "en-GB": "By following our profile on social networks, you expressly consent to the processing of your personal data in accordance with the privacy policy of the corresponding social network. Likewise, you expressly consent to the CONTROLLER's access to the processing of your data contained in your profile and that the news published about any RESPONSIBLE service appear on your wall. Your request to connect necessarily implies your consent for the indicated treatments, this being the legal basis of the treatment. The data will be kept as long as the interested party does not request their opposition or withdraw their consent, being able to withdraw this at any time.",
        "es-ES": "Al seguir nuestro perfil en las redes sociales consiente expresamente el tratamiento de sus datos personales conforme a la política de privacidad de la correspondiente red social. Igualmente, consiente expresamente el acceso del RESPONSABLE al tratamiento de sus datos contenidos en su perfil y que las noticias publicadas sobre cualquier servicio del RESPONSABLE aparezcan en su muro. Su petición para conectar, implica necesariamente su consentimiento para los tratamientos señalados, siendo esta la base legal del tratamiento. Los datos se conservarán mientras el interesado no solicite su oposición o retire su consentimiento, pudiendo retirar este en cualquier momento.",
        "ca-ES": "En seguir el nostre perfil a les xarxes socials consent expressament el tractament de les seves dades personals conforme a la política de privadesa de la corresponent xarxa social. Igualment, consenteix expressament l'accés del RESPONSABLE al tractament de les seves dades contingudes al seu perfil i que les notícies publicades sobre qualsevol servei del RESPONSABLE apareguin al seu mur. La seva petició per connectar implica necessàriament el seu consentiment per als tractaments assenyalats, i aquesta és la base legal del tractament. Les dades es conservaran mentre l'interessat no sol·liciti la seva oposició o retiri el seu consentiment, i podrà retirar-lo en qualsevol moment."
    },
    socialNetworksParagraph02: {
        "en-GB": "The publication of comments and content on social networks will become public information, so users should be especially cautious when they decide to share their personal information. The CONTROLLER is not responsible for the information that users enter on the page. However, people whose personal data is published or included in comments, may request the RESPONSIBLE to cancel them.",
        "es-ES": "La publicación de comentarios y contenidos en las redes sociales se convertirán en información pública, por lo que los usuarios deberán tener especial cautela cuando decidan compartir su información personal. El RESPONSABLE no se hace responsable por la información que los usuarios incorporen en la página. No obstante, las personas cuyos datos personales se encuentren publicados o estén incluidos en comentarios, podrán solicitar al RESPONSABLE la cancelación de los mismos.",
        "ca-ES": "La publicació de comentaris i continguts a les xarxes socials es convertiran en informació pública, per la qual cosa els usuaris hauran de tenir especial cautela quan decideixin compartir la seva informació personal. El RESPONSABLE no es fa responsable de la informació que els usuaris incorporin a la pàgina. No obstant això, les persones les dades personals de les quals es trobin publicades o estiguin incloses en comentaris, podran sol·licitar al RESPONSABLE la cancel·lació dels mateixos."
    },
    legalNotice: {
        "en-GB": "II. Legal Notice",
        "es-ES": "II. Aviso Legal",
        "ca-ES": "II. Avís Legal"
    },
    identification: {
        "en-GB": "Identification",
        "es-ES": "Identificación",
        "ca-ES": "Identificació"
    },
    identificationDesc: {
        "en-GB": "In compliance with the duty of information contained in article 10 of Law 34/2002, of July 11, on Services of the Information Society and Electronic Commerce, DYNAMICS VALUE, S.L., informs you that the data entered here correspond to the entity that owns the website <a href='https://dynamicsvalue.com'>https://dynamicsvalue.com</a>.",
        "es-ES": "En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, DYNAMICS VALUE, S.L., le informa que los datos aquí consignados corresponden a la entidad titular del sitio web <a href='https://dynamicsvalue.com'>https://dynamicsvalue.com</a>.",
        "ca-ES": "En compliment amb el deure informació recollit a l'article 10 de la Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informació i del Comerç Electrònic, DYNAMICS VALUE, S.L., us informa que les dades aquí consignades corresponen a l'entitat titular del lloc web <a href='https://dynamicsvalue.com'>https://dynamicsvalue.com</a>."
    },
    intellectualProperty: {
        "en-GB": "Intellectual Property",
        "es-ES": "Propiedad intelectual e industrial",
        "ca-ES": "Propiedad intel·lectual i industrial"
    },
    intellectualPropertyParagraph01: {
        "en-GB": "All Industrial and Intellectual Property rights of all the elements contained in this website, including trademarks, formats, graphic designs, texts, images and documents, belong to Dynamics Value and are protected by Spanish and international laws on Intellectual and Industrial property. The total or partial reproduction of this website and any of its contents without the express written permission of Dynamics Value is expressly prohibited.",
        "es-ES": "Todos los derechos de Propiedad Industrial e Intelectual de la totalidad de elementos contenidos en este sitio web, incluidas las marcas comerciales, formatos, diseños gráficos, textos, imágenes y documentos, pertenecen a Dynamics Value y se encuentran protegidos por las leyes españolas e internacionales sobre propiedad Intelectual e Industrial. Queda expresamente prohibida la reproducción total o parcial de este sitio Web y de cualquiera de sus contenidos sin el permiso expreso y por escrito de Dynamics Value.",
        "ca-ES": "Tots els drets de propietat industrial i intel·lectual de la totalitat d'elements continguts en aquest lloc web, incloses les marques comercials, formats, dissenys gràfics, textos, imatges i documents, pertanyen a Dynamics Value i estan protegits per les lleis espanyoles i internacionals sobre propietat Intel·lectual i Industrial. Queda expressament prohibida la reproducció total o parcial d'aquest lloc web i de qualsevol dels continguts sense el permís exprés i per escrit de Dynamics Value."
    },
    intellectualPropertyParagraph02: {
        "en-GB": "Access to the website does not imply any type of waiver, transmission, license or assignment of said rights by Dynamics Value, unless expressly stated otherwise.",
        "es-ES": "El acceso al sitio web no implica ningún tipo de renuncia, transmisión, licencia o cesión de dichos derechos por parte de Dynamics Value, salvo que se establezca expresamente lo contrario.",
        "ca-ES": "L'accés al lloc web no implica cap tipus de renúncia, transmissió, llicència o cessió dels drets esmentats per part de Dynamics Value, llevat que s'estableixi expressament el contrari."
    },
    termsOfUse: {
        "en-GB": "Terms of Use",
        "es-ES": "Condiciones de Uso",
        "ca-ES": "Condicions d'Ús"
    },
    termsOfUseParagraph01: {
        "en-GB": "Access to this website implies acceptance of these conditions of use without reservations that regulate access and use of the same in order to make information about our services available to users.",
        "es-ES": "El acceso a este sitio Web implica la aceptación de estas condiciones de uso sin reservas que regulan el acceso y la utilización del mismo con el fin de poner a disposición de los usuarios información sobre nuestros servicios.",
        "ca-ES": "L'accés a aquest lloc web implica l'acceptació d'aquestes condicions d'ús sense reserves que en regulen l'accés i la utilització per tal de posar a disposició dels usuaris informació sobre els nostres serveis."
    },
    termsOfUseParagraph02: {
        "en-GB": "The use of the contents of this website for its use for commercial purposes or for its distribution, transformation or communication is expressly prohibited.",
        "es-ES": "Se prohíbe expresamente la utilización de los contenidos de este sitio Web para su utilización con fines comerciales o para su distribución, transformación o comunicación.",
        "ca-ES": "Es prohibeix expressament la utilització dels continguts d'aquest lloc web per utilitzar-los amb fins comercials o per distribuir-los, transformar-los o comunicar-los."
    },
    termsOfUseParagraph03: {
        "en-GB": "Dynamics Value will not be liable for any consequence, damage or harm that may arise from said use or use of the information.",
        "es-ES": "Dynamics Value, no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicha utilización o uso de la información.",
        "ca-ES": "Dynamics Value, no respondrà de cap conseqüència, dany o perjudici que poguessin derivar-se de la dita utilització o ús de la informació."
    },
    termsOfUseParagraph04: {
        "en-GB": "Both access to this website and the use that may be made of the information contained therein is the sole responsibility of the person who performs it.",
        "es-ES": "Tanto el acceso a esta Web como el uso que pueda hacerse de la información contenida en la misma es de la exclusiva responsabilidad de quien lo realiza.",
        "ca-ES": "Tant l'accés a aquest web com l'ús que es pugui fer de la informació que conté és de l'exclusiva responsabilitat de qui el realitza."
    },
    termsOfUseParagraph05: {
        "en-GB": "The user undertakes not to use the information published on this website for illicit or harmful purposes or effects, not to damage or render the information useless and not to perform any other action that may be contrary to the content of this Legal Notice.",
        "es-ES": "El usuario se obliga a no utilizar la información que se publica en esta Web con fines o efectos ilícitos o lesivos, a no dañar o inutilizar la información y a no realizar cualquier otra acción que puedan ser contraria al contenido de este Aviso Legal.",
        "ca-ES": "L'usuari s'obliga a no utilitzar la informació que es publica en aquesta Web amb fins o efectes il·lícits o lesius, a no fer malbé o inutilitzar la informació ia no realitzar qualsevol altra acció que puguin ser contrària al contingut d'aquest Avís Legal."
    },
    termsOfUseParagraph06: {
        "en-GB": "Dynamics Value cannot guarantee the absence of interruptions or errors in access to this website, although it will use its best efforts to avoid them.",
        "es-ES": "Dynamics Value no puede asegurar la inexistencia de interrupciones o errores en el acceso a este sitio Web, aunque pondrá sus mayores esfuerzos para evitarlos.",
        "ca-ES": "Dynamics Value no pot assegurar la inexistència d'interrupcions o errors en l'accés a aquest lloc web, encara que posarà els seus esforços més grans per evitar-los."
    },
    cookies: {
        "en-GB": "Cookies",
        "es-ES": "Cookies",
        "ca-ES": "Cookies"
    },
    cookiesDesc: {
        "en-GB": "This website uses cookies. You can find more information about our cookie policy and cookie declaration at <a href='/cookies-policy' target='_blank'>https://dynamicsvalue.com/cookies-policy</a>.",
        "es-ES": "Puedes encontrar más información sobre nuestra política de cookies en el siguiente enlace <a href='/cookies-policy' target='_blank'>https://dynamicsvalue.com/cookies-policy</a>.",
        "ca-ES": "Pots trobar més informació sobre la nostra política de cookies al següent ennlaç <a href='/cookies-policy' target='_blank'>https://dynamicsvalue.com/cookies-policy</a>."
    },
    otherLinks: {
        "en-GB": "Other Links",
        "es-ES": "Enlaces a otras webs",
        "ca-ES": "Enllaços a altres webs"
    },
    otherLinksDesc: {
        "en-GB": "The links (links) that you can find on this website are a service to users. These pages are not operated or controlled by Dynamics Value, therefore, it is not responsible for the contents of these Web sites nor are they covered by this Legal Notice. If you access these web pages, you should take into account that their privacy policies may be different from ours.",
        "es-ES": "Los enlaces (links) que puede encontrar en esta Web son un servicio a los usuarios. Estas páginas no son operadas ni controladas por Dynamics Value, por ello, no se hace responsable de los contenidos de esos sitios Web ni están cubiertas por el presente Aviso Legal. Si accede a estas páginas Webs deberá tener en cuenta que sus políticas de privacidad pueden ser diferentes a la nuestra.",
        "ca-ES": "Els enllaços (links) que podeu trobar en aquesta Web són un servei als usuaris. Aquestes pàgines no són operades ni controlades per Dynamics Value, per això no es fa responsable dels continguts d'aquests llocs web ni estan cobertes pel present Avís Legal. Si accediu a aquestes pàgines Web haureu de tenir en compte que les seves polítiques de privadesa poden ser diferents de la nostra."
    },
    jurisdiction: {
        "en-GB": "Applicable legislation and jurisdiction",
        "es-ES": "Legislación aplicable y competencia jurisdiccional",
        "ca-ES": "Legislació aplicable i competència jurisdiccional"
    },
    jurisdictionParagraph01: {
        "en-GB": "This Legal Notice is governed by current Spanish regulations that apply to it.",
        "es-ES": "El presente Aviso Legal se rige por la normativa española vigente que le es de aplicación.",
        "ca-ES": "Aquest Avís Legal es regeix per la normativa espanyola vigent que li és aplicable."
    },
    jurisdictionParagraph02: {
        "en-GB": "For the resolution of disputes that may arise as a result of the provisions of these provisions and their interpretation, application and compliance, the user, by virtue of the acceptance of the conditions contained in this Legal Notice, expressly waives any other jurisdiction. that could match.",
        "es-ES": "Para la resolución de las controversias que pudieran derivarse como consecuencia de lo dispuesto en las presentes disposiciones y sobre su interpretación, aplicación y cumplimiento, el usuario, en virtud de la aceptación de las condiciones recogidas en este Aviso legal, renuncia expresamente a cualquier otro fuero que pudiera corresponderle.",
        "ca-ES": "Per a la resolució de les controvèrsies que poguessin derivar-se com a conseqüència del que disposen aquestes disposicions i sobre la seva interpretació, aplicació i compliment, l'usuari, en virtut de l'acceptació de les condicions recollides en aquest Avís legal, renuncia expressament a qualsevol altre fur que li pogués correspondre."
    },
    jurisdictionParagraph03: {
        "en-GB": "In any case, within the Spanish jurisdiction, if the legislation allows submitting to a specific jurisdiction, the user expressly waives the jurisdiction that may correspond to him and voluntarily submits to the jurisdiction of the Courts and Tribunals of Barcelona.",
        "es-ES": "En cualquier caso, dentro de la jurisdicción española, si la legislación permitiese someterse a un fuero en concreto, el usuario renuncia expresamente al fuero que pudiera corresponderle y se somete voluntariamente a la jurisdicción de los Juzgados y Tribunales de Barcelona.",
        "ca-ES": "En qualsevol cas, dins de la jurisdicció espanyola, si la legislació permetés sotmetre's a un fur en concret, l'usuari renuncia expressament al fur que li pogués correspondre i se sotmet voluntàriament a la jurisdicció dels Jutjats i Tribunals de Barcelona."
    },
    dataTransfersTitle: {
        "en-GB": "Data Transfers",
        "es-ES": "Transferencia de Datos",
        "ca-ES": "Transferència de Dades"
    },
    dataTransfersDesc: {
        "en-GB": "Your personal data may be transferred to and processed by third parties located in a country outside of the European Union. We will ensure that the transfer of your personal data is carried out in accordance with applicable privacy laws and, in particular, that appropriate contractual, technical and organizational measures are in place such as the Standard Contractual Clauses approved by the EU Commission.",
        "es-ES": "Tus datos personales pueden ser transferidos y procesados por terceros ubicados en un país fuera de la Unión Europea. Nos aseguraremos de que la transferencia de tus datos personales se haga acorde con las leyes de privacidad, en especial, de tomar las medidas contractuales, técnicas y organizacionales necesarias tales como las Cláusulas Contractuales aprobadas por la comisión de la Unión Europea.",
        "ca-ES": "Les vostres dades personals poden ser transferides i processades per tercers situats en un país fora de la Unió Europea. Ens assegurarem que la transferència de les vostres dades personals es faci d'acord amb les lleis de privacitat, en especial, de prendre les mesures contractuals, tècniques i organitzacionals necessàries tals com les Clàusules Contractuals aprovades per la comissió de la Unió Europea."
    }
};

interface IPrivacyPolicyLiterals {
    privacyPolicyTitle: string;
    lastUpdated: string;
    privacyPolicyDesc: string;
    dataControllerTitle: string;
    dataControllerDesc: string;
    legitimateInterest01: string;
    legitimateInterest02: string;
    legitimateInterest03: string;
    legitimateInterest04: string;
    purposeOfTreatmentTitle: string;
    purposeBulletPoint01: string;
    purposeBulletPoint02: string;
    purposeBulletPoint03: string;
    purposeBulletPoint04: string;
    purposeBulletPoint05: string;
    legitimateTitle: string;
    consentDesc: string;
    legitimateInterest: string;
    contractLegitimateInterest: string;
    recipientsTitle: string;
    recipientsDesc: string;
    conservation: string;
    conservationParagraph01: string;
    conservationParagraph02: string;
    conservationParagraph03: string;
    rights: string;
    rightsParagraph01: string;
    rightsParagraph02: string;
    rightsParagraph03: string;
    minorsProtection: string;
    minorsProtectionDesc: string;
    socialNetworks: string;
    socialNetworksParagraph01: string;
    socialNetworksParagraph02: string;
    legalNotice: string;
    identification: string;
    identificationDesc: string;
    intellectualProperty: string;
    intellectualPropertyParagraph01: string;
    intellectualPropertyParagraph02: string;
    termsOfUse: string;
    termsOfUseParagraph01: string;
    termsOfUseParagraph02: string;
    termsOfUseParagraph03: string;
    termsOfUseParagraph04: string;
    termsOfUseParagraph05: string;
    termsOfUseParagraph06: string;
    cookies: string;
    cookiesDesc: string;
    otherLinks: string;
    otherLinksDesc: string;
    jurisdiction: string;
    jurisdictionParagraph01: string;
    jurisdictionParagraph02: string;
    jurisdictionParagraph03: string;
    dataTransfersTitle: string;
    dataTransfersDesc: string;
};

export type { IPrivacyPolicyLiterals };
export { PrivacyPolicyLiterals };