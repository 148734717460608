import React from "react";
import { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface AppModalProps
{
    title: string;
    bodyText: string;
    isOpen: boolean;
    onClickOk: () => void;
}

class AppModalState
{
    constructor()
    {

    }
}

export class AppModal extends Component<AppModalProps, AppModalState> {
    constructor(props: AppModalProps)
    {
        super(props);

        var initialState = new AppModalState();
        this.state = initialState;

        this.ok = this.ok.bind(this);
    }
  
    ok() : void {
      this.props.onClickOk();
    }

    render () {
      return (
        <div>
          <Modal isOpen={this.props.isOpen} fade={false} className="theme-white" >
            <ModalHeader>{this.props.title}</ModalHeader>
            <ModalBody>
              {this.props.bodyText}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.ok}>Ok</Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }