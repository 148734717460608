import React, { Component } from 'react';
import { EmailInput } from '../Input/EmailInput';
import { TextAreaInput } from '../Input/TextAreaInput';
import { ModalDialog } from '../ModalDialog';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { InputState, CheckBoxInputState } from '../Input/types';
import { TextInput } from '../Input/TextInput';
import { CheckBoxInput } from '../Input/CheckBoxInput';
import { AppProps } from '../AppState';
import { Help } from '../Help/Help';
import { Redirect } from 'react-router';
import { GenericResponse } from '../Api/GenericResponse';
import { AppModal } from '../Modal/AppModal';

interface SubmitModel 
{
  email: string;
  firstName: string;
  message: string;
  allowEmails: boolean;
};

interface SubmitResponse 
{
  success: boolean;
  errorMessage: string;
}

class ContactState 
{
    emailState?: InputState = undefined;
    firstNameState?: InputState = undefined;
    messageState?: InputState = undefined;
    allowMarketingState?: CheckBoxInputState = undefined;
    acceptedTermsOfService?: CheckBoxInputState = undefined;
    isSubmitting: boolean = false;
    validateForm: boolean = false;
    
    showSubmitError: boolean = false;
    submitError: string = "";

    redirect?: string = undefined;

    constructor()
    {

    }

    
};

export class Contact extends Component<AppProps, ContactState> {
  constructor(props: any)
  {
      super(props);
      this.emailOnChange = this.emailOnChange.bind(this);
      this.firstNameOnChange = this.firstNameOnChange.bind(this);
      this.messageOnChange = this.messageOnChange.bind(this);
      this.marketingCheckOnChange = this.marketingCheckOnChange.bind(this);
      this.acceptTermsOfServiceOnChange = this.acceptTermsOfServiceOnChange.bind(this);
      this.submit = this.submit.bind(this);
      this.hideError = this.hideError.bind(this);

      this.state = new ContactState();
  }

  emailOnChange = (newState: InputState) => {
    this.setState({
        emailState: newState
    });
  }

  firstNameOnChange = (newState: InputState) => {
    this.setState({
      firstNameState: newState
    });
  }

  messageOnChange = (newState: InputState) => {
    this.setState({
        messageState: newState
    });
  }

  marketingCheckOnChange = (checkBoxState: CheckBoxInputState) => {
    this.setState({
      allowMarketingState: checkBoxState
    });
  }

  acceptTermsOfServiceOnChange = (checkBoxState: CheckBoxInputState) => {
    this.setState({
      acceptedTermsOfService: checkBoxState
    });
  }

  toSubmitModel() : SubmitModel {
    return {
      email: this.state.emailState ? this.state.emailState.value : "",
      firstName: this.state.firstNameState ? this.state.firstNameState.value : "",
      message: this.state.messageState ? this.state.messageState.value : "",
      allowEmails: this.state.allowMarketingState ? this.state.allowMarketingState.checked : false
    };
  }

  hideError() : void {
    this.setState({
      showSubmitError: false
    });
  }
  submit = () => {

    var self = this;

    this.setState({
      validateForm: true
    });

    
    if(this.state.emailState?.isValid 
      && this.state.firstNameState?.isValid
      && this.state.messageState?.isValid
      && this.state.acceptedTermsOfService?.isValid)

      {
        this.setState({
          isSubmitting: true
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(self.toSubmitModel())
        };

        fetch("/api/contact/submit", requestOptions)
          .then(res => res.json())
          .then(result => {
              var response = result as GenericResponse;
              if(response.succeeded)
              {
                this.setState({
                  redirect: "/contact/sent"
                });
              }
              else {
                this.setState({
                  isSubmitting: false,
                  submitError: response.errorMessage,
                  showSubmitError: true
                });
              }
          });
      }
  }

  render () {
    if(this.state.redirect)
    {
      return (<Redirect to={this.state.redirect} />);
    }

    const literals = getLiteralsForCulture(ContactLiterals, this.props.appState.currentCulture) as any;
    var shouldValidateForm = this.state.validateForm;

    return (
      <div>
         <AppModal isOpen={this.state.showSubmitError} title={literals.contactLabel} bodyText={this.state.submitError} onClickOk={this.hideError} />

          <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                      <h2>{literals.contactLabel}</h2>

                      <p>{literals.contactSubHeadingLabel}</p>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-6">
                        <form id="contact">
                        <div className="row">
                            <div className="form-group col-md-offset-2 col-md-5">
                                <label htmlFor="email">{literals.emailAddressLabel }</label>
                                <EmailInput id="email" name="email" placeHolder={literals.yourEmailPlaceHolder} 
                                    displayValidation={shouldValidateForm} isMandatory={true} mandatoryErrorMessage={literals.emailMandatoryErrorLabel} 
                                    validationErrorMessage={literals.emailNotValidLabel} 
                                    onChange={this.emailOnChange} 
                                    value={this.state.emailState?.value}></EmailInput>
                            </div>
                            <div className="form-group col-md-5">
                                <label htmlFor="firstName">{literals.firstNameLabel }</label>
                                <TextInput id="firstName" name="firstName" placeHolder={literals.firstNameLabel} inputType="text" 
                                    displayValidation={shouldValidateForm} usesValidation={true} validationRegex="" maxLength={100} 
                                    isMandatory={true} mandatoryErrorMessage={literals.firstNameMandatoryErrorLabel} validationErrorMessage="First Name is not valid" 
                                    value={this.state.firstNameState?.value}
                                    onChange={this.firstNameOnChange}></TextInput>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-offset-2 col-md-10">
                                <label htmlFor="message">{literals.messageLabel }</label>
                                <TextAreaInput id="message" name="message" rows={8} placeHolder={literals.messageHerePlaceholder} 
                                  isMandatory={true} displayValidation={shouldValidateForm} mandatoryErrorMessage={literals.messageMandatoryErrorLabel} 
                                  validationErrorMessage="Please, enter a message" 
                                  onChange={this.messageOnChange} 
                                  value={this.state.messageState?.value}></TextAreaInput>
                            </div>
                        </div>
        
                          <div className="row">
                            <div className="form-group  col-md-offset-2 col-md-10">
                                    <CheckBoxInput id="acceptTermsOfServiceConfirmation" name="acceptTermsOfServiceConfirmation" 
                                      isMandatory={true} displayValidation={shouldValidateForm} mandatoryErrorMessage={literals.mustAcceptedTosLabel} 
                                      onChange={this.acceptTermsOfServiceOnChange}
                                      checked={this.state.acceptedTermsOfService?.checked}
                                      >
                                        <span dangerouslySetInnerHTML={{__html: literals.consentText}}></span>
                                    </CheckBoxInput>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-md-offset-2 col-md-4">
                            {this.state.isSubmitting && <button className="btn btn-primary" disabled={true}>{literals.submittingLabel}</button>}
                            {!this.state.isSubmitting && <button className="btn btn-primary" onClick={this.submit} >{literals.submitLabel}</button>}
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="row">
                      <div className="col-md-offset-2 col-md-10">
                          <Help appState={this.props.appState} />
                      </div>
                    </div>
                  </div>
               </div>
              

              
          </section>
      </div>
    );
  }
}
