import React, { Component } from 'react';
import $ from 'jquery';

interface PrismStatic {
    highlightAll(async: boolean): void;
}
declare var Prism: PrismStatic;

interface CodeComponentProps 
{
    code: string
}

class CodeComponentState 
{
    i: number = 0;
    wait: number = 0;
    renderedCode: string = "";
    tick: number = 20;
    animationEnded: boolean = false;
    interval: any;
}

export class CodeComponent extends Component<CodeComponentProps, CodeComponentState> {
  constructor(props: CodeComponentProps) {
    super(props);
    this.state = new CodeComponentState();

    this.endAnimation = this.endAnimation.bind(this);
  }

  endAnimation(): void 
  {
    clearInterval(this.state.interval);
    this.setState({
        i: this.props.code.length,
        renderedCode: this.props.code,
        animationEnded: true
    });
    setTimeout(function () { Prism.highlightAll(false); }, 200);
  }

  animate(): void 
  {       
    var self = this;

    var intervalHandle = setInterval(function () {
        if (self.state.wait > 0) {
            self.setState({
                wait: self.state.wait - 1
            });
        }
        else {
            if (self.state.i < self.props.code.length) {
                var c = self.props.code[self.state.i];

                self.setState({
                    renderedCode: self.state.renderedCode + self.props.code[self.state.i],
                    i: self.state.i + 1,
                    wait: c === '\n' ? 20 : 0
                });
            }
            else {
                self.endAnimation();
            }
        }

        var pre = $("pre.language-csharp");
        pre.scrollTop(pre.prop("scrollHeight"));
    }, self.state.tick);

    self.setState({
        interval:  intervalHandle
    });
  }

  render () {
    return (
      <div>
        <pre className="language-csharp" style={{height: "350px", overflowY: "scroll"}}>
          <code className="language-csharp">{this.state.renderedCode}</code>
        </pre>
        {!this.state.animationEnded && <button className="btn btn-primary" onClick={this.endAnimation} style={{display: 'block'}}>Skip Animation</button>}
      </div>
    );
  }

  componentDidMount()
  {
      this.animate();
  }
}
