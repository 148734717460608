import React, { useEffect } from "react";
import { useState } from "react";
import { BasicDetailsLiterals, IBasicDetailsLiterals } from "../../../literals/account/user-profile/BasicDetailsLiterals";
import { CultureLiterals } from "../../../literals/culture/CultureLiterals";
import { getLiteralsForCulture } from "../../../literals/Literals";
import { PostJsonAsync } from "../../../services/FetchService";
import { GenericResponse } from "../../Api/GenericResponse";
import { AppProps } from "../../AppState";
import { Card } from "../../Cards/Card";
import { DropDownInputField } from "../../Input/Fields/DropDownInputField";
import { TextInputField } from "../../Input/Fields/TextInputField";
import { CreateInputState, CreateValidatedInputState, DropDownOption, InputState } from "../../Input/types";
import { Loading } from "../../Loading";
import { AppModal } from "../../Modal/AppModal";

interface UserProfileModel {
    firstName?: string;
    lastName?: string;
    culture?: string;
}
interface UserProfileResponse extends GenericResponse 
{
  model: UserProfileModel;
}

const BasicDetails = ({appState} : AppProps) => {
    const initialFirstNameState = new InputState();
    const initialLastNameState = new InputState();
    const initialCultureState = new InputState();
    
    const [ firstName, setFirstName ] = useState(initialFirstNameState);
    const [ lastName, setLastName ] = useState(initialLastNameState);
    const [ culture, setCulture ] = useState(initialCultureState);

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isValidatingForm, setIsValidatingForm ] = useState(false);
    const [ isSubmitErrorVisible , setIsSubmitErrorVisible ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ submitError, setSubmitError ] = useState("");
    
    const literals = getLiteralsForCulture(BasicDetailsLiterals, appState.currentCulture) as IBasicDetailsLiterals; 

    const cultureLiterals = getLiteralsForCulture(CultureLiterals, appState.currentCulture) as any;
    let cultureOptions = [] as DropDownOption[];
    for(var cultureLiteral in cultureLiterals)
    {
      cultureOptions.push({
        value: cultureLiteral,
        label: cultureLiterals[cultureLiteral] 
      });
    }

    useEffect(() => {
        const getBasicDetails = async() => {
          const result = await PostJsonAsync("/api/account/get-profile", {}, appState);
          if(result.succeeded)
          {
            const model = (result as UserProfileResponse).model;
            setFirstName(CreateValidatedInputState(model.firstName));
            setLastName(CreateValidatedInputState(model.lastName));
            setCulture(CreateValidatedInputState(model.culture));
          }
          setIsLoading(false);
        };
        getBasicDetails();
      }, []);
      
    const submit = () => {
        setIsValidatingForm(true);

        if(!firstName.isValid ||
            !lastName.isValid ||
            !culture.isValid)
        {
            return;
        }

        setIsSubmitting(true);

        const SubmitAsync = async () => {
          const result = await PostJsonAsync("/api/account/update-profile", 
            {
                firstName: firstName.value,
                lastName: lastName.value,
                culture: culture.value
            }, 
            appState);
          if(result.succeeded) {
            setIsSubmitErrorVisible(false);
            if(appState.changeCultureFn) {
                appState.changeCultureFn(culture.value);
            }
          }
          else {
            setSubmitError(result.errorMessage);
            setIsSubmitErrorVisible(true);
          }

          setIsSubmitting(false);
        };

        SubmitAsync();
    };

    let cardBody = (
      <div>
        <div className="row">
                <div className="form-group col-md-12">
                    <TextInputField 
                        label={literals.firstName}
                        currentCulture={appState.currentCulture}
                        id="firstName"
                        name="firstName"
                        placeHolder={literals.firstName}
                        displayValidation={isValidatingForm}
                        usesValidation={true}
                        maxLength={200}
                        isMandatory={true}
                        onChange={(newState) => setFirstName(newState)}
                        value={firstName.value} 
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-12">
                    <TextInputField 
                        label={literals.lastName}
                        currentCulture={appState.currentCulture}
                        id="lastName"
                        name="lastName"
                        placeHolder={literals.lastName}
                        displayValidation={isValidatingForm}
                        usesValidation={true}
                        maxLength={200}
                        isMandatory={true}
                        onChange={(newState) => setLastName(newState)}
                        value={lastName.value}/>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-12">
                    <DropDownInputField
                        label={literals.language}
                        currentCulture={appState.currentCulture}
                        id="culture"
                        name="culture"
                        placeHolder={literals.language}
                        options={cultureOptions}
                        displayValidation={isValidatingForm} 
                        usesValidation={true}
                        isMandatory={true}
                        onChange={(newState) => setCulture(newState)}
                        value={culture.value}> 
                    </DropDownInputField>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    {isSubmitting && <button className="btn btn-primary" disabled={true}>{literals.saving}</button>}
                    {!isSubmitting && <button className="btn btn-primary" onClick={submit} >{literals.save}</button>}
                </div>
            </div>
        </div>
    );

    let loadingCard = (<div><Loading /></div>);
    
    return (
        <div className="col-md-12">
            <AppModal isOpen={isSubmitErrorVisible} title={literals.myProfileTitle} bodyText={submitError} onClickOk={() => setIsSubmitErrorVisible(false)} />
            <Card title={literals.myProfileTitle} body={isLoading ? loadingCard : cardBody} />
        </div>
    );
};


export { BasicDetails };