var ContactLiterals = {

    contactLabel: {
        "en-GB": "Contact",
        "es-ES": "Contacto",
        "ca-ES": "Contacte"
    },
    contactSubHeadingLabel: 
    {
        "en-GB": "Want to know more about what FakeXrmEasy can do for your company? Get in touch and we'll be more than happy to help!",
        "es-ES": "¿Necesita ayuda o saber más sobre qué podemos hacer por su empresa? ¿O bien tiene sugerencias sobre cómo podemos mejorar el servicio? Contacte con nosotros y estaremos encantados de ayudarle!",
        "ca-ES": "Necessita ajuda o conéixer què podem fer per la seva empresa? O bé té suggerències sobre com podem millorar el servei? Contacti amb nosaltres i estarem encantats d'ajudar-lo!"
    },
    emailAddressLabel: 
    {
        "en-GB": "Email Address",
        "es-ES": "Correo electrónico",
        "ca-ES": "Correu electrònic"
    },
    firstNameLabel: 
    {
        "en-GB": "First Name",
        "es-ES": "Nombre",
        "ca-ES": "Nom"
    },
    messageLabel: 
    {
        "en-GB": "Message",
        "es-ES": "Mensaje",
        "ca-ES": "Missatge"
    },
    emailNotValidLabel: {
        "en-GB": "Please, email doesn't have a valid format",
        "es-ES": "Por favor, el email no tiene un formato adecuado",
        "ca-ES": "Si us plau, el email no té un format adequat"
    },
    emailMandatoryErrorLabel: {
        "en-GB": "Please, email is mandatory",
        "es-ES": "Por favor, introduce un correo electrónico",
        "ca-ES": "Si us plau, introdueix un correu electrònic"
    },
    yourEmailPlaceHolder: {
        "en-GB": "youremail@mail.com",
        "es-ES": "tuemail@mail.com",
        "ca-ES": "elteuemail@mail.com"
    },
    firstNameMandatoryErrorLabel: {
        "en-GB": "Please, first name is mandatory",
        "es-ES": "Por favor, introduce un nombre",
        "ca-ES": "Si us plau, introdueix un nom"
    },
    messageMandatoryErrorLabel: {
        "en-GB": "Please, message is mandatory",
        "es-ES": "Por favor, introduce un mensaje",
        "ca-ES": "Si us plau, introdueix un missatge"
    },
    messageHerePlaceholder: 
    {
        "en-GB": "Your message here...",
        "es-ES": "Escribe aquí tu mensaje...",
        "ca-ES": "Escriu aquí el teu missatge..."
    },
    dontAllowEmailMarketingLabel: 
    {
        "en-GB": "I do not wish to receive the latest news about products, services or announcements that may interest me.",
        "es-ES": "No deseo recibir información de las úlimas noticias sobre productos, servicios o novedades que me puedan interesar",
        "ca-ES": "No vull rebre informació de les últimes notícies sobre productes, serveis o novetats que em puguin interessar"
    },
    contactSentLabel: {
        "en-GB": "Contact Request Received",
        "es-ES": "Solicitud de Contacto Recibida",
        "ca-ES": "Sol·licitud de Contacte Rebuda"
    },
    contactSentParagraphLabel: {
        "en-GB": "Thanks a million for getting in touch.",
        "es-ES": "Muchísimas gracias por contactar con nosotros.",
        "ca-ES": "Moltíssimes gràcies per contactar amb nosaltres. "
    },
    contactGetBackParagraphLabel: {
        "en-GB": "We'll review your request and we'll get back to you as soon as possible.",
        "es-ES": "Revisaremos to solicitud de contacto y nos pondremos en contacto contigo tan pronto como sea posible.",
        "ca-ES": "Revisarem la teva sol·licitud de contacte i ens posarem amb contacte tant aviat com sigui possible."
    },
    submitLabel: {
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "ca-ES": "Envia"
    },
    submittingLabel: {
        "en-GB": "Submitting...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    },
    consentText: {
        "en-GB": "<b>Consent</b>. We treat your data to reply to your query and any other follow up questions or any requests from you. You can exercise your rights according to our <a href='/privacy-policy' target='_blank'>Privacy Policy</a>",
        "es-ES": "<b>Consiento el tratamiento de mis datos</b>. Trataremos sus datos con la finalidad de contestar a sus consultas, dudas, reclamaciones y cualquier otro tipo de solicitud. Puede ejercer sus derechos de acceso, modificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra <a href='/privacy-policy' target='_blank'>Política de Privacidad y Aviso Legal</a>",
        "ca-ES": "<b>Consiento el tratamiento de mis datos</b>. Trataremos sus datos con la finalidad de contestar a sus consultas, dudas, reclamaciones y cualquier otro tipo de solicitud. Puede ejercer sus derechos de accesso, modificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra <a href='/privacy-policy' target='_blank'>Política de Privacidad y Aviso Legal</a>"
    },
    mustAcceptedTosLabel: {
        "en-GB": " Please, we need your consent.",
        "es-ES": " Por favor, necesitamos su consentimiento.",
        "ca-ES": " Si us plau, necessitem el seu consentiment."
    }
};


export { ContactLiterals };