const InputLiterals = {
    emailLabel: {
        "en-GB": "Email",
        "es-ES": "Email",
        "ca-ES": "Email"
    },
    passwordLabel: {
        "en-GB": "Password",
        "es-ES": "Contraseña",
        "ca-ES": "Contrassenya"
    },
    confirmPasswordLabel: {
        "en-GB": "Confirm Password",
        "es-ES": "Confimar Contraseña",
        "ca-ES": "Confirmar Contrassenya"
    },
    emailNotValidErrorLabel: {
        "en-GB": "Please, email is not valid",
        "es-ES": "Por favor, el email no es válido",
        "ca-ES": "Si us plau, el email no és vàlid"
    },
    emailMandatoryErrorLabel: {
        "en-GB": "Please, email is mandatory",
        "es-ES": "Por favor, el email es obligatorio",
        "ca-ES": "Si us plau, el email és obligatori"
    },
    minPasswordLengthError: {
        "en-GB": "Please, password must be at least 8 characters",
        "es-ES": "Por favor, la contraseña debe tener al menos 8 caracteres.",
        "ca-ES": "Si us plau, la contrassenya ha de tenir com a mínim 8 caracters"
    },
    passwordsDontMatch: {
        "en-GB": "Passwords don't match",
        "es-ES": "Las contraseñas no coinciden",
        "ca-ES": "Les contrassenyes no coincideixen"
    },
    passwordMandatoryErrorLabel: {
        "en-GB": "Please, password is mandatory",
        "es-ES": "Por favor, la contraseña es obligatoria",
        "ca-ES": "Si us plau, la contrassenya és obligatòria"
    },
    confirmPasswordMandatoryErrorLabel: {
        "en-GB": "Please, confirm password is mandatory",
        "es-ES": "Por favor, la confirmación de contraseña es obligatoria",
        "ca-ES": "Si us plau, la confirmació de contrassenya és obligatòria"
    },
    passwordNotValidErrorLabel: {
        "en-GB": "Please, password is not valid. Make sure it's at least 8 characters long, with at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, and one special character from this list: [!,@,#,$,%,^,&,*]",
        "es-ES": "Por favor, la contraseña no es válida. Debe tener al menos 8 caracteres, con mínimo 1 carácter alfanumérico minúscula, 1 carácter alfanumérico mayúscula, 1 carácter numérico, y un carácter especial de la siguiente lista: [!,@,#,$,%,^,&,*]",
        "ca-ES": "Si us plau, la contrassenya no és vàlida. Ha de tenir com a mínim 8 caracters, amb mínim 1 lletra minúscula, 1 lletra majúscula, 1 dígit, i un caràcter especial de llista següent: [!,@,#,$,%,^,&,*]"
    },
    submit: {
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "ca-ES": "Envia"
    },
    submitting: {
        "en-GB": "Submitting...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    },
    isMandatory: {
        "en-GB": "is mandatory.",
        "es-ES": "es obligatorio.",
        "ca-ES": "és requerit."
    },
    isNotValid: {
        "en-GB": "is not valid.",
        "es-ES": "no es válido.",
        "ca-ES": "no és vàlid."
    }
};

interface IInputLiterals
{
    emailLabel: string;
    passwordLabel: string;
    confirmPasswordLabel: string;
    emailNotValidErrorLabel: string;
    emailMandatoryErrorLabel: string;
    minPasswordLengthError: string;
    passwordsDontMatch: string;
    passwordMandatoryErrorLabel: string;
    confirmPasswordMandatoryErrorLabel: string;
    passwordNotValidErrorLabel: string;
    submit: string;
    submitting: string;
    isMandatory: string;
    isNotValid: string;
};

export { InputLiterals };
export type { IInputLiterals };