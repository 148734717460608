import { GenericResponse } from "../components/Api/GenericResponse";
import { AppState } from "../components/AppState";
import { getDefaultJsonAjaxHeaders } from "./AuthService";

export interface RequestOptions 
{
    method: string;
    headers: any;
    body: any;
}

export function Fetch(route: string, method: string, body: any, appState: AppState, resultFn: (value: GenericResponse) => void) {
    const fetchRequestOptions = {
        method: method,
        headers: getDefaultJsonAjaxHeaders(appState),
        body: JSON.stringify(body)
    };

  fetch(route, fetchRequestOptions)
    .then(res => res.json())
    .then(result => {
        resultFn(result as GenericResponse);
    });
}

export function PostJson(route: string, body: any, appState: AppState, resultFn: (value: GenericResponse) => void, handleErrorFn?: (result: Response) => void) {
    const fetchRequestOptions = {
        method: 'POST',
        headers: getDefaultJsonAjaxHeaders(appState),
        body: JSON.stringify(body)
    };

  fetch(route, fetchRequestOptions)
    .then(response => {
        if(!response.ok)
        {
            if(handleErrorFn) {
                handleErrorFn(response);
            }
            throw new Error('Abort promise');
        }
        return response.json()
    })
    .then(result => {
        resultFn(result as GenericResponse);
    })
    .catch(error => {
        console.error('There was a problem with the fetch operation');
    });
}

export async function PostJsonAsync(route: string, body: any, appState: AppState): Promise<GenericResponse> {
    return await FetchJsonAsync("POST", route, body, appState);
}

export async function GetJsonAsync(route: string, appState: AppState): Promise<GenericResponse> {
    return await FetchJsonAsync("GET", route, {}, appState);
}

async function FetchJsonAsync(method: string, route: string, body: any, appState: AppState): Promise<GenericResponse> {
    try {
        let fetchRequestOptions = {
            method: method,
            headers: getDefaultJsonAjaxHeaders(appState)
        } as any;
        if(method !== "GET" && method !== "HEAD") 
        {
            fetchRequestOptions.body = JSON.stringify(body);
        }

        var response = await fetch(route, fetchRequestOptions);
        if(!response.ok) 
        {
            if(appState.handleApiErrorFn) {
                appState.handleApiErrorFn(response);
            }
            return { succeeded: false, errorMessage: "An unexpected error occurred"};
        }
    
        var json = await response.json();
    
        return json as GenericResponse;
    }
    catch(e)
    {
        console.error((e as any).message);
        return { succeeded: false, errorMessage: "An unexpected error occurred"};
    }
}