const FooterLiterals = {
    privacyPolicy: {
        "en-GB": "Privacy Policy",
        "es-ES": "Política de Privacidad y Aviso Legal",
        "ca-ES": "Política de Privacitat i Avís Legal"
    },
    cookiePolicy: {
        "en-GB": "Cookie Policy",
        "es-ES": "Política de Cookies",
        "ca-ES": "Política de Cookies"
    },
    licensing: {
        "en-GB": "Licensing",
        "es-ES": "Licenciamiento",
        "ca-ES": "Llicenciament"
    }
}

interface IFooterLiterals {
    privacyPolicy: string;
    cookiePolicy: string;
    licensing: string;
}

export { FooterLiterals };
export type { IFooterLiterals };