var DashboardLiterals = {
    myAccountLabel: {
        "en-GB": "My Account",
        "es-ES": "Mi Cuenta",
        "ca-ES": "El meu Compte"
    },
    firstNameLabel: {
        "en-GB": "First Name",
        "es-ES": "Nombre",
        "ca-ES": "Nom"
    },
    lastNameLabel: {
        "en-GB": "Last Name",
        "es-ES": "Apellidos",
        "ca-ES": "Cognoms"
    },
    languageLabel: {
        "en-GB": "Preferred Language",
        "es-ES": "Idioma preferido",
        "ca-ES": "Idioma preferit"
    },
    saveLabel: {
        "en-GB": "Save",
        "es-ES": "Guardar",
        "ca-ES": "Desa"
    },
    savingLabel: {
        "en-GB": "Saving...",
        "es-ES": "Guardando...",
        "ca-ES": "Desant.."
    },
    cancelLabel: {
        "en-GB": "Cancel",
        "es-ES": "Cancelar",
        "ca-ES": "Cancel·la"
    },
    firstNameMandatoryErrorLabel: {
        "en-GB": "Please, first name is mandatory.",
        "es-ES": "Por favor, el nombre es un campo requerido",
        "ca-ES": "Si us plau, el nom és un camp requerit"
    }
};

export { DashboardLiterals };