import React from 'react';
import { ForgotPasswordLiterals } from '../../literals/account/ForgotPasswordLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

const ForgotPasswordSent = ({appState}: AppProps) => {

  const literals = getLiteralsForCulture(ForgotPasswordLiterals, appState.currentCulture) as any;
    
    return (
      <div>
          <section className="fit theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-2 col-md-10">
                      <h2>{literals.forgotPasswordSent}</h2>

                      <p>{literals.forgotPasswordSentDesc}</p>
                  </div>
              </div>
          </section>
      </div>
    );
  
}

export { ForgotPasswordSent };
