import React from "react";

interface ICustomerStory {
    imageLogo?: string;
    companyTitle?: string;
    feedback: string;
    author: string;
    role: string;
};

const CustomerStory = ({imageLogo, companyTitle, feedback, author, role}: ICustomerStory) => {
    return (
        <div>
            <div className="row">
                <div className="form-group col-md-12 text-center">
                    {imageLogo && <p><img src={imageLogo} style={{height: '100px'}}/></p>}
                    {companyTitle && <h3>{companyTitle}</h3>}
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-offset-2 col-md-8 text-center">
                    <p><i className="fa fa-quote-left"></i> <span style={{fontWeight: "bold"}}>{feedback}</span> <i className="fa fa-quote-right"></i></p>
                    <hr style={{opacity: "0.2"}} />
                    <p>{author} - {role}</p>
                </div>
            </div>
        </div>
    );
};

export { CustomerStory };