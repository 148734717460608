import React from "react";
import { Component, ReactNode } from "react";
import { IInputLiterals, InputLiterals } from "../../../literals/input/InputLiterals";
import { getLiteralsForCulture } from "../../../literals/Literals";
import { DropDownInput } from "../DropDownInput";
import { TextInput } from "../TextInput";
import { DropwDownInputProps } from "../types";

interface DropDownFieldOptions extends DropwDownInputProps {
    label: string;
    currentCulture: string;
}

export class DropDownInputField extends Component<DropDownFieldOptions, {}> {

    constructor(props: DropDownFieldOptions) {
        super(props);
    }

    render(): ReactNode {

        const inputLiterals = getLiteralsForCulture(InputLiterals, this.props.currentCulture) as IInputLiterals; 
        
        return (
        <div>
            <label>
                <label htmlFor={this.props.name}>{this.props.label}</label>
            </label>
            <DropDownInput
                id={this.props.id}
                name={this.props.name}
                placeHolder={this.props.placeHolder} 
                
                options={this.props.options}

                usesValidation={this.props.usesValidation}
                displayValidation={this.props.displayValidation}
                validationErrorMessage={this.props.validationErrorMessage ? this.props.validationErrorMessage : `${this.props.label} ${inputLiterals.isNotValid}`}
               
                isMandatory={this.props.isMandatory}
                mandatoryErrorMessage={this.props.mandatoryErrorMessage ? this.props.mandatoryErrorMessage : `${this.props.label} ${inputLiterals.isMandatory}`}
                
                onChange={this.props.onChange}
                value={this.props.value} />
        </div>);
        
    }
}