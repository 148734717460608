import React, { Component } from 'react';
import { CookiesPolicyLiterals, ICookiesPolicyLiterals } from '../../literals/legal/CookiePolicyLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export class CookiesPolicy extends Component<AppProps> {

  componentDidMount(): void {
    const s = document.createElement('script');
    const cookieBotId = process.env.REACT_APP_COOKIE_BOT_ID;
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://consent.cookiebot.com/${cookieBotId}/cd.js`;

    const placeHolder = document.getElementById("CookieDeclarationPlaceholder");
    if(placeHolder)
        placeHolder.appendChild(s);
  }

  render () {
    var literals = getLiteralsForCulture(CookiesPolicyLiterals, this.props.appState.currentCulture) as ICookiesPolicyLiterals;

    return (
        <div>
            <section className="theme-white">
                <h1>{literals.cookiesPolicyLabel}</h1>
                <p>{literals.lastUpdate}</p>

                <h2>{literals.cookieDefinitionTitle}</h2>
            
                <p>{literals.cookieDefinitionParagraph01}</p>
                <p>{literals.cookieDefinitionParagraph02}</p>
                <p>{literals.cookieDefinitionParagraph03}</p>
            
                <h2>{literals.cookieDeclarationTitle}</h2>
                <div id="CookieDeclarationPlaceholder">

                </div>
                
                <h2>{literals.thirdPartyAccessTitle}</h2>
                <p></p>
  
                <h2>{literals.disablingCookiesTitle}</h2>
                <p>{literals.disablingCookiesDesc}</p>
            
                <h2>{literals.howToDisableCookiesTitle}</h2>
                <p>{literals.howToDisableCookiesParagraph01}</p>
                <p>{literals.howToDisableCookiesParagraph02}</p>

                <ul>
                    <li><b>Chrome</b>: <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">https://support.google.com/chrome/answer/95647?hl=es</a>.</li>
                    <li><b>Internet Explorer</b>: <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank">http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-11</a>.</li>
                    <li><b>Safari: </b><a href="https://support.apple.com/es-es/HT201265" target="_blank">https://support.apple.com/es-es/HT201265</a></li>
                    <li><b>Microsoft Edge:</b><a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank">https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a></li>
                    <li><b>Firefox:</b><a href="https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia" target="_blank">https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia</a></li>
                </ul>
  
                <p>{literals.howToDisableCookiesParagraph03}</p>

                <h2>{literals.crossBorderDataTransfersTitle}</h2>
                <p>{literals.crossBorderDataTransfersDesc}</p>

                <h2>{literals.profilesTitle}</h2>
                <p>{literals.profilesDesc}</p>

                <h2>{literals.dataRetentionTitle}</h2>
                <p>{literals.dataRetentionDesc}</p>

                <h2>{literals.moreInfoTitle}</h2>
                <p>{literals.moreInfoDesc}</p>

                <h2>{literals.cookiePolicyUpdatesTitle}</h2>
                <p>{literals.cookiePolicyUpdatesDesc}</p>
            </section>
        </div>
    );
  }
}
