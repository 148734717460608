import React, { Component } from 'react';
import { ILicensingLiterals, LicensingLiterals } from '../../literals/legal/LicensingLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export class Licensing extends Component<AppProps> {



  render () {
    var literals = getLiteralsForCulture(LicensingLiterals, this.props.appState.currentCulture) as ILicensingLiterals;

    return (
        <div>
            <section className="theme-white">
                <h1>{literals.title}</h1>
                <p dangerouslySetInnerHTML={{__html: literals.desc}}></p>
            </section>
        </div>
    );
  }
}
