var QuoteLiterals = {
    quoteLabel: {
        "en-GB": "Get a Quote",
        "es-ES": "Solicitar Presupuesto",
        "ca-ES": "Sol·licitar Pressupost"
    },
    quoteEmailUsLabel: {
        "en-GB": "Please, email us at sales@dynamicsvalue.com to get a quote for your company.",
        "es-ES": "Por favor, envíenos un email a sales@dynamicsvalue.com para solicitar un presupuesto.",
        "ca-ES": "Si us plau, envieu-nos un email a sales@dynamicsvalue.com per a sol·licitar un pressupost."
    },
    quoteSubHeadingLabel: 
    {
        "en-GB": "Please use the form below to get a quote for your company. ",
        "es-ES": "Por favor rellene el formulario a continuación para recibir un presupuesto para su empresa",
        "ca-ES": "Si us plau, ompli el formulari següent per tal d'obtenir un pressupost per a la seva empresa"
    },
    emailAddressLabel: 
    {
        "en-GB": "Email Address",
        "es-ES": "Correo electrónico",
        "ca-ES": "Correu electrònic"
    },
    numberOfDevsLabel: 
    {
        "en-GB": "Number of Users*",
        "es-ES": "Número de Usuarios*",
        "ca-ES": "Nombre d'Usuaris*"
    },
    numberOfUsersDescriptionLabel: 
    {
        "en-GB": "* A user is an individual who needs to write or debug any code where FakeXrmEasy is used",
        "es-ES": "* Un usuario es una persona que escribe o depura código que use FakeXrmEasy",
        "ca-ES": "* Un usuari és una persona que escriu o depura codi que utilitza FakeXrmEasy"
    },
    numberOfDevsLabelMandatoryErrorLabel: {
        "en-GB": "Please, the number of users field is mandatory",
        "es-ES": "Por favor, introduce un correo electrónico",
        "ca-ES": "Si us plau, introdueix un correu electrònic"
    },
    dontAllowEmailMarketingLabel: 
    {
        "en-GB": "I do not wish to receive information about services or offers that may interest me.",
        "es-ES": "No deseo recibir información de servicios o ofertas que me puedan interesar",
        "ca-ES": "No vull rebre informació de serveis o ofertes que em puguin interessar"
    },
    contactSentLabel: {
        "en-GB": "Contact Request Received",
        "es-ES": "Solicitud de Contacto Recibida",
        "ca-ES": "Sol·licitud de Contacte Rebuda"
    },
    contactSentParagraphLabel: {
        "en-GB": "Thanks a million for getting in touch.",
        "es-ES": "Muchísimas gracias por contactar con nosotros.",
        "ca-ES": "Moltíssimes gràcies per contactar amb nosaltres. "
    },
    contactGetBackParagraphLabel: {
        "en-GB": "We'll review your request and we'll get back to you as soon as possible.",
        "es-ES": "Revisaremos to solicitud de contacto y nos pondremos en contacto contigo tan pronto como sea posible.",
        "ca-ES": "Revisarem la teva sol·licitud de contacte i ens posarem amb contacte tant aviat com sigui possible."
    },
    submitLabel: {
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "ca-ES": "Envia"
    },
    submittingLabel: {
        "en-GB": "Submitting...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    },
    iAgreeToTheLabel: {
        "en-GB": "I agree to",
        "es-ES": "He leído y acepto",
        "ca-ES": "He llegit i accepto"
    },
    legalNoticeLabel: {
        "en-GB": " Legal Notice ",
        "es-ES": " Aviso Legal ",
        "ca-ES": " Avís Legal "
    },
    andTheLabel: {
        "en-GB": " and the ",
        "es-ES": " y la ",
        "ca-ES": " i la "
    },
    privacyPolicyLabel: {
        "en-GB": " Privacy Policy",
        "es-ES": " Política de Privacidad",
        "ca-ES": " Política de Privacitat"
    },
    mustAcceptedTosLabel: {
        "en-GB": " Please, you must accept the terms of service and privacy policy",
        "es-ES": " Por favor, debe aceptar las condiciones de uso y política de privacidad",
        "ca-ES": " Si us plau, ha d'acceptar les condicions d'ús i la política de privacitat"
    }
};


export { QuoteLiterals };