import { AppState } from "../components/AppState";

export function getXsrfToken() : string
{
    var token = document.cookie
          .split("; ")
          .find(row => row.startsWith("XSRF-TOKEN="))
          ?.split("=")[1];

    if(!token)
        return "";
    
    return token;
}

export function getDefaultJsonAjaxHeaders(appState: AppState) : any
{
    return { 
        "Content-Type": "application/json",
        "X-XSRF-TOKEN":  getXsrfToken(),
        "X-APP-CULTURE": appState.currentCulture
      };
}