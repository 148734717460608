import React, { Component } from 'react';
import { IPrivacyPolicyLiterals, PrivacyPolicyLiterals } from '../../literals/legal/PrivacyPolicyLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export class PrivacyPolicy extends Component<AppProps> {
  render () {
    const literals = getLiteralsForCulture(PrivacyPolicyLiterals, this.props.appState.currentCulture) as IPrivacyPolicyLiterals;

    return (
      <div>
          <section className="theme-white">
            <h1>{literals.privacyPolicyTitle}</h1>
            <p>{literals.lastUpdated}</p>
            <p dangerouslySetInnerHTML={{__html: literals.privacyPolicyDesc}} ></p>

            <h2>{literals.dataControllerTitle}</h2>
            <p dangerouslySetInnerHTML={{__html: literals.dataControllerDesc}}></p>

            <h3>{literals.legitimateTitle}</h3>
            <p dangerouslySetInnerHTML={{__html: literals.consentDesc}}></p>
            <p dangerouslySetInnerHTML={{__html: literals.legitimateInterest}}></p>
            <ul>
              <li><p dangerouslySetInnerHTML={{__html: literals.legitimateInterest01}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.legitimateInterest02}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.legitimateInterest03}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.legitimateInterest04}}></p></li>
            </ul>
            <p dangerouslySetInnerHTML={{__html: literals.contractLegitimateInterest}}></p>

            <h3>{literals.purposeOfTreatmentTitle}</h3>
            <ul>
              <li><p dangerouslySetInnerHTML={{__html: literals.purposeBulletPoint01}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.purposeBulletPoint02}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.purposeBulletPoint03}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.purposeBulletPoint04}}></p></li>
              <li><p dangerouslySetInnerHTML={{__html: literals.purposeBulletPoint05}}></p></li>
            </ul>

            

            <h3>{literals.recipientsTitle}</h3>
            <p>{literals.recipientsDesc}</p>

            <h3>{literals.conservation}</h3>
            <p>{literals.conservationParagraph01}</p>
            <p>{literals.conservationParagraph02}</p>
            <p>{literals.conservationParagraph03}</p>

            <h3>{literals.rights}</h3>
            <p>{literals.rightsParagraph01}</p>
            <p dangerouslySetInnerHTML={{__html: literals.rightsParagraph02}}></p>
            <p dangerouslySetInnerHTML={{__html: literals.rightsParagraph03}}></p>

            <h3>{literals.minorsProtection}</h3>
            <p>{literals.minorsProtectionDesc}</p>

            <h3>{literals.socialNetworks}</h3>
            <p>{literals.socialNetworksParagraph01}</p>
            <p>{literals.socialNetworksParagraph02}</p>

            <h3>{literals.dataTransfersTitle}</h3>
            <p>{literals.dataTransfersDesc}</p>
            
            <h1>{literals.legalNotice}</h1>

            <h3>{literals.identification}</h3>
            <p dangerouslySetInnerHTML={{__html: literals.identificationDesc}}></p>
            <p>
              DYNAMICS VALUE, S.L. <br/>
              C/ Salamanca, 8 <br/>
              08620 Sant Vicenc dels Horts <br/>
              Barcelona <br/>
              España <br/>
              info@dynamicsvalue.com <br/>
              ES B66534538 <br/>
            </p>

            <h3>{literals.intellectualProperty}</h3>
            <p>{literals.intellectualPropertyParagraph01}</p>
            <p>{literals.intellectualPropertyParagraph02}</p>

            <h3>{literals.termsOfUse}</h3>
            <p>{literals.termsOfUseParagraph01}</p>
            <p>{literals.termsOfUseParagraph02}</p>
            <p>{literals.termsOfUseParagraph03}</p>
            <p>{literals.termsOfUseParagraph04}</p>
            <p>{literals.termsOfUseParagraph05}</p>
            <p>{literals.termsOfUseParagraph06}</p>

            <h3>{literals.otherLinks}</h3>
            <p>{literals.otherLinksDesc}</p>

            <h3>{literals.cookies}</h3>
            <p dangerouslySetInnerHTML={{__html: literals.cookiesDesc}}></p>

            <h3>{literals.jurisdiction}</h3>
            <p>{literals.jurisdictionParagraph01}</p>
            <p>{literals.jurisdictionParagraph02}</p>
            <p>{literals.jurisdictionParagraph03}</p>
        </section>
      </div>
    );
  }
}
