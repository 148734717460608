import { Currency, GetEurCurrency } from "./Currency";

export interface Price {
    amount: number;
    currency: Currency;
}


const GetBoutiquePricingMonthly = (currency: Currency) => {
    if(currency.isoCode === "USD")
    {
        return <Price> {amount: 35, currency: currency};
    }
    else if(currency.isoCode === "GBP")
    {
        return <Price> {amount: 30, currency: currency};
    }
    else if(currency.isoCode === "AUD")
    {
        return <Price> {amount: 54, currency: currency};
    }
    else {
        return <Price> {amount: 35, currency: GetEurCurrency()};
    }
};

const GetBoutiquePricingYearly = (currency: Currency) => {
    var price = GetBoutiquePricingMonthly(currency);
    price.amount = price.amount * 10;
    return price;
};

const GetTeamPricingMonthly = (currency: Currency) => {
    if(currency.isoCode === "USD")
    {
        return <Price> {amount: 30, currency: currency};
    }
    else if(currency.isoCode === "GBP")
    {
        return <Price> {amount: 26, currency: currency};
    }
    else if(currency.isoCode === "AUD")
    {
        return <Price> {amount: 47, currency: currency};
    }
    else {
        return <Price> {amount: 30, currency: GetEurCurrency()};
    }
};

const GetTeamPricingYearly = (currency: Currency) => {
    var price = GetTeamPricingMonthly(currency);
    price.amount = price.amount * 10;
    return price;
};


const GetLargePricingMonthly = (currency: Currency) => {
    if(currency.isoCode === "USD")
    {
        return <Price> {amount: 25, currency: currency};
    }
    else if(currency.isoCode === "GBP")
    {
        return <Price> {amount: 21, currency: currency};
    }
    else if(currency.isoCode === "AUD")
    {
        return <Price> {amount: 39, currency: currency};
    }
    else {
        return <Price> {amount: 25, currency: GetEurCurrency()};
    }
};

const GetLargePricingYearly = (currency: Currency) => {
    var price = GetLargePricingMonthly(currency);
    price.amount = price.amount * 10;
    return price;
};

const GetBusinessPricing = (currency: Currency) => {
    if(currency.isoCode === "USD")
    {
        return <Price> {amount: 6000, currency: currency};
    }
    else if(currency.isoCode === "GBP")
    {
        return <Price> {amount: 5220, currency: currency};
    }
    else if(currency.isoCode === "AUD")
    {
        return <Price> {amount: 9375, currency: currency};
    }
    else {
        return <Price> {amount: 6000, currency: GetEurCurrency()};
    }
};

const GetEnterprisePricing = (currency: Currency) => {
    if(currency.isoCode === "USD")
    {
        return <Price> {amount: 8000, currency: currency};
    }
    else if(currency.isoCode === "GBP")
    {
        return <Price> {amount: 6960, currency: currency};
    }
    else if(currency.isoCode === "AUD")
    {
        return <Price> {amount: 12500, currency: currency};
    }
    else {
        return <Price> {amount: 8000, currency: GetEurCurrency()};
    }
};

const GetUltimatePricing = (currency: Currency) => {
    if(currency.isoCode === "USD")
    {
        return <Price> {amount: 12000, currency: currency};
    }
    else if(currency.isoCode === "GBP")
    {
        return <Price> {amount: 10500, currency: currency};
    }
    else if(currency.isoCode === "AUD")
    {
        return <Price> {amount: 18775, currency: currency};
    }
    else {
        return <Price> {amount: 12000, currency: GetEurCurrency()};
    }
};

export {
    GetBoutiquePricingMonthly,
    GetBoutiquePricingYearly,
    GetTeamPricingMonthly,
    GetTeamPricingYearly,
    GetLargePricingMonthly,
    GetLargePricingYearly,
    GetBusinessPricing,
    GetEnterprisePricing,
    GetUltimatePricing,
}