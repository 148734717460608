import React, { useEffect, useState } from 'react';
import { BillingAddressLiterals, IBillingAddressLiterals } from '../../../literals/account/billing/BillingAddressLiterals';
import { getLiteralsForCulture } from '../../../literals/Literals';
import { AppProps } from '../../AppState';
import { Help } from '../../Help/Help';
import { Loading } from '../../Loading';
import { BillingAddress } from './BillingAddress';

const Billing = (appProps: AppProps) => {
  
  const [ isLoading, setIsLoading ] = useState(true);
  
  useEffect(() => {
    const getAuthenticated = async() => {
      await appProps.appState.isAuthenticated();
      setIsLoading(false);
    };
    getAuthenticated();
  }, []);

  const literals = getLiteralsForCulture(BillingAddressLiterals, appProps.appState.currentCulture) as IBillingAddressLiterals; 
  
  if(isLoading) {
    return <Loading />;
  }
  
  return (<div>
    <section className="theme-dark-gray">
      <div className="row">
          <div className="form-group col-md-offset-1 col-md-10">
              <h2>{literals.title}</h2>
          </div>
      </div>
      <div className="row">
          <div className="col-md-offset-1 col-md-5">
            <div className="row">
              <BillingAddress appState={appProps.appState} ></BillingAddress>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
                <div className="col-md-offset-2 col-md-10">
                <Help appState={appProps.appState} />
              </div>
            </div>
        </div>
      </div>
    </section>
  </div>);
}

export { Billing };