import React from "react";
import { getLiteralsForCulture } from "../../literals/Literals";
import { QuoteLiterals } from "../../literals/quote/QuoteLiterals";
import { AppProps } from "../AppState";

export const QuoteTemp = (appProps: AppProps) => 
 {
    const literals = getLiteralsForCulture(QuoteLiterals, appProps.appState.currentCulture) as any;
    return (<div>
        <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                    <h2>{literals.quoteLabel }</h2>
                    <p>{literals.quoteEmailUsLabel}</p>
                    <h3><i aria-hidden="true" className="fa fa-envelope"></i> sales@dynamicsvalue.com</h3>
                  </div>
              </div>
        </section>
    </div>);
 }