var NavMenuLiterals = {

    languageSelected: {
        "en-GB": "English",
        "es-ES": "Castellano",
        "ca-ES": "Català"
    },
    homeLabel: 
    {
        "en-GB": "Home",
        "es-ES": "Inicio",
        "ca-ES": "Inici"
    },
    whyLabel: 
    {
        "en-GB": "Why?",
        "es-ES": "¿Por qué?",
        "ca-ES": "Per què?"
    },
    docsLabel: 
    {
        "en-GB": "Docs",
        "es-ES": "Documentación",
        "ca-ES": "Documentació"
    },
    getStartedLabel: 
    {
        "en-GB": "Get Started",
        "es-ES": "Empezar",
        "ca-ES": "Començar"
    },
    customerStoriesLabel: {
        "en-GB": "Customer Stories",
        "es-ES": "Clientes",
        "ca-ES": "Clientes"
    },
    pricingLabel: 
    {
        "en-GB": "Pricing",
        "es-ES": "Precios",
        "ca-ES": "Preus"
    },
    trainingLabel: 
    {
        "en-GB": "Training",
        "es-ES": "Formación",
        "ca-ES": "Formació"
    },
    contactLabel: 
    {
        "en-GB": "Contact",
        "es-ES": "Contacto",
        "ca-ES": "Contacte"
    },
    myAccountLabel: 
    {
        "en-GB": "My Account",
        "es-ES": "Mi Cuenta",
        "ca-ES": "El meu compte"
    },
    signOutLabel: 
    {
        "en-GB": "Sign Out",
        "es-ES": "Cerrar sesión",
        "ca-ES": "Tanca sessió"
    }
}

export { NavMenuLiterals };