const EmailLiterals = {

    email: {
        "en-GB": "Email",
        "es-ES": "Email",
        "ca-ES": "Email"
    },
    emailNotVerifiedDescription: {
        "en-GB": "Your account has not been verified yet. Please check your inbox and verify your account. Or else resend the verification email via button below if you can't find it.",
        "es-ES": "Tu cuenta aún no se ha verificado. Por favor, comprueba tu bandeja de entrada el correo de verificación, o, si no lo encuentras, puedes reenviar otro correo de verificación.",
        "ca-ES": "El teu compte encara no s'ha verificat. Si us plau, comprova a la teva safata d'entrada que has rebut el correu de verificació, o bé, si no el trobes, pots reenviar un altre correu de verificació."
    },
    verified: {
        "en-GB": "Verified",
        "es-ES": "Verificado",
        "ca-ES": "Verificat"
    },
    notVerified: {
        "en-GB": "Not verified",
        "es-ES": "No verificado",
        "ca-ES": "No verificat"
    },
    resendEmail: {
        "en-GB": "Resend email",
        "es-ES": "Reenviar correo",
        "ca-ES": "Reenviar correu"
    },
    sending: {
        "en-GB": "Sending...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    }
};

interface IEmailLiterals
{
    email: string;
    emailNotVerifiedDescription: string;
    verified: string;
    notVerified: string;
    resendEmail: string;
    sending: string;
};

export { EmailLiterals };
export type { IEmailLiterals };
