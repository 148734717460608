import React, { Component } from 'react';
import { LandingTop } from './Home/LandingTop';
import { MainHomeBanner } from './Home/MainHomeBanner';
import { HomeWhyFakeXrmSection} from './Home/HomeWhyFakeXrmSection';

import $ from 'jquery';
import { Community } from './Home/Community';
import { HomeLiterals } from '../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../literals/Literals';
import { AnimationComponentUtils } from './Utils/ComponentUtils';
import { Footer } from './Footer';
import { AppState } from './AppState';
import { Testimonials } from './Home/Testimonials';
import { ResearchStudy } from './Home/ResearchStudy';
import { CustomerStories } from '../components/CustomerStories/CustomerStories';
interface HomeProps
{
  appState: AppState;
}

export class Home extends Component<HomeProps> {
  static displayName = Home.name;

  render () {
    var literals = getLiteralsForCulture(HomeLiterals, this.props.appState.currentCulture) as any;
    
    return (
      <div style={{height: "100%"}}>
          <LandingTop appState={this.props.appState}></LandingTop>
          <ResearchStudy appState={this.props.appState}></ResearchStudy>
          <HomeWhyFakeXrmSection appState={this.props.appState}></HomeWhyFakeXrmSection>
          <Testimonials appState={this.props.appState}></Testimonials>
          <MainHomeBanner appState={this.props.appState}></MainHomeBanner>
          <CustomerStories appState={this.props.appState}></CustomerStories>
          <Community appState={this.props.appState}></Community>
          <Footer appState={this.props.appState}></Footer>
      </div>
    );
  }

  checkIfInView($window: any, $animation_elements: any)  
  {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight() as number;
        var element_top_position = ($element.offset() as JQueryCoordinates).top;
        var element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('in-view');
        } else {
            $element.removeClass('in-view');
        }

    });
  }
  componentDidMount()
  {
    var self = this;

    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
