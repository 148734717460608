import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ForgotPasswordLiterals } from '../../literals/account/ForgotPasswordLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { getDefaultJsonAjaxHeaders } from '../../services/AuthService';
import { GenericResponse } from '../Api/GenericResponse';
import { AppProps, AppState } from '../AppState';
import { Button } from 'reactstrap';
import { InputState } from '../Input/types';
import { EmailInput } from '../Input/EmailInput';
import { InputLiterals } from '../../literals/input/InputLiterals';

const ForgotPasswordAsync = async (appState: AppState, email: string) => {
  const requestOptions = {
    method: "POST",
    headers: getDefaultJsonAjaxHeaders(appState),
    body: JSON.stringify({Email: email})
  };

  var response = await fetch("/api/account/forgot-pass", requestOptions);
  var result = await response.json() as GenericResponse;
  return result;
}

const ForgotPassword = ({appState}: AppProps) => {
  const history = useHistory();

  const initialConfirmResult: GenericResponse = {
    succeeded: false,
    errorMessage: ""
  };

  const initialEmailInputState: InputState = {isValid: false, isDisabled: false, value: "", isValidated: false};

  const [ emailInpuState, setEmailInputState ] = useState(initialEmailInputState);
  const [ showValidation, setShowValidation ] = useState(false);
  const [ resetResult, setResetResult ] = useState(initialConfirmResult);
  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const literals = getLiteralsForCulture(ForgotPasswordLiterals, appState.currentCulture) as any;
  const inputLiterals = getLiteralsForCulture(InputLiterals, appState.currentCulture) as any;

  const emailOnChange = (newState: InputState) => {
    setEmailInputState(newState);
  }

  const resetPass = async () => {
    setShowValidation(true);
    if(emailInpuState.isValid)
    {
      setIsSubmitting(true);
      const result = await ForgotPasswordAsync(appState, emailInpuState.value);
      if(result.succeeded)
      {
        history.push("/account/forgot-password/sent");
      }
      else {

      }
      setIsSubmitting(false);
    }
  };

 
  return (
    <div>
        <section className="theme-dark-gray">
          <div className="row">
              <div className="form-group col-md-offset-4 col-md-4">
                  <h2>{literals.resetPassword }</h2>
                  <p>{literals.resetPasswordDesc}</p>
              </div>
          </div>
          <div className="row">
              <div className="col-md-offset-4 col-md-4">
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="email">{inputLiterals.emailLabel}</label>
                            <EmailInput 
                              id="email" 
                              name="email" 
                              placeHolder={inputLiterals.emailLabel} 
                              inputType="text" 
                              displayValidation={showValidation} 
                              usesValidation={true} 
                              isMandatory={true} 
                              mandatoryErrorMessage={inputLiterals.emailMandatoryErrorLabel} 
                              validationErrorMessage={inputLiterals.emailNotValidErrorLabel}
                              value={emailInpuState.value}
                              onChange={emailOnChange}>
                            </EmailInput>
                        </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        {!isSubmitting && <Button className="btn btn-primary" onClick={resetPass}>{inputLiterals.submit}</Button>}
                        {isSubmitting && <Button className="btn btn-primary" onClick={resetPass} disabled={true}>{inputLiterals.submitting}</Button>}
                      </div>  
                    </div>
              </div>
          </div>
        </section>
    </div>
  );
}

export { ForgotPassword }

