import React, { Component } from 'react';
import { HomeLiterals } from '../../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export const MainHomeBanner = (appProps: AppProps) => 
{
    const literals = getLiteralsForCulture(HomeLiterals, appProps.appState.currentCulture) as any;
    
    return (
        <section className="theme-white bordered" style={{overflowX: "hidden"}}>
            <div className="row animation-element fade-in">
                <div className="col-md-12 text-center">
                    <h1>{literals.whatIsFakeXrmEasyLabel}</h1>
                    <p>{literals.fakeXrmEasyDescLabel}</p>
                    <p style={{fontWeight: 'bold'}}>{literals.fakeXrmEasyHealthyLabel}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 text-center animation-element slide-left">
                    <span className="fa fa-bolt fa-4x green"></span>
                    <h2>Azure Functions</h2>
                </div>
                <div className="col-md-3 text-center  animation-element fade-in">
                    <span className="fa fa-cogs fa-4x green"></span>
                    <h2>Plugins</h2>      
                </div>
                <div className="col-md-3 text-center  animation-element fade-in">
                <span className="fa fa-code-fork fa-4x green"></span>
                    <h2>Code Activities</h2>
                </div>
                <div className="col-md-3 text-center  animation-element slide-right">
                    <span className="fa fa-code fa-4x green"></span>
                    <h2>{literals.otherLabel}</h2>
                </div>
            </div>
        </section>
    );
    
}