import React, { Component } from 'react';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { Link } from 'react-router-dom';


interface GetStartedProps 
{
    isOverview?: boolean;
    isInstallation?: boolean;
    isYourFirstTest?: boolean;
    isHowToTestAPlugin?: boolean;
    isHowToTestACodeActivity?: boolean;
    isQueries?: boolean;
    isManyToManyRelationship?: boolean;
    isOtherExamples?: boolean;
}

export class GetStarted extends Component<GetStartedProps> {
  constructor(props: GetStartedProps)
  {
    super(props);
  }
  render () {
    var literals = getLiteralsForCulture(ContactLiterals, 'en-GB') as any;

    return (
      <div style={{marginTop: '10px'}}>
        <div className="row theme-green">
            <div className="col-md-12">
              <p style={{marginTop: '0', marginBottom:0, padding: '8px', textAlign: 'center'}}>NEW! This documentation section is for FakeXrmEasy versions 1.x and earlier, which are now deprecated.</p>
              <p style={{marginTop: '0', marginBottom:0, padding: '8px', textAlign: 'center'}}>Please do check our brand new documentation for FakeXrmEasy versions 2 or later that support both the CRM SDK and the new DataverseClient in .net core <a href="https://dynamicsvalue.github.io/fake-xrm-easy-docs/quickstart/migrating-from-1x/" target={"blank"}>here!</a></p>
              
            </div>
        </div>
        <section className="theme-dark-gray">
            <div className="row">
                <div className="col-md-3">
                    <ul className="sidebar">
                        <li>{!this.props.isOverview && <Link to="/get-started/overview">Overview</Link>}{this.props.isOverview && "Overview"}</li>
                        <li>{!this.props.isInstallation && <Link to="/get-started/installation" >Installation</Link>}{this.props.isInstallation && "Installation"}</li>
                        <li>{!this.props.isYourFirstTest && <Link to="/get-started/your-first-test" >Your First Test</Link>}{this.props.isYourFirstTest && "Your First Test"}</li>
                        <li>{!this.props.isHowToTestAPlugin && <Link to="/get-started/plugins" >How To Test a Plugin</Link>}{this.props.isHowToTestAPlugin && "How To Test a Plugin"}</li>
                        <li>{!this.props.isHowToTestACodeActivity && <Link to="/get-started/codeactivities" >How To Test a CodeActivity</Link>}{this.props.isHowToTestACodeActivity && "How To Test a CodeActivity"}</li>
                        <li>{!this.props.isQueries && <Link to="/get-started/queries" >Queries</Link>}{this.props.isQueries && "Queries"}</li>
                        <li>{!this.props.isManyToManyRelationship && <Link to="/get-started/nn-relationships" >N:N Relationships</Link>}{this.props.isManyToManyRelationship && "N:N Relationships"}</li>
                        <li>{!this.props.isOtherExamples && <Link to="/get-started/others" >Other Examples</Link>}{this.props.isOtherExamples && "Other Examples"}</li>
                     </ul>
                </div>
                <div className="col-md-9 animation-element fade-in">
                    {this.props.children}
                </div>
            </div>
        </section>  
      </div>
    );
  }
}
