import React, { Component } from 'react';
import { getLiteralsForCulture } from '../../literals/Literals';
import { PricingTier } from './PricingTier';
import { FlatFeePricingTier } from './FlatFeePricingTier';
import { AppProps } from '../AppState';
import { PricingLiterals } from '../../literals/pricing/PricingLiterals';
import { Link } from 'react-router-dom';
import { Currency, GetAllCurrencies } from './Currency';
import {
  GetBoutiquePricingMonthly,
  GetBoutiquePricingYearly,
  GetBusinessPricing,
  GetEnterprisePricing,
  GetLargePricingMonthly,
  GetLargePricingYearly,
  GetTeamPricingMonthly,
  GetTeamPricingYearly,
  GetUltimatePricing,
} from './Price';

class PricingState
{
  showingAnnual: boolean = false;
  selectedCurrency: Currency = { symbol: "€", isoCode: "EUR" }
  allCurrencies: Currency[] = GetAllCurrencies()
}

export class Pricing extends Component<AppProps, PricingState> {

  constructor(props: AppProps)
  {
    super(props);
    this.pricingFrequencyChanged = this.pricingFrequencyChanged.bind(this);
    this.currencyChanged = this.currencyChanged.bind(this);
    this.state = new PricingState();
  }

  pricingFrequencyChanged(e: React.ChangeEvent<HTMLInputElement>)
  {
    this.setState({
      showingAnnual: e.currentTarget.checked
    })
  }

  currencyChanged(e: React.ChangeEvent<HTMLSelectElement>)
  {
    this.setState({
      selectedCurrency: this.state.allCurrencies.filter(c => c.isoCode === e.currentTarget.value)[0]
    });
  }

  render () {
    const literals = getLiteralsForCulture(PricingLiterals, this.props.appState.currentCulture) as any;
    const currencyOptions = GetAllCurrencies().map(currency => {
      return <option value={currency.isoCode}>{currency.isoCode} ({currency.symbol})</option>
    });

    const selectedCurrency = this.state.selectedCurrency;



    return (
      <div>
          <section className="theme-white bordered pricing" style={{backgroundColor: '#078263'}}>
            <div className="pricing-container">
              <div className="theme-green">
                <h1>{literals.pricingLabel}</h1>
                <p>{literals.pricingDescLabel}</p>
                <p>{literals.userDescLabel}</p>
                <p style={{fontWeight: 'bold'}}>{literals.costEffectivePricingLabel}<Link to="/white-paper">{literals.hereLabel}</Link></p>
                
                <p>{literals.checkOutOur} <a href="https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/license/" target={"_blank"}>{literals.licence}</a> {literals.andOur} <a href="https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/faq/" target={"_blank"}>{literals.licensingFaq}</a> {literals.ifYouHaveAnyQuestions}.</p>
                <p>{literals.quoteInOtherCurrencies} <Link to="/quote">{literals.getQuoteLabel}</Link></p>
                <div style={{textAlign: 'center', marginTop: '30px'}}>
                  {this.state.showingAnnual ? <p><b>{literals.annualPlanLabel}</b></p> : <p><b>{literals.monthlyPlanLabel}</b></p>}
                  <input className="pricing-switch" type="checkbox" checked={this.state.showingAnnual} onChange={this.pricingFrequencyChanged}></input>
                </div>
                <div className="row" style={{textAlign: 'center', marginTop: '20px'}}>
                  <div className="col-md-4 col-sm-12 col-md-offset-4 col-sm-offset-0">
                    <div className="form-group">
                      <label>{literals.chooseYourCurrency}:</label>
                      <select className='form-control' onChange={this.currencyChanged} value={this.state.selectedCurrency.isoCode}>
                        {currencyOptions}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul  className="pricing-list cols4 bounce-invert">
            
            <li >
                <ul className="pricing-wrapper">
                    <li  className="is-visible is-ended" data-type="monthly">
                        <header  className="pricing-header">
                            <h2 >Community</h2>
                            <div className="price">
                                <span  className="currency"></span>
                                <span  className="value">{literals.freeLabel}</span>
                                
                            </div>
                        </header>
                        <div  className="pricing-body">
                            <ul  className="pricing-features">
                                <li ><span dangerouslySetInnerHTML={{__html: literals.communitySupportLabel}}></span></li>
                                <li ><span dangerouslySetInnerHTML={{__html: literals.forOSSProjectsLabel}}></span></li>
                                <li ><span dangerouslySetInnerHTML={{__html: literals.nonCommercialUseLabel}}></span></li>
                                <li><span dangerouslySetInnerHTML={{__html: literals.nonCommercialOrganizationsLabel}}></span></li>
                                <li ><span dangerouslySetInnerHTML={{__html: literals.diyLabel}}></span><br/><br/><br/></li> 
                            </ul>
                        </div>
                        <footer  className="pricing-footer">
                            <a  className="select" href="https://github.com/DynamicsValue/fake-xrm-easy/issues" target="_blank">GitHub</a>
                        </footer>
                    </li>
                </ul>
            </li>
            <li  className="exclusive">
              <PricingTier appState={this.props.appState} showingAnnual={this.state.showingAnnual} planName="Boutique" pricePerMonth={GetBoutiquePricingMonthly(selectedCurrency)} pricePerYear={GetBoutiquePricingYearly(selectedCurrency)} volumeOfSubscriptions='1-5'></PricingTier>
            </li>
            <li >
              <PricingTier appState={this.props.appState} showingAnnual={this.state.showingAnnual} planName="Team" pricePerMonth={GetTeamPricingMonthly(selectedCurrency)} pricePerYear={GetTeamPricingYearly(selectedCurrency)} volumeOfSubscriptions='6-10'></PricingTier>
            </li>
            <li >
              <PricingTier appState={this.props.appState} showingAnnual={this.state.showingAnnual} planName="Large" pricePerMonth={GetLargePricingMonthly(selectedCurrency)} pricePerYear={GetLargePricingYearly(selectedCurrency)} volumeOfSubscriptions='11-∞'></PricingTier>
            </li>
            </ul>

            <div className="pricing-container">
              <div className="theme-green">
              <div style={{textAlign: 'center', marginTop: '30px'}}>
                  {this.state.showingAnnual ? <p><b>{literals.annualPlanLabel}</b></p> : <p><b>{literals.monthlyPlanLabel}</b></p>}
                  <input className="pricing-switch" type="checkbox" checked={this.state.showingAnnual} onChange={this.pricingFrequencyChanged}></input>
                </div>
                <div className="row" style={{textAlign: 'center', marginTop: '20px'}}>
                  <div className="col-md-4 col-sm-12 col-md-offset-4 col-sm-offset-0">
                    <div className="form-group">
                      <label>{literals.chooseYourCurrency}:</label>
                      <select className='form-control' onChange={this.currencyChanged} value={this.state.selectedCurrency.isoCode}>
                        {currencyOptions}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <ul  className="pricing-list bounce-invert">
              <li >
                <FlatFeePricingTier appState={this.props.appState} showingAnnual={this.state.showingAnnual} planName="Business" price={GetBusinessPricing(selectedCurrency)} volumeOfSubscriptions='16-26'></FlatFeePricingTier>
              </li>
              <li >
                <FlatFeePricingTier appState={this.props.appState} showingAnnual={this.state.showingAnnual} planName="Enterprise" price={GetEnterprisePricing(selectedCurrency)} volumeOfSubscriptions='27-45'></FlatFeePricingTier>
              </li>
              <li >
                <FlatFeePricingTier appState={this.props.appState} showingAnnual={this.state.showingAnnual} planName="Ultimate" price={GetUltimatePricing(selectedCurrency)} volumeOfSubscriptions='>= 46'></FlatFeePricingTier>
              </li>

            </ul>
            <div className="pricing-container">
              <div className="theme-green">
                <p>*: {literals.contractorsLabel}</p>
                <p>{literals.checkOutOur} <a href="https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/license/" target={"_blank"}>{literals.licence}</a> {literals.andOur} <a href="https://dynamicsvalue.github.io/fake-xrm-easy-docs/licensing/faq/" target={"_blank"}>{literals.licensingFaq}</a> {literals.ifYouHaveAnyQuestions}.</p>
              </div>
            </div>
            
        </section>
      </div>
    );
  }

}
