import React, { Component, useState } from 'react';
import { EmailInput } from '../Input/EmailInput';
import { getLiteralsForCulture } from '../../literals/Literals';
import { InputState, CheckBoxInputState } from '../Input/types';
import { CheckBoxInput } from '../Input/CheckBoxInput';
import { RegisterLiterals } from '../../literals/account/RegisterLiterals';
import { PasswordInput } from '../Input/PasswordInput';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getDefaultJsonAjaxHeaders } from '../../services/AuthService';
import { GenericResponse } from '../Api/GenericResponse';
import { Redirect, useHistory } from 'react-router';
import { AppProps } from '../AppState';
import { AppModal } from '../Modal/AppModal';
import { ErrorMessage } from '../Input/ErrorMessage';
import { Help } from '../Help/Help';
import { InputLiterals } from '../../literals/input/InputLiterals';
interface SubmitModel 
{
  Email: string;
  Password: string;
  ConfirmPassword: string;
};

class RegisterState 
{
    emailState?: InputState = undefined;
    passwordState?: InputState = undefined;
    confirmPasswordState?: InputState = undefined;
    
    acceptedTermsOfService?: CheckBoxInputState = undefined;
    isSubmitting: boolean = false;
    validateForm: boolean = false;
    
    passwordsDontMatch: boolean = false;

    showSubmitError: boolean = false;
    submitError: string = "";

    redirect?: string = undefined;

    constructor()
    {

    }
};

const SignUp = ({appState} : AppProps) => {
  var initialEmailState = new InputState();
  var initialPasswordState = new InputState();
  var initialConfirmPasswordState = new InputState();

  const history = useHistory();

  const [ emailState, setEmailState ] = useState(initialEmailState);
  const [ passwordState, setPasswordState ] = useState(initialPasswordState);
  const [ confirmPasswordState, setConfirmPasswordState ] = useState(initialConfirmPasswordState);
  const [ acceptedTermsOfService, setAcceptedTermsOfService ] = useState(false);
  const [ passwordsDontMatch, setPasswordsDontMatch ] = useState(false);

  const [ isValidatingForm, setIsValidatingForm ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isSubmitErrorVisible, setIsSubmitErrorVisible ] = useState(false);
  const [ submitError, setSubmitError ] = useState("");

  const literals = getLiteralsForCulture(RegisterLiterals, appState.currentCulture) as any; 
  const inputLiterals = getLiteralsForCulture(InputLiterals, appState.currentCulture) as any; 

  const emailOnChange = (newState: InputState) => {
    setEmailState(newState);
  }

  const submit = () => {

    setIsValidatingForm(true);
    
    if(!emailState?.isValid ||
       !passwordState?.isValid ||
       !confirmPasswordState?.isValid ||
       !acceptedTermsOfService)
    {
      return;
    }

    setPasswordsDontMatch(passwordState.value !== confirmPasswordState.value);
    if(passwordsDontMatch){
      return;
    }

    setIsSubmitting(true);

    const requestOptions = {
      method: "POST",
      headers: getDefaultJsonAjaxHeaders(appState),
      body: JSON.stringify({
        Email: emailState.value,
        Password: passwordState.value,
        ConfirmPassword: confirmPasswordState.value
      })
    };

    fetch("/api/account/register", requestOptions)
      .then(res => res.json())
      .then(result => {
          var response = result as GenericResponse;
          if(response.succeeded)
          {
            history.push('/account/registered');
          }
          else {
            setSubmitError(response.errorMessage);
            setIsSubmitErrorVisible(true);
          }
          setIsSubmitting(false);
      });
  };

  return (
    <div>
       <AppModal isOpen={isSubmitErrorVisible} title={literals.registerLabel} bodyText={submitError} onClickOk={() => setIsSubmitErrorVisible(false)} />

        <section className="theme-dark-gray">
            <div className="row">
                <div className="form-group col-md-offset-4 col-md-4">
                    <h2 style={{textAlign: "center"}}>{literals.registerLabel }</h2>

                    <p>{literals.registerSubHeadingLabel}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-offset-4 col-md-4">
                      <form id="contact" autoComplete='off'>
                      <div className="row">
                          <div className="form-group col-md-12">
                              <label htmlFor="email">{inputLiterals.emailLabel}</label>
                              <EmailInput 
                                id="email"
                                name="email"
                                placeHolder={inputLiterals.emailLabel}
                                displayValidation={isValidatingForm}
                                usesValidation={true}
                                isMandatory={true}
                                mandatoryErrorMessage={inputLiterals.emailMandatoryErrorLabel}
                                validationErrorMessage={inputLiterals.emailNotValidErrorLabel}
                                onChange={emailOnChange}
                                value={emailState.value}>
                              </EmailInput>
                          </div>
                      </div>

                      <div className="row">
                          <div className="form-group col-md-12">
                              <label htmlFor="password">{inputLiterals.passwordLabel }</label>
                              <PasswordInput
                                id="password"
                                name="password"
                                placeHolder={inputLiterals.passwordLabel}
                                displayValidation={isValidatingForm}
                                usesValidation={true}
                                maxLength={100}
                                isMandatory={true}
                                mandatoryErrorMessage={inputLiterals.passwordMandatoryErrorLabel}
                                validationErrorMessage={inputLiterals.passwordNotValidErrorLabel}
                                onChange={(newState) => setPasswordState(newState)}
                                value={passwordState.value}>
                              </PasswordInput>
                          </div>
                      </div>
      
                      <div className="row">
                          <div className="form-group col-md-12">
                              <label htmlFor="confirmPassword">{inputLiterals.confirmPasswordLabel }</label>
                              <PasswordInput
                                id="confirmPassword"
                                name="confirmPassword"
                                placeHolder={inputLiterals.confirmPasswordLabel}
                                displayValidation={isValidatingForm}
                                usesValidation={true}
                                maxLength={100}
                                isMandatory={true}
                                mandatoryErrorMessage={inputLiterals.confirmPasswordMandatoryErrorLabel}
                                validationErrorMessage={inputLiterals.passwordNotValidErrorLabel}
                                onChange={(newState) => setConfirmPasswordState(newState)}
                                value={confirmPasswordState.value}>
                              </PasswordInput>
                          </div>
                      </div>

                      <div className="row">
                          <div className="form-group col-md-12">
                              <label>
                                  <CheckBoxInput 
                                    id="acceptTermsOfServiceConfirmation" 
                                    name="acceptTermsOfServiceConfirmation"
                                    isMandatory={true}
                                    displayValidation={isValidatingForm}
                                    mandatoryErrorMessage={literals.mustAcceptedTosLabel}
                                    onChange={(newState) => setAcceptedTermsOfService(newState.checked)}
                                    checked={acceptedTermsOfService}>
                                      {literals.iAgreeToTheLabel}
                                    <a href="/terms-of-service">{literals.legalNoticeLabel}</a>
                                    {literals.andTheLabel}<a href="/privacy-policy">{literals.privacyPolicyLabel}</a>
                                  </CheckBoxInput>
                              </label>
                          </div>
                      </div>
                  </form>
                  {passwordsDontMatch && 
                    <div className="row">
                      <div className="col-md-12">
                        <ErrorMessage message={literals.passwordsDontMatch} />
                      </div>
                    </div>}
                  <div className="row">
                      <div className="col-md-12">
                          {isSubmitting && <button className="btn btn-primary" disabled={true} style={{width: "100%"}}>{literals.submittingLabel}</button>}
                          {!isSubmitting && <button className="btn btn-primary" onClick={submit} style={{width: "100%"}}>{literals.submitLabel}</button>}
                      </div>
                  </div>
                </div>
             </div>
             <div className="row" style={{textAlign: "center"}}>
                  <div className="col-md-offset-4 col-md-4">
                  <Help appState={appState} />
                </div>
              </div>
        </section>
    </div>
  );

};

export { SignUp };
