const SignInLiterals = {

    signInLabel: {
        "en-GB": "Sign In",
        "es-ES": "Iniciar Sesión",
        "ca-ES": "Inicia Sessió"
    },
    emailLabel: {
        "en-GB": "Email",
        "es-ES": "Email",
        "ca-ES": "Email"
    },
    passwordLabel: {
        "en-GB": "Password",
        "es-ES": "Contraseña",
        "ca-ES": "Contrassenya"
    },
    passwordNotValid: {
        "en-GB": "Password has an invalid format",
        "es-ES": "Contraseña no tiene un formato correcto",
        "ca-ES": "Contrassenya no té un format correcte"
    },
    rememberMeLabel: {
        "en-GB": "Remember Me",
        "es-ES": "Recordarme",
        "ca-ES": "Recorda'm"
    },
    submitLabel: {
        "en-GB": "Sign In",
        "es-ES": "Entrar",
        "ca-ES": "Entra"
    },
    submittingLabel: {
        "en-GB": "Signing in...",
        "es-ES": "Entrando...",
        "ca-ES": "Entrant..."
    },
    forgotPassword: {
        "en-GB": "Forgot password?",
        "es-ES": "¿Contraseña olvidada?",
        "ca-ES": "Contrassenya oblidada?"
    }
};

export { SignInLiterals };