var TrainingLiterals = {

    trainingLabel: {
        "en-GB": "Training",
        "es-ES": "Formación",
        "ca-ES": "Formació"
    },

    trainingDesc: 
    {
        "en-GB": "With the release of the brand new versions of FakeXrmEasy versions 2 or later, which includes commercial support, there is a brand new amazing and up-to-date documentation that covers most topics and so the MasterClass offering has been discontinued. If you still want to discuss tailor made training options, please do so via our Contact page.",
        "es-ES": "Con el nuevo lanzamiento de FakeXrmEasy versión 2 o posterior, que incluye, entre otros, soporte comercial y un nuevo site de documentación que se mantiene completamente al día, la oferta de MasterClass que ofrecíamos se ha discontinuado. Si, a pesar de ello, necesita formación a medida, por favor hágalo a través de nuestra página de Contacto.",
        "ca-ES": "Amb el nou llançament de FakeXrmEasy versió 2 o posterior, que inclou, entre d'altres, suport comercial i una nova documentació continuament actualitzada amb cada versió, la oferta que teníem de MasterClass s'ha discontinuat. Si, malgrat això, continua necessitant formació a mida, si us plau, posi's en contacte a través de la secció de Contacte de la nostra web."
    }
}

export { TrainingLiterals };