import { AppSettings } from "../AppSettings";
import { PostJsonAsync } from "../services/FetchService";
import { GenericResponse } from "./Api/GenericResponse";

interface GetLoggedInResponse extends GenericResponse {
    model: string;
}

export interface AppProps 
{
    appState: AppState;
}

export class AppState 
{
    currentCulture: string = 'en-GB';
    isLoggedIn: boolean = false;
    changeCultureFn?: ((newCulture: string) => void);
    setAuthenticatedFn?: ((authenticated: boolean) => void); 
    handleApiErrorFn? : ((response: Response) => void);
    redirect?: string = undefined;
    settings: AppSettings = new AppSettings();

    //Service Worker related
    checkingNewVersionFn?: (isCheckingNewVersion: boolean) => void;
    newVersionAvailableFn?: (newVersionAvailable: boolean) => void;
    checkingNewVersion: boolean = false;
    newVersionAvailable: boolean = false;
    
    appError: string = "";

    isAuthenticated = async () : Promise<GenericResponse> => {
        const result = await PostJsonAsync("/api/account/logged-in", {}, this);
        if(this.setAuthenticatedFn)
        {
            this.setAuthenticatedFn(result.succeeded);
        }

        if(result.succeeded) {
            const loggedInResponse = result as GetLoggedInResponse;
            if(loggedInResponse.model !== "")
            {
                const culture = loggedInResponse.model;
                if(this.changeCultureFn)
                {
                this.changeCultureFn(culture);
                }
            }
        }
        return result;
    }

    constructor()
    {
    }
};