import React, { useEffect, useState } from 'react';
import { CountryListResponse, CountryModel } from '../../../api/Countries';
import { BillingAddressLiterals, IBillingAddressLiterals } from '../../../literals/account/billing/BillingAddressLiterals';
import { getLiteralsForCulture } from '../../../literals/Literals';
import { GetJsonAsync, PostJsonAsync } from '../../../services/FetchService';
import { formatCif, isCifValid } from '../../../utils/billing/cifValidator';
import { AppProps } from '../../AppState';
import { Card } from '../../Cards/Card';
import { DropDownInputField } from '../../Input/Fields/DropDownInputField';
import { TextInputField } from '../../Input/Fields/TextInputField';
import { DropDownOption, InputState, ValidationResult } from '../../Input/types';
import { Loading } from '../../Loading';
import { AppModal } from '../../Modal/AppModal';



const BillingAddress = ({appState} : AppProps) => {
  
    let initialCountries: CountryModel[] = [];
    let initialSelectedCountry = new InputState();
    let initialCompanyName = new InputState();
    let initialCity = new InputState();
    let initialCifValue = new InputState();
    let addressLine1InitialValue = new InputState();
    let addressLine2InitialValue = new InputState();
    let postCodeInitialValue = new InputState();

    const [ countries, setCountries ] = useState(initialCountries);
    const [ selectedCountry, setSelectedCountry ] = useState(initialSelectedCountry);
    const [ companyName, setCompanyName ] = useState(initialCompanyName);
    const [ city, setCity ] = useState(initialCity);
    const [ cif, setCif ] = useState(initialCifValue);
    const [ addessLine1, setAddressLine1 ] = useState(addressLine1InitialValue);
    const [ addessLine2, setAddressLine2 ] = useState(addressLine2InitialValue);
    const [ postCode, setPostCode ] = useState(postCodeInitialValue);

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isValidatingForm, setIsValidatingForm ] = useState(false);
    const [ isSubmitErrorVisible , setIsSubmitErrorVisible ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ submitError, setSubmitError ] = useState("");

    const literals = getLiteralsForCulture(BillingAddressLiterals, appState.currentCulture) as IBillingAddressLiterals; 
    
    const isCifVisible = selectedCountry.value === "ESP";

    const CifValidationFunction = (cif: string): ValidationResult => {
      const result = isCifValid(cif);
      
      return {
        isValid: result.isValid,
        errorMessage: literals.cifNotValidMessage
      };
    }

    useEffect(() => {
      const getAllCountries = async() => {
        const result = await GetJsonAsync("/api/countries/all", appState);
        if(result.succeeded)
        {
          const countryList = (result as CountryListResponse).list;
          setCountries(countryList);
        }
        setIsLoading(false);
      };
      getAllCountries();
    }, []);
  
    

    const submit = () => {
      setIsValidatingForm(true);

        if(!selectedCountry.isValid || 
          isCifVisible && !cif.isValid ||
          !addessLine1.isValid ||
          !city.isValid || 
          !postCode.isValid) 
        {
            return;
        }

        setIsSubmitting(true);

        const SubmitAsync = async () => {
          const result = await PostJsonAsync("/api/billing/address/save", 
            {
                country: selectedCountry.value,
            }, 
            appState);
          if(result.succeeded) {
            setIsSubmitErrorVisible(false);
            
          }
          else {
            setSubmitError(result.errorMessage);
            setIsSubmitErrorVisible(true);
          }

          setIsSubmitting(false);
        };

        SubmitAsync();
    };

    if(isLoading) {
      return <Loading />;
    }

    const countryOptions: DropDownOption[] = countries.map(country => {
        return {
          value: country.alpha3Code,
          label: country.name
        };
    });
    
    const cifField = (
      <div className="row">
            <div className="form-group col-md-12">
                <TextInputField 
                    label={literals.cif}
                    currentCulture={appState.currentCulture}
                    id="cif"
                    name="cif"
                    placeHolder={literals.cif}
                    displayValidation={isValidatingForm}
                    usesValidation={true}
                    validationFn={CifValidationFunction}
                    maxLength={200}
                    isMandatory={true}
                    onChange={(newState) => setCif(newState)}
                    value={formatCif(cif.value)}
                />
            </div>
        </div>
    );

    const cityField = (
      <div className="row">
            <div className="form-group col-md-12">
                <TextInputField 
                    label={literals.city}
                    currentCulture={appState.currentCulture}
                    id="city"
                    name="city"
                    placeHolder={literals.city}
                    displayValidation={isValidatingForm}
                    usesValidation={true}
                    maxLength={200}
                    isMandatory={true}
                    onChange={(newState) => setCity(newState)}
                    value={city.value}
                />
            </div>
        </div>
    );

    const postCodeField = (
      <div className="row">
            <div className="form-group col-md-12">
                <TextInputField 
                    label={literals.postCode}
                    currentCulture={appState.currentCulture}
                    id="postCode"
                    name="postCode"
                    placeHolder={literals.postCode}
                    displayValidation={isValidatingForm}
                    usesValidation={true}
                    maxLength={200}
                    isMandatory={true}
                    onChange={(newState) => setPostCode(newState)}
                    value={postCode.value}
                />
            </div>
        </div>
    );

    const line1Field = (
      <div className="row">
            <div className="form-group col-md-12">
                <TextInputField 
                    label={literals.addressLine1}
                    currentCulture={appState.currentCulture}
                    id="addressLine1"
                    name="addressLine1"
                    placeHolder={literals.addressLine1}
                    displayValidation={isValidatingForm}
                    usesValidation={true}
                    maxLength={200}
                    isMandatory={true}
                    onChange={(newState) => setAddressLine1(newState)}
                    value={addessLine1.value}
                />
            </div>
        </div>
    );

    const line2Field = (
      <div className="row">
            <div className="form-group col-md-12">
                <TextInputField 
                    label={literals.addressLine2}
                    currentCulture={appState.currentCulture}
                    id="addressLine2"
                    name="addressLine2"
                    placeHolder={literals.addressLine2}
                    displayValidation={isValidatingForm}
                    usesValidation={true}
                    maxLength={200}
                    isMandatory={false}
                    onChange={(newState) => setAddressLine2(newState)}
                    value={addessLine2.value}
                />
            </div>
        </div>
    );

    const companyNameField = (
      <div className="row">
            <div className="form-group col-md-12">
                <TextInputField 
                    label={literals.companyName}
                    currentCulture={appState.currentCulture}
                    id="companyName"
                    name="companyName"
                    placeHolder={literals.companyName}
                    displayValidation={isValidatingForm}
                    usesValidation={true}
                    maxLength={200}
                    isMandatory={true}
                    onChange={(newState) => setCompanyName(newState)}
                    value={companyName.value} 
                />
            </div>
        </div>
    );

    const cardBody = (
      <div>
        {companyNameField}

        <div className="row">
            <div className="form-group col-md-12">
                <DropDownInputField
                    label={literals.country}
                    currentCulture={appState.currentCulture}
                    id="country"
                    name="country"
                    placeHolder={literals.country}
                    options={countryOptions}
                    displayValidation={isValidatingForm} 
                    usesValidation={true}
                    isMandatory={true}
                    onChange={(newState) => setSelectedCountry(newState)}
                    value={selectedCountry.value}> 
                </DropDownInputField>
            </div>
        </div>

        {isCifVisible && cifField}

        {line1Field}
        {line2Field}
        {cityField}
        {postCodeField}

        <div className="row">
            <div className="col-md-6">
                {isSubmitting && <button className="btn btn-primary" disabled={true}>{literals.saving}</button>}
                {!isSubmitting && <button className="btn btn-primary" onClick={submit} >{literals.save}</button>}
            </div>
        </div>
      </div>
    );

    return (
      <div className="col-md-12">
          <AppModal isOpen={isSubmitErrorVisible} title={literals.title} bodyText={submitError} onClickOk={() => setIsSubmitErrorVisible(false)} />
          <Card title={literals.billingAddress} body={cardBody} />
      </div>
    );
}

export { BillingAddress };