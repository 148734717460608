import React from 'react';
import { AppProps } from '../../AppState';
import { BasicDetails } from './BasicDetails';
import { Email } from './Email';

const UserProfile = ({appState} : AppProps) => {
  
  return (
    <div>
      <div className="row">
        <Email appState={appState} />
      </div>
      <div className="row">
        <BasicDetails appState={appState} />
      </div>
      
    </div>
  );
}

export { UserProfile };