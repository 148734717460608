const ConfirmEmailLiterals = {

    confirmEmail: {
        "en-GB": "Confirm Email",
        "es-ES": "Confirmar email",
        "ca-ES": "Confirmar email"
    },
    confirmingLabel: {
        "en-GB": "We're confirming your account, please wait...",
        "es-ES": "Estamos confirmando tu cuenta, por favor espera...",
        "ca-ES": "Estem confrimant el teu compte, si us plau, espera..."
    },
    confirmed: {
        "en-GB": "Your account has been confirmed successfully. Please, proceed to sign in.",
        "es-ES": "Tu cuenta se confirmó correctamente. Por favor, continúa iniciando sesión.",
        "ca-ES": "El teu compte s'ha confirmat correctament, si us plau, continúa iniciant sessió."
    },
    notConfirmed: {
        "en-GB": "Your account could not be confirmed. ",
        "es-ES": "Tu cuenta no se pudo confirmar.",
        "ca-ES": "El teum compte no s'ha pogut confirmar."
    },
    signIn: {
        "en-GB": "Sign In",
        "es-ES": "Iniciar Sesión",
        "ca-ES": "Inicia Sessió"
    }
};

export { ConfirmEmailLiterals };