import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ResetPasswordLiterals } from '../../literals/account/ResetPasswordLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { getDefaultJsonAjaxHeaders } from '../../services/AuthService';
import { GenericResponse } from '../Api/GenericResponse';
import { AppProps, AppState } from '../AppState';
import * as qs from 'query-string';
import { Button } from 'reactstrap';
import { AppModal } from '../Modal/AppModal';
import { InputLiterals } from '../../literals/input/InputLiterals';
import { EmailInput } from '../Input/EmailInput';
import { InputState } from '../Input/types';
import { PasswordInput } from '../Input/PasswordInput';
import { ErrorMessage } from '../Input/ErrorMessage';

const ResetPasswordAsync = async (appState: AppState, email: string, password: string, token: string) => {
  const requestOptions = {
    method: "POST",
    headers: getDefaultJsonAjaxHeaders(appState),
    body: JSON.stringify({Email: email, Token: token, Password: password})
  };

  var response = await fetch("/api/account/reset-password", requestOptions);
  var result = await response.json() as GenericResponse;
  return result;
}

const ResetPassword = ({appState}: AppProps) => {
  const history = useHistory();
  const location = useLocation();
  const initialConfirmResult: GenericResponse = {
    succeeded: false,
    errorMessage: ""
  };

  const initialEmailInputState = new InputState();
  const initialPasswordInputState = new InputState();
  const initialConfirmPasswordInputState = new InputState();
  
  const [ confirmResult, setConfirmResult ] = useState(initialConfirmResult);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isSubmitErrorVisible, setSubmitErrorVisible ] = useState(false);
  const [ submitError, setSubmitError ] = useState("");
  const [ isValidatingForm, setIsValidatingForm ] = useState(false);
  const [ emailState, setEmailState ] = useState(initialEmailInputState);
  const [ passwordState, setPasswordState ] = useState(initialPasswordInputState);
  const [ confirmPasswordState, setConfirmPasswordState ] = useState(initialConfirmPasswordInputState);
  const [ passwordsDontMatch, setPasswordsDontMatch ] = useState(false);
  
  const query = qs.parse(location.search);

  const literals = getLiteralsForCulture(ResetPasswordLiterals, appState.currentCulture) as any;
  const inputLiterals = getLiteralsForCulture(InputLiterals, appState.currentCulture) as any;

  const token = query.token ? query.token as string : "";

  const emailOnChange = (newState: InputState) => {
    setEmailState(newState);
  }

  const navigateToResetPasswordSent = () => {
    history.push("/account/reset-password/sent");
  };

  const submit = () => {
    setIsValidatingForm(true);

    if(!emailState.isValid || !passwordState.isValid || !confirmPasswordState.isValid)
    {
      return;
    }

    setPasswordsDontMatch(passwordState.value !== confirmPasswordState.value);
    if(passwordsDontMatch)
    {
      return;
    }

    const resetPassword = async () => {
      const response = await ResetPasswordAsync(appState, emailState.value, passwordState.value, token);
      if(!response.succeeded)
      {
        setSubmitError(response.errorMessage);
        setSubmitErrorVisible(true);
      }
      else {
        navigateToResetPasswordSent();
      }
      setIsSubmitting(false);
    }

    setIsSubmitting(true);
    resetPassword();
  };

  return (
    <div>
        <AppModal isOpen={isSubmitErrorVisible} title={literals.resetPasswordTitle} bodyText={submitError} onClickOk={() => setSubmitErrorVisible(false)} />
        
        <section className="fit theme-dark-gray">
            <div className="row">
                <div className="form-group col-md-offset-4 col-md-4">
                    <h2 style={{textAlign: "center"}}>{literals.resetPasswordTitle}</h2>

                    <p>{literals.resetPasswordSubHeading}</p>
                </div>
            </div>

            <div className="row">
                  <div className="col-md-offset-4 col-md-4">
                      <div className="row">
                          <div className="form-group col-md-12">
                              <label htmlFor="email">{inputLiterals.emailLabel}</label>
                              <EmailInput 
                                id="email"
                                name="email"
                                placeHolder={inputLiterals.emailLabel}
                                displayValidation={isValidatingForm}
                                usesValidation={true}
                                isMandatory={true}
                                mandatoryErrorMessage={inputLiterals.emailMandatoryErrorLabel}
                                validationErrorMessage={inputLiterals.emailNotValidErrorLabel}
                                onChange={emailOnChange}
                                value={emailState?.value}>
                              </EmailInput>
                          </div>
                      </div>

                      <div className="row">
                          <div className="form-group col-md-12">
                              <label htmlFor="password">{inputLiterals.passwordLabel }</label>
                              <PasswordInput
                                id="password"
                                name="password"
                                placeHolder={inputLiterals.passwordLabel}
                                displayValidation={isValidatingForm}
                                usesValidation={true}
                                maxLength={100}
                                isMandatory={true}
                                mandatoryErrorMessage={inputLiterals.passwordMandatoryErrorLabel}
                                validationErrorMessage={inputLiterals.passwordNotValidErrorLabel}
                                onChange={(newState) => setPasswordState(newState)}
                                value={passwordState?.value}>
                              </PasswordInput>
                          </div>
                      </div>
      
                      <div className="row">
                          <div className="form-group col-md-12">
                              <label htmlFor="confirmPassword">{inputLiterals.confirmPasswordLabel }</label>
                              <PasswordInput
                                id="confirmPassword"
                                name="confirmPassword"
                                placeHolder={inputLiterals.confirmPasswordLabel}
                                displayValidation={isValidatingForm}
                                usesValidation={true}
                                maxLength={100}
                                isMandatory={true}
                                mandatoryErrorMessage={inputLiterals.confirmPasswordMandatoryErrorLabel}
                                validationErrorMessage={inputLiterals.passwordNotValidErrorLabel}
                                onChange={(newState) => setConfirmPasswordState(newState)}
                                value={confirmPasswordState?.value}>
                              </PasswordInput>
                          </div>
                      </div>

                      {passwordsDontMatch && 
                        <div className="row">
                          <div className="col-md-12">
                            <ErrorMessage message={inputLiterals.passwordsDontMatch} />
                          </div>
                        </div>}
                      <div className="row">
                          <div className="col-md-12">
                              {isSubmitting && <button className="btn btn-primary" disabled={true}>{inputLiterals.submitting}</button>}
                              {!isSubmitting && <button className="btn btn-primary" onClick={submit} >{inputLiterals.submit}</button>}
                          </div>
                      </div>
                  </div>
              </div>
        </section>
    </div>
  );
}

export { ResetPassword }

