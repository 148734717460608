import React, { Component } from 'react';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";

export class Overview extends Component {

  render () {
    return (
      <GetStarted isOverview={true}>
        <div className="row">
            <h1>Getting Started with Fake Xrm Easy</h1>
        </div>

        <div className="row">
            <section className="theme-white">
                <div className="row">
                    <p><b>FakeXrmEasy</b> is an open source automated testing framework specifically designed for <b>Dynamics CRM</b> versions 2011, 2013, 2015 and 2016.</p>

                    <p>
                        FakeXrmEasy enhances your organisation to be more productive because it uses an In Memory organization database, where you can run hundreds of automated tests in a matter of seconds. Seriously.
                    </p>
                    <p> It also helps by giving you already mocked objects, like an IOrganizationService, or a plugin context, or a code activity context, which use the In Memory context above. It is, more precisely, a mocking library for Dynamics CRM you can use with your test framework of choice (XUnit, NUnit, etc...).
                    </p>
                    <p>In a nutshell, it is like having an Organization Database, but much more <b>lightweight</b>, and allowing you to develop &amp; run your tests straight from within Visual Studio or MSBuild, without even opening thousands of CRM windows and forms, therefore, making your development lifecycle much more efficient. </p>

                    <p>FakeXrmEasy was <b>born due to the lack of mocking libraries</b> for Dynamics CRM. There are other automated testing frameworks for Dynamics CRM projects out there but they rely on mocking libraries like Moq or Microsoft Fakes, which means, you have to code your own mocks from scratch, and doing that, we can tell you, is really time consuming.</p>
                    
                    <p>So, FakeXrmEasy goes further than that and it provides you with an already mocked OrganizationService which intercepts your plugins, codeactivities, ... and so on.</p>
                    
                    <p>Because of that, you could adopt TDD strategies more easily, or use wherver testing paradigms best suits you, the point is: You don't have to worry about mocking anything, it's arlready done for you!</p>

                    <p>This section will describe in more detail how to install FakeXrmEasy and get started on writing your first tests. Please, follow the links on the page left hand side.</p>

                    <p>We are constantly evolving and improving the project, so please feel free to get in touch about suggestions and, of course, everyone is welcome to <a href="https://github.com/jordimontana82/fake-xrm-easy" target="_blank"><b>contribute</b></a> to the cause.</p>

                    <p>We want this framework to be literally the Top #1 Dynamics CRM's Automated Testing Framework in the World!</p>

                    <p style={{marginTop: "20px", fontSize: "12px"}}>Note: This section is only available in English. If you would like this section translated to other languages, please, <a href="/contact">get in touch</a> ant let us know. </p>
                    <p style={{fontSize: "12px"}}>Nota: Esta sección sólo está disponible en inglés. Si desea esta sección traducida a otros idiomas, por favor, <a href="/contact">contacte con nosotros</a> para hacérnoslo saber. </p>
                    <p style={{fontSize: "12px"}}>Nota: Aquesta secció només està disponible an anglès. Si desitja aquesta secció traduïda a altres idiomes, si us plau, <a href="/contact">contacti amb nosaltres</a> per fer-nos-ho saber. </p>
                </div>
            </section>
        </div>
        
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
