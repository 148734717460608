import React, { Component } from 'react';
import { AppProps } from './AppState';

export const AppError = (appProps: AppProps) => 
 
    (<div>
        <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                      <h2>Ooops!</h2>
                      <p>{appProps.appState.appError}</p>
                  </div>
              </div>
        </section>
    </div>);

