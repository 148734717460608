
var getLiteralsForCulture = function(literals, culture)
{
    var selectedLiterals = {};

    for(var literal in literals)
    {
        for(var literalCulture in literals[literal])
        {
            if(literalCulture === culture)
            {
                selectedLiterals[literal] = literals[literal][literalCulture];
            }
        }
    }

    return selectedLiterals;
};

export {getLiteralsForCulture};