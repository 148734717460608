import React from "react";
import { Component, ReactNode } from "react";
import { IInputLiterals, InputLiterals } from "../../../literals/input/InputLiterals";
import { getLiteralsForCulture } from "../../../literals/Literals";
import { TextInput } from "../TextInput";
import { TextInputProps } from "../types";

interface TextInputFieldProps extends TextInputProps {
    label: string;
    currentCulture: string;
}

export class TextInputField extends Component<TextInputFieldProps, {}> {

    constructor(props: TextInputFieldProps) {
        super(props);
    }

    render(): ReactNode {

        const inputLiterals = getLiteralsForCulture(InputLiterals, this.props.currentCulture) as IInputLiterals; 
        
        return (
        <div>
            <label>
                <label htmlFor={this.props.name}>{this.props.label}</label>
            </label>
            <TextInput
                id={this.props.id}
                name={this.props.name}
                placeHolder={this.props.placeHolder} 
                
                maxLength={this.props.maxLength}

                usesValidation={this.props.usesValidation}
                displayValidation={this.props.displayValidation}
                validationRegex={this.props.validationRegex}
                validationErrorMessage={this.props.validationErrorMessage ? this.props.validationErrorMessage : `${this.props.label} ${inputLiterals.isNotValid}`}
                validationFn={this.props.validationFn}
                
                isMandatory={this.props.isMandatory}
                mandatoryErrorMessage={this.props.mandatoryErrorMessage ? this.props.mandatoryErrorMessage : `${this.props.label} ${inputLiterals.isMandatory}`}
                
                onChange={this.props.onChange}
                value={this.props.value} />
        </div>);
        
    }
}