import React, { Component } from 'react';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { CodeComponent } from './CodeComponent';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";

export class Installation extends Component {

  render () {
    var literals = getLiteralsForCulture(ContactLiterals, 'en-GB') as any;
    const textContent = `
    //  Right...
    //
    //
    //  Let's get started. 
    //
    //  
    //  The first thing to do would be to create a new Unit Test Project in Visual Studio
    //
    //  Once you have that created, we need install 3 packages:
    //
    //  -  FakeXrmEasy: our automocking library
    //
    //  -  XUnit: to declare our tests (or any other test framework you want, like NUnit, etc)
    //
    //  -  And the XUnit test runner, if you wish to run them from the Test Explorer.
    //
    //
    //
    //  You could install the NuGet packages via right click on your project -> Manage NuGet Packages or...
    //    
    //  Use the Package Manager Console... like this..
    //
    //  Install-Package FakeXrmEasy         //For Dynamics CRM 2011
    //  Install-Package FakeXrmEasy.2013    //For Dynamics CRM 2013
    //  Install-Package FakeXrmEasy.2015    //For Dynamics CRM 2015
    //  Install-Package FakeXrmEasy.2016    //For Dynamics CRM 2016
    //
    //  and this...
    //  
    //  Install-Package xunit -Version 1.9.2
    //  Install-Package xunit.runner.visualstudio
    //
    //  It's pretty simple.
    //
    //  Now...
    //
    //  Once you have those packages installed in your project, 
    //  You are ready to add your tests.
    //
    //  Please click on the next section 'Your First Test' to continue.
    //     
    `;

    return (
      <GetStarted isInstallation={true}>
        <div>
        <div className="row">
            <h1>Installation</h1>
        </div>
        <div className="row">
            <CodeComponent code={textContent}></CodeComponent>
        </div>
      </div>
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
