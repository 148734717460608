var HelpLiterals = {

    helpLabel: {
        "en-GB": "Help?",
        "es-ES": "¿Ayuda?",
        "ca-ES": "Ajuda?"
    },
    businessHoursLabel: 
    {
        "en-GB": "Business Hours",
        "es-ES": "Horario",
        "ca-ES": "Horari"
    },
    mondayFridayLabel:
    {
        "en-GB": "Mon-Fri",
        "es-ES": "Lunes a Viernes",
        "ca-ES": "Dilluns a Divendres"
    }
}

export { HelpLiterals };