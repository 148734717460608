import React, { useState } from 'react';
import { EmailInput } from '../Input/EmailInput';
import { getLiteralsForCulture } from '../../literals/Literals';
import { InputState } from '../Input/types';
import { PasswordInput } from '../Input/PasswordInput';
import { SignInLiterals } from '../../literals/account/SignInLiterals';
import { getDefaultJsonAjaxHeaders } from '../../services/AuthService';
import { GenericResponse } from '../Api/GenericResponse';
import { useHistory } from 'react-router';
import { AppProps } from '../AppState';
import { AppModal } from '../Modal/AppModal';
import { Help } from '../Help/Help';
import { InputLiterals } from '../../literals/input/InputLiterals';
import { Button } from 'reactstrap';

const SignIn = ({appState}: AppProps) => {

  const history = useHistory();

  const initialEmailState = new InputState();
  const initialPasswordState = new InputState();
  const [ emailState, setEmailState ] = useState(initialEmailState);
  const [ passwordState, setPasswordState ] = useState(initialPasswordState);
  const [ isValidatingForm, setIsValidatingForm ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ isSubmitErrorVisible, setIsSubmitErrorVisible ] = useState(false);
  const [ submitError, setSubmitError ] = useState("");

  const emailOnChange = (newState: InputState) => {
    setEmailState(newState);
  }

  const forgotPasswordClick = () => {
    history.push('/account/forgot-password');
  }
  const submit = () => {

    setIsValidatingForm(true);

    if(!emailState.isValid || !passwordState.isValid)
    {
      return;
    }

    setIsSubmitting(true);

    const requestOptions = {
      method: "POST",
      headers: getDefaultJsonAjaxHeaders(appState),
      body: JSON.stringify({Email: emailState.value, Password: passwordState.value})
    };

    fetch("/api/account/signin", requestOptions)
      .then(res => res.json())
      .then(result => {
          var response = result as GenericResponse;
          if(response.succeeded)
          {
            if(appState.setAuthenticatedFn)
            {
              appState.setAuthenticatedFn(true);
            }

            document.location.href = '/account/dashboard';
          }
          else {
            setSubmitError(response.errorMessage);
            setIsSubmitErrorVisible(true);
          }

          setIsSubmitting(false);
      });
  };

    const literals = getLiteralsForCulture(SignInLiterals, appState.currentCulture) as any;
    const inputLiterals = getLiteralsForCulture(InputLiterals, appState.currentCulture) as any;

    return (
      <div>
        <AppModal title={literals.signInLabel } isOpen={isSubmitErrorVisible} bodyText={submitError} onClickOk={() => setIsSubmitErrorVisible(false)} />

          <section className="theme-dark-gray">

              <div className="row">
                  <div className="form-group col-md-offset-4 col-md-4">
                      <h2 style={{textAlign: "center"}}>{literals.signInLabel }</h2>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-offset-4 col-md-4">
                        <form id="contact" autoComplete='off'>
                        <input type="hidden" value="something" />
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="email">{inputLiterals.emailLabel }</label>
                                <EmailInput 
                                  id="email" 
                                  name="email" 
                                  placeHolder={inputLiterals.emailLabel} 
                                  inputType="text" 
                                  displayValidation={isValidatingForm} 
                                  usesValidation={true}
                                  isMandatory={true} 
                                  mandatoryErrorMessage={inputLiterals.emailMandatoryErrorLabel} 
                                  validationErrorMessage={inputLiterals.emailNotValidErrorLabel} 
                                  onChange={emailOnChange}
                                  value={emailState.value}>
                                  </EmailInput>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="password">{inputLiterals.passwordLabel }</label>
                                <PasswordInput
                                  id="password"
                                  name="password"
                                  placeHolder={inputLiterals.passwordLabel}
                                  inputType="password"
                                  displayValidation={isValidatingForm}
                                  usesValidation={true}
                                  validationRegex=""
                                  maxLength={100}
                                  isMandatory={true}
                                  mandatoryErrorMessage={inputLiterals.passwordMandatoryErrorLabel}
                                  validationErrorMessage={literals.passwordNotValid}
                                  onChange={(newState) => setPasswordState(newState)}
                                  value={passwordState.value}>
                                </PasswordInput>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-md-6">
                            {isSubmitting && <button style={{width: "100%"}} className="btn btn-primary" disabled={true}>{literals.submittingLabel}</button>}
                            {!isSubmitting && <button style={{width: "100%"}} className="btn btn-primary" onClick={submit} >{literals.submitLabel}</button>}
                            
                        </div>
                        <div className="col-md-6">
                          <Button className='btn btn-secondary' style={{width: "100%"}} onClick={forgotPasswordClick}>{literals.forgotPassword}</Button>
                        </div>
                    </div>
                  </div>
               </div>
          </section>
      </div>
    );

};

export { SignIn };
