import React from 'react';
import { AppProps } from './AppState';


export const Forbidden = (appProps: AppProps) => 
 
    (<div>
        <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-4 col-md-14">
                      <h2>Access Denied</h2>
                      <p>Sorry, you don't have access to this resource.</p>
                  </div>
              </div>
        </section>
    </div>);

