import React, { Component, FocusEvent } from 'react';

import { ValidationResult, TextAreaInputProps, InputState} from './types';

export class TextAreaInput extends Component<TextAreaInputProps, {}> {

    constructor(props: TextAreaInputProps) {
        super(props);

        this.onChangeTextArea = this.onChangeTextArea.bind(this);
    }

    onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        var newValue = e.target.value;
        var inputState = new InputState();
        inputState.value = newValue;

        var validationResult = this.validate(newValue);
        inputState.isValid = validationResult.isValid;
        inputState.validationErrorMessage = validationResult.errorMessage;


        this.props.onChange(inputState);
    }

    validate = (value: string) : ValidationResult => 
    {
        if(this.props.isMandatory === true && value.trim() === '') 
        {
            return {
                isValid: false,
                errorMessage: this.props.mandatoryErrorMessage
            };  
        }

        if(this.props.maxLength && value.trim().length > this.props.maxLength) 
        {
            return {
                isValid: false,
                errorMessage: this.props.validationErrorMessage
            };  
        }

        if(this.props.validationRegex && this.props.validationRegex !== '')
        {
            var regExp = new RegExp(this.props.validationRegex);
            if(!regExp.test(value)) {
                return {
                isValid: false,
                errorMessage: this.props.validationErrorMessage
                }; 
            }
            
        }

        return {isValid: true};
    }

    render () {
        var validationResult = this.validate(this.props.value ? this.props.value : "");

        if(validationResult.isValid || !this.props.displayValidation)
        {
            return (
                <div>
                    <textarea className="form-control" rows={this.props.rows} id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChangeTextArea} onBlur={this.onChangeTextArea} autoComplete="off" />
                </div>
            );
        }
        else 
        {
            return (
                <div className="has-error">
                    <textarea className="form-control" rows={this.props.rows} id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChangeTextArea} onBlur={this.onChangeTextArea} autoComplete="off" />
                    <label data-formvalidator-renderedmessage>{validationResult.errorMessage}</label>
                </div>
            );
        }
    }
}
