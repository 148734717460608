var NewsLetterLiterals = {

    title: {
        "en-GB": "Newsletter",
        "es-ES": "Newsletter",
        "ca-ES": "Newsletter"
    },
    description: 
    {
        "en-GB": "Don't miss any updates about the latest news and features of FakeXrmEasy, events and so on, by subscribing to our newsletter. Unsubscribe any time.",
        "es-ES": "No te pierdas ninguna noticia sobre las últimas funcionalidades y características de FakeXrmEasy suscribiéndote a nuestra newsletter (sólo en inglés). Puedes darte de baja en cualquier momento.",
        "ca-ES": "No et perdis cap notícia de les últimes novetats de FakeXrmEasy, així como participacions a events, etc. suscrivint-te a la notra newsletter (només en anglès). Pots donar-te de baixa en qualsevol moment."
    },
    emailAddressLabel: 
    {
        "en-GB": "Email Address",
        "es-ES": "Correo electrónico",
        "ca-ES": "Correu electrònic"
    },
    emailNotValidLabel: {
        "en-GB": "Please, email doesn't have a valid format",
        "es-ES": "Por favor, el email no tiene un formato adecuado",
        "ca-ES": "Si us plau, el email no té un format adequat"
    },
    emailMandatoryErrorLabel: {
        "en-GB": "Please, email is mandatory",
        "es-ES": "Por favor, introduce un correo electrónico",
        "ca-ES": "Si us plau, introdueix un correu electrònic"
    },
    yourEmailPlaceHolder: {
        "en-GB": "youremail@mail.com",
        "es-ES": "tuemail@mail.com",
        "ca-ES": "elteuemail@mail.com"
    },
    doneLabel: {
        "en-GB": "Subscribed!",
        "es-ES": "Ya te has suscrito!",
        "ca-ES": "Ja t'has subscrit!"
    },
    doneParagraphLabel: {
        "en-GB": "Congrats! You're now subscribed so you won't miss any important updates. You'll receive your first issue soon. Thanks a mill! ",
        "es-ES": "Enhorabuena! Ya te has suscrito a nuestra newsletter así que no te perderás ningún detalle importante. Recibirás el primer capítulo próximamente. Gracias de nuevo!",
        "ca-ES": "Enhorabona! Ja t'has suscrit a la nostra newsletter així que no et perdràs cap notícia important. Rebràs el pròxim email de la nostra newsletter aviat. Gràcies de nou!"
    },
    consentText: {
        "en-GB": "<b>Consent</b>. We care about your personal data. We'll keep you up to date about new features that we'll be releasing frequently. You can exercise your rights according to our <a href='/privacy-policy' target='_blank'>Privacy Policy</a>",
        "es-ES": "<b>Consiento el tratamiento de mis datos</b>. Trataremos sus datos con la finalidad de mantenerle informado acerca de nuestras noticias, promociones y novedades. Puede ejercer sus derechos de acceso, modificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra <a href='/privacy-policy' target='_blank'>Política de Privacidad y Aviso Legal</a>",
        "ca-ES": "<b>Consiento el tratamiento de mis datos</b>. Trataremos sus datos con la finalidad de mantenerle informado acerca de nuestras noticias, promociones y novedades. Puede ejercer sus derechos de accesso, modificación, supresión, portabilidad, limitación y oposición, como le informamos en nuestra <a href='/privacy-policy' target='_blank'>Política de Privacidad y Aviso Legal</a>"
    },
    mustAcceptedTosLabel: {
        "en-GB": " Please, we need your consent.",
        "es-ES": " Por favor, necesitamos su consentimiento.",
        "ca-ES": " Si us plau, necessitem el seu consentiment."
    },
    submitLabel: {
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "ca-ES": "Envia"
    },
    submittingLabel: {
        "en-GB": "Submitting...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    }

};


export { NewsLetterLiterals };