import React from 'react';

class InputState
{
    isValid: boolean;
    validationErrorMessage?: string;
    isDisabled: boolean;
    value: string;
    isValidated: boolean;

    constructor()
    {
      this.isValid = false;
      this.isDisabled = false;
      this.value = '';
      this.validationErrorMessage = '';
      this.isValidated = false;
    }
};


class CheckBoxInputState
{
    isValid: boolean;
    validationErrorMessage?: string;
    isDisabled: boolean;
    checked: boolean;
    isValidated: boolean;

    constructor()
    {
      this.isValid = true;
      this.isDisabled = false;
      this.checked = false;
      this.validationErrorMessage = '';
      this.isValidated = false;
    }
};

export const CreateInputState = (value?: string) : InputState => {
  let input = new InputState();
  input.value = value ? value : "";
  return input;
};

export const CreateValidatedInputState = (value?: string) : InputState => {
  let input = new InputState();
  input.value = value ? value : "";
  input.isValid = true;
  return input;
};

interface ValidationResult 
{
  isValid: boolean;
  errorMessage?: string;
};

interface BaseInputProps
{
  id: string;
  name: string;
  usesValidation?: boolean;
  validationErrorMessage?: string;
  displayValidation: boolean;
  isMandatory?: boolean;
  mandatoryErrorMessage?: string;
}

interface InputProps extends BaseInputProps
{
  inputType?: string;
  placeHolder: string;

  onChange: (newState: InputState) => void;
};

interface TextInputProps extends InputProps
{
  validationRegex?: string;
  maxLength?: number;
  value?: string;
  validationFn?: (value: string) => ValidationResult;
};

interface TextAreaInputProps extends TextInputProps
{
  rows?: number;
  value?: string;
};

interface EmailInputProps extends InputProps
{
  value?: string;
};

interface DropDownOption 
{
  value: string;
  label: string;
}

interface DropwDownInputProps extends BaseInputProps
{
  placeHolder: string;
  options: DropDownOption[];
  value?: string;
  onChange: (newState: InputState) => void;
};

interface CheckBoxInputProps extends BaseInputProps
{
  checked?: boolean;
  onChange: (newState: CheckBoxInputState) => void;
};


export type { InputProps, TextInputProps, EmailInputProps, CheckBoxInputProps, TextAreaInputProps, DropwDownInputProps, ValidationResult, DropDownOption };

export { InputState, CheckBoxInputState };