import React, { useEffect } from "react";
import { useState } from "react";
import { EmailLiterals, IEmailLiterals } from "../../../literals/account/user-profile/EmailLiterals";
import { getLiteralsForCulture } from "../../../literals/Literals";
import { PostJsonAsync } from "../../../services/FetchService";
import { GenericResponse } from "../../Api/GenericResponse";
import { AppProps } from "../../AppState";
import { Card } from "../../Cards/Card";
import { Loading } from "../../Loading";
import { AppModal } from "../../Modal/AppModal";

interface EmailVerifiedModel {
  isVerified: boolean;
  email: string;
}

interface EmailVerifiedResponse extends GenericResponse 
{
  model: EmailVerifiedModel;
}

const Email = ({appState} : AppProps) => {

    const [ emailAddress, setEmailAddress ] = useState("");
    const [ isEmailVerified, setIsEmailVerified ] = useState(false);

    const [ isLoading, setIsLoading ] = useState(true);
    const [ isSubmitErrorVisible , setIsSubmitErrorVisible ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ submitError, setSubmitError ] = useState("");
    
    const literals = getLiteralsForCulture(EmailLiterals, appState.currentCulture) as IEmailLiterals; 

    useEffect(() => {
        const getEmailVerified = async() => {
          const result = await PostJsonAsync("/api/account/is-email-confirmed", {}, appState);
          if(result.succeeded)
          {
            const model = (result as EmailVerifiedResponse).model;
            setIsEmailVerified(model.isVerified);
            setEmailAddress(model.email);
          }
          setIsLoading(false);
        };
        getEmailVerified();
      }, []);
      
    const resendEmail = () => {
      
      setIsSubmitting(true);

        const SubmitAsync = async () => {
          const result = await PostJsonAsync("/api/account/resend-confirmation", {}, appState);
          if(result.succeeded) {
            setIsSubmitErrorVisible(false);
          }
          else {
            setSubmitError(result.errorMessage);
            setIsSubmitErrorVisible(true);
          }

          setIsSubmitting(false);
        };

        SubmitAsync();
    };

    let cardBody = (<div>
      <p>{emailAddress}   {isEmailVerified && <i style={{color: "#27a283", marginLeft: "0.5em"}} className="bi bi-person-check-fill"></i>}</p>
      {!isEmailVerified && <p className="card-text">{literals.emailNotVerifiedDescription}</p>}
      {!isEmailVerified && <div className="row">
          <div className="col-md-4">
              {isSubmitting && <button className="btn btn-primary" disabled={true}>{literals.sending}</button>}
              {!isSubmitting && <button className="btn btn-primary" onClick={resendEmail} >{literals.resendEmail}</button>}
          </div>
      </div>}
    </div>);

    let loadingCard = (<div><Loading /></div>);

    return (
        <div className="col-md-12">
            <AppModal isOpen={isSubmitErrorVisible} title={literals.email} bodyText={submitError} onClickOk={() => setIsSubmitErrorVisible(false)} />
            
            <Card title={literals.email} body={isLoading ? loadingCard : cardBody}  />
 
            
        </div>
    );
};


export { Email };