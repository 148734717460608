import React, { Component } from 'react';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { CodeComponent } from './CodeComponent';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";

export class HowToTestACodeActivity extends Component {

  render () {
    var literals = getLiteralsForCulture(ContactLiterals, 'en-GB') as any;
    const textContent = `
    //  Welcome to your first Code Activity Example
    //
    //  You can test codeactivities similar to plugins
    //
    //  with the ExecuteCodeActivity method, which expects
    //  your code activity, and the inputs
    
    //  Here is an example from the SDK which tests that
    //  a codeactivity which adds two ints, produces the
    //  expected output:
    
    [Fact]
    public void When_the_add_activity_is_executed_the_right_sum_is_returned()
    {
        var fakedContext = new XrmFakedContext();
    
        //Inputs
        var inputs = new Dictionary<string, object>() {
        { "firstSummand", 2 },
        { "secondSummand", 3 }
        };
    
        var result = fakedContext.ExecuteCodeActivity<AddActivity>(inputs);
    
        Assert.True(((int)result["result"]).Equals(5));
    }
    //
    //  Also, like when you are testing plugins, codeactivities accept
    //  a fake code activity context you can use to inject context properties
    //  Here is an example:
    
    [Fact]
    public void When_passing_a_custom_workflow_activity_context_injected_property_is_returned()
    {
        var fakedContext = new XrmFakedContext();
        var wfContext = fakedContext.GetDefaultWorkflowContext();
        wfContext.MessageName = "Update";
    
        //Inputs
        var inputs = new Dictionary<string, object>();
    
        CheckContextPropertyActivity codeActivity = new CheckContextPropertyActivity();
    
        var result = fakedContext.ExecuteCodeActivity<CheckContextPropertyActivity>
            (wfContext, inputs, codeActivity);
    
            Assert.True(((string)result["MessageName"]).Equals("Update"));
    }
    //
    //    

    `;

    return (
      <GetStarted isHowToTestACodeActivity={true}>
        <div>
        <div className="row">
            <h1>How to Test a CodeActivity</h1>
        </div>
        <div className="row">
            <CodeComponent code={textContent}></CodeComponent>
        </div>
      </div>
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
