import React from 'react';
import { ResetPasswordLiterals } from '../../literals/account/ResetPasswordLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

const ResetPasswordSent = ({appState}: AppProps) => {

  const literals = getLiteralsForCulture(ResetPasswordLiterals, appState.currentCulture) as any;
    
    return (
      <div>
          <section className="fit theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-2 col-md-10">
                      <h2>{literals.resetPasswordSent}</h2>

                      <p>{literals.resetPasswordSentDesc}</p>
                  </div>
              </div>
          </section>
      </div>
    );
  
}

export { ResetPasswordSent };
