var CustomerStoriesLiterals = {

    customerStoriesHeading: {
        "en-GB": "Customer Stories",
        "es-ES": "Historias de Clientes",
        "ca-ES": "Històries de Clients"
    },
    desc: 
    {
        "en-GB": "Some of our amazing clients ...",
        "es-ES": "Algunos de nuestros fantásticos clientes ...",
        "ca-ES": "Alguns dels nostres fantàstics clients ..."
    },
    kenticoQuote: 
    {
        "en-GB": "I consider unit testing a core part of our development process for Dynamics CRM, and FakeXrmEasy is simply the best tool for it. Especially for the modern .NET Core platform. It saves us hours of development time every week.",
        "es-ES": "Considero las pruebas unitarias una parte fundamental de nuestros procesos de desarrollo sobre Dynamics CRM, y FakeXrmEasy es, simplemente, la mejor herramienta para ello. Especialmente para moderna plataforma de .NET Core. Nos ahorra horas de tiempo de desarollo cada semana.",
        "ca-ES": "Considero les proves unitàries una part fonamental dels nostres processos de desenvolupament sobre Dynamics CRM, i FakeXrmEasy és, senzillament, la millor eina per fer-ho. Especialment per la moderna plataforma de .NET Core. Ens estalvia hores de desenvolupament cada setmana."
    },
    betwayQuote: 
    {
        "en-GB": "Unit testing has always been a crucial aspect of our process, particularly when it comes to Dynamics 365. Striving to enhance our testing methodologies, we stumbled upon FakeXrmEasy, and it turned out to be a game changer that simplified our workflow and accelerated our development.",
        "es-ES": "Las pruebas unitarias siempre han sido un aspecto crucial de nuestro proceso, especialmente cuando se trata de Dynamics 365. Esforzándonos en mejorar nuestras metodologías de testing, nos encontramos con FakeXrmEasy, y ha resultado ser un cambio de paradigma que ha simplificado nuestros procesos y acelerado nuestro desarrollo.",
        "ca-ES": "Les proves unitàries sempre han estat un aspecte fonamental del nostre procès, especialment quan es tracta de Dynamics 365. Esforçant-nos en millorar les nostres metodologies de testing, ens vàrem trobar amv fakeXrmEasy, i ha resultat ser un canvi de paradigma que ha simplificat els nostres processos i accelerat el nostre desenvolupament."
    },
    innofactorQuote: {
        "en-GB": "Nobody likes unit testing, but FakeXrmEasy makes it almost fun.",
        "es-ES": "A nadie le gustan las pruebas unitarias, pero FakeXrmEasy lo hace hasta divertido.",
        "ca-ES": "A ningú li agraden les proves unitàries, pero FakeXrmEasy ho fa fins i tot divertit."
    },
}

export { CustomerStoriesLiterals };