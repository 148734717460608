export interface Currency {
    symbol: string;
    isoCode: string;
}

const GetAllCurrencies = () => {
    return <Currency[]> [
        { symbol: "€", isoCode: "EUR" },
        { symbol: "$", isoCode: "USD" },
        { symbol: "£", isoCode: "GBP" },
        { symbol: "A$", isoCode: "AUD" },
    ];
}

const GetEurCurrency = () => {
    return <Currency> { symbol: "€", isoCode: "EUR" };
}

export { GetAllCurrencies, GetEurCurrency };