import React, { Component } from 'react';
import { EmailInput } from '../Input/EmailInput';
import { TextAreaInput } from '../Input/TextAreaInput';
import { getLiteralsForCulture } from '../../literals/Literals';
import { InputState, CheckBoxInputState } from '../Input/types';
import { TextInput } from '../Input/TextInput';
import { CheckBoxInput } from '../Input/CheckBoxInput';
import { AppProps } from '../AppState';
import { Help } from '../Help/Help';
import { Redirect } from 'react-router';
import { GenericResponse } from '../Api/GenericResponse';
import { AppModal } from '../Modal/AppModal';
import { WhitePaperLiterals } from '../../literals/white-paper/WhitePaperLiterals';
import { PostJson } from '../../services/FetchService';

interface SubmitModel 
{
  email: string;
  allowEmails: boolean;
};

interface SubmitResponse 
{
  success: boolean;
  errorMessage: string;
}

class ContactState 
{
    emailState?: InputState = undefined;
    consent?: CheckBoxInputState = undefined;
    isSubmitting: boolean = false;
    validateForm: boolean = false;
    
    showSubmitError: boolean = false;
    submitError: string = "";

    redirect?: string = undefined;

    constructor()
    {

    }

    
};

export class WhitePaper extends Component<AppProps, ContactState> {
  constructor(props: any)
  {
      super(props);
      this.emailOnChange = this.emailOnChange.bind(this);
      this.consentOnChange = this.consentOnChange.bind(this);
      this.submit = this.submit.bind(this);
      this.hideError = this.hideError.bind(this);

      this.state = new ContactState();
  }

  emailOnChange = (newState: InputState) => {
    this.setState({
        emailState: newState
    });
  }

  consentOnChange = (checkBoxState: CheckBoxInputState) => {
    this.setState({
      consent: checkBoxState
    });
  }

  toSubmitModel() : SubmitModel {
    return {
      email: this.state.emailState ? this.state.emailState.value : "",
      allowEmails: true
    };
  }

  hideError() : void {
    this.setState({
      showSubmitError: false
    });
  }
  submit = () => {

    var self = this;

    this.setState({
      validateForm: true
    });

    
    if(this.state.emailState?.isValid && this.state.consent?.isValid)

      {
        this.setState({
          isSubmitting: true
        });

        PostJson("/api/white-paper/submit", self.toSubmitModel(), this.props.appState, result => {
          if(result.succeeded)
              {
                this.setState({
                  redirect: "/white-paper/sent"
                });
              }
              else {
                this.setState({
                  isSubmitting: false,
                  submitError: result.errorMessage,
                  showSubmitError: true
                });
              }
        }, this.props.appState.handleApiErrorFn);
      }
  }

  render () {
    if(this.state.redirect)
    {
      return (<Redirect to={this.state.redirect} />);
    }

    const literals = getLiteralsForCulture(WhitePaperLiterals, this.props.appState.currentCulture) as any;
    var shouldValidateForm = this.state.validateForm;

    return (
      <div>
         <AppModal isOpen={this.state.showSubmitError} title={literals.contactLabel} bodyText={this.state.submitError} onClickOk={this.hideError} />

          <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                      <h2>{literals.researchStudyLabel}</h2>

                      <p>{literals.researchStudyDescription}</p>
                      <p>{literals.outcome}</p>

                      <ul>
                        <li><span dangerouslySetInnerHTML={{__html: literals.escapedDefectsReduction}}></span></li>
                        <li><span dangerouslySetInnerHTML={{__html: literals.increasedEfficiency}}></span></li>
                      </ul>

                      <span>{literals.escapedDefectMeaning}</span>
                      
                  </div>
              </div>
              <div className="row">
                <div className="form-group col-md-offset-1 col-md-10">
                <p>{literals.getTheStudyAtYourInbox}</p>
                </div>
              </div>
              <div className="row">
                  <div className="col-md-6">
                        <form id="contact">
                        <div className="row">
                            <div className="form-group col-md-offset-2 col-md-5">
                                <label htmlFor="email">{literals.emailAddressLabel }</label>
                                <EmailInput id="email" name="email" placeHolder={literals.yourEmailPlaceHolder} 
                                    displayValidation={shouldValidateForm} isMandatory={true} mandatoryErrorMessage={literals.emailMandatoryErrorLabel} 
                                    validationErrorMessage={literals.emailNotValidLabel} 
                                    onChange={this.emailOnChange} 
                                    value={this.state.emailState?.value}></EmailInput>
                            </div>
                        </div>
      
                        <div className="row">
                              <div className="form-group col-md-offset-2 col-md-10">
                                  <CheckBoxInput id="marketingConfirmation" name="marketingConfirmation" 
                                  isMandatory={true} displayValidation={shouldValidateForm} mandatoryErrorMessage={literals.consentNeeded}
                                  onChange={this.consentOnChange} 
                                  checked={this.state.consent?.checked}><span dangerouslySetInnerHTML={{__html: literals.consentText}}></span></CheckBoxInput>
                              </div>
                          </div>
                    </form>
                    <div className="row">
                        <div className="col-md-offset-2 col-md-4">
                            {this.state.isSubmitting && <button className="btn btn-primary" disabled={true}>{literals.submittingLabel}</button>}
                            {!this.state.isSubmitting && <button className="btn btn-primary" onClick={this.submit} >{literals.submitLabel}</button>}
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="row">
                      <div className="col-md-offset-2 col-md-10">
                          <Help appState={this.props.appState} />
                      </div>
                    </div>
                  </div>
               </div>
              

              
          </section>
      </div>
    );
  }
}
