var CultureLiterals = {
    "es-ES": {
        "es-ES": "Castellano",
        "ca-ES": "Castellà",
        "en-GB": "Spanish"
    },
    "ca-ES": {
        "es-ES": "Catalán",
        "ca-ES": "Català",
        "en-GB": "Catalan"
    },
    "en-GB": {
        "es-ES": "Inglés",
        "ca-ES": "Anglès",
        "en-GB": "English"
    },
};

export { CultureLiterals };