import React, { Component } from 'react';
import { AppSettings } from '../AppSettings';
import { ILayoutLiterals, LayoutLiterals } from '../literals/layout/LayoutLiterals';
import { getLiteralsForCulture } from '../literals/Literals';
import { AppState } from './AppState';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';

interface LayoutProps
{
  appState: AppState;
  newVersionAvailable: boolean;
  checkingNewVersion: boolean;
}

interface NewUpdateAvailableProps {
  literals: ILayoutLiterals;
}
const NewUpdateAvailable = ({literals}: NewUpdateAvailableProps) => {
  const appSettings = new AppSettings();
  return (<div className="row theme-green">
      <div className="col-md-12">
        <p style={{marginTop: '0', marginBottom:0, padding: '8px', textAlign: 'center'}}>{literals.newVersionAvailable} ({appSettings.version}). {literals.closeAndReopenTabs}</p>
      </div>
    </div>);
};

interface CheckingNewVersionProps {
  literals: ILayoutLiterals;
}
const CheckingNewVersion = ({literals}: CheckingNewVersionProps) => {
  const appSettings = new AppSettings();
  return (<div className="row theme-green">
      <div className="col-md-12">
        <p style={{marginTop: '0', marginBottom:0, padding: '8px', textAlign: 'center'}}>{literals.checkingNewVersion}</p>
      </div>
    </div>);
};
export class Layout extends Component<LayoutProps> {
  static displayName = Layout.name;

  render () {
    const literals = getLiteralsForCulture(LayoutLiterals, this.props.appState.currentCulture) as ILayoutLiterals; 
    return (
      <div>
        {this.props.checkingNewVersion && <CheckingNewVersion literals={literals}/>}
        {this.props.newVersionAvailable && <NewUpdateAvailable literals={literals}/>}
        <NavMenu appState={this.props.appState} />
        <div className="top-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}
