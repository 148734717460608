import React, { Component } from 'react';
import { CustomerStoriesLiterals } from '../../literals/customer-stories/CustomerStoriesLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';
import kenticoLogo from '../../images/customer-stories/kentico/kentico-logo-hor-color-pos-sz-rgb.svg';
import { CustomerStory } from './CustomerStory';

export class CustomerStories extends Component<AppProps> {

  render () {
    var literals = getLiteralsForCulture(CustomerStoriesLiterals, this.props.appState.currentCulture) as any;

    return (
      <div>
          <section className="fit theme-white">
            <div className="row">
                <div className="form-group col-md-12 text-center">
                  
                    <h2>{literals.customerStoriesHeading} <i className="bi bi-chat-quote-fill"></i></h2>

                    <p>{literals.desc}</p>
                </div>
            </div>

            <CustomerStory 
              imageLogo={kenticoLogo}
              feedback={literals.kenticoQuote}
              author={'Michal Vrana'}
              role={'Technical Leader at Kentico'}
            />

            <CustomerStory 
              companyTitle='Innofactor'
              feedback={literals.innofactorQuote}
              author={'Atte Niskakoski'}
              role={'Architect at Innofactor - Finland'}
            />

            <CustomerStory 
              companyTitle='Betway Group'
              feedback={literals.betwayQuote}
              author={'Marcin Bochenko'}
              role={'Lead Developer at Win Technologies, Betway Group - London, UK'}
            />
        </section>
      </div>
    );
  }

}
