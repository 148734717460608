import React, { Component } from 'react';
import { ContactLiterals } from '../../literals/contact/ContactLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { CodeComponent } from './CodeComponent';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";

export class ManyToManyRelationships extends Component {

  render () {
    var literals = getLiteralsForCulture(ContactLiterals, 'en-GB') as any;
    const textContent = `
    //  N:N Relationships
    //
    //  By using FakeXrmEasy we have mocks automatically implemented to deal with 1:N and N:1 relationships.
    // 
    //  This is because those relationships are modelled as "lookups" using EntityReference fields.
    //
    //  However, a N:N relationship requires an intermediate table or intersect entity to store the reference to the 2 related entities.
    //  
    //  Therefore, in that case, we need a small extra step to tell FakeXrmEasy to know where to find such intersect entity
    //  
    //  when dealing with messages like Associate.
    //
    //  We just need to call .AddRelationship and pass an XrmFakedRelationship instance which defines
    // 
    //  how are the entities related to each other.
            
     
    using System;
    
    using Xunit;
    using FakeItEasy;
    using FakeXrmEasy;
    
    using System.Collections.Generic;
    using Microsoft.Xrm.Sdk;
    using System.Linq;
    
    namespace MyFirstXrmUnitTestProject
    {
        public class MyTests
        {
            [Fact]
            public void MyFirstTest()
            {
    
                var context = new XrmFakedContext();
    
                context.AddRelationship("systemuserroles_association", new XrmFakedRelationship
                {
                    IntersectEntity = "systemuserroles",
                    Entity1LogicalName = SystemUser.EntityLogicalName,
                    Entity1Attribute = "systemuserid",
                    Entity2LogicalName = Role.EntityLogicalName,
                    Entity2Attribute = "roleid"
                });
    
                //Now any associate / disassociate request for "systemuserroles_association"
                //will use the specified entities and fields above
    
                //Easy!
                
            }
        }
    }
      
    `;

    return (
      <GetStarted isManyToManyRelationship={true}>
        <div>
        <div className="row">
            <h1>N:N Relationships</h1>
        </div>
        <div className="row">
            <CodeComponent code={textContent}></CodeComponent>
        </div>
      </div>
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
