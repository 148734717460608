import React, { Component } from 'react';
import dynamicsValueLogo from '../images/DynamicsValueLogo.svg';
import { AppState } from './AppState';
import { AppVersion } from './AppVersion';
import { FooterLiterals, IFooterLiterals } from '../literals/footer/FooterLiterals';
import { getLiteralsForCulture } from '../literals/Literals';
import { Link } from 'react-router-dom';

interface FooterProps
{
  appState: AppState;
}
export class Footer extends Component<FooterProps> {
  render () {
    const literals = getLiteralsForCulture(FooterLiterals, this.props.appState.currentCulture) as IFooterLiterals;
    var fontStyle = {fontSize: '13px'};

    return (
        <section className="theme-dark-gray">
            <hr />
            <footer>
                <ul className="site-footer-links">
                    <li><img src={dynamicsValueLogo} style={{height: '50px'}}/></li>
                    <li>&copy; 2015 DynamicsValue</li>
                    <li><Link to="/privacy-policy">{literals.privacyPolicy}</Link></li>
                    <li><Link to="/cookies-policy">{literals.cookiePolicy}</Link></li>
                    <li><Link to="/licensing">{literals.licensing}</Link></li>
                </ul>
                <AppVersion appState={this.props.appState} />
            </footer>
        </section>
    );
  }
}