import React, { Component } from 'react';
import { TrainingLiterals } from '../../literals/training/TrainingLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';


export class Training extends Component<AppProps> {

  render () {
    var literals = getLiteralsForCulture(TrainingLiterals, this.props.appState.currentCulture) as any;

    return (
      <div>
          <section className="fit theme-dark-gray">
            <div className="row">
                <div className="form-group col-md-offset-1 col-md-11">
                  
                    <h2>{literals.trainingLabel }</h2>

                    <p>{literals.trainingDesc}</p>
                </div>
            </div>
        </section>
      </div>
    );
  }

}
