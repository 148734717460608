const BillingAddressLiterals = {
    billingAddress: {
        "en-GB": "Billing Address",
        "es-ES": "Dirección de Facturación",
        "ca-ES": "Direcció de Facturació"
    },
    title: {
        "en-GB": "Billing",
        "es-ES": "Facturación",
        "ca-ES": "Facturació"
    },
    companyName: {
        "en-GB": "Company Name",
        "es-ES": "Nombre de Empresa",
        "ca-ES": "Nom d'Empresa"
    },
    cif: {
        "en-GB": "CIF",
        "es-ES": "CIF",
        "ca-ES": "CIF"
    },
    cifNotValidMessage: {
        "en-GB": "CIF is not valid, please use only alphanumeric characters, without whitespaces",
        "es-ES": "CIF no es válido, por favor, usa sólo caracteres alfanuméricos sin puntos ni guinoes ni espacios.",
        "ca-ES": "CIF no és vàlid, si us plau, utilitza només caràcters alfanumèrics sense espais, guions ni punts."
    },
    country: {
        "en-GB": "Country",
        "es-ES": "País",
        "ca-ES": "País"
    },
    city: {
        "en-GB": "City",
        "es-ES": "Ciudad",
        "ca-ES": "Ciutat"
    },
    addressLine1: {
        "en-GB": "Address Line 1",
        "es-ES": "Dirección",
        "ca-ES": "Direcció"
    },
    addressLine2: {
        "en-GB": "Address Line 2",
        "es-ES": "Puerta / Piso",
        "ca-ES": "Porta / Pis"
    },
    postCode: {
        "en-GB": "Post Code",
        "es-ES": "Código Postal",
        "ca-ES": "Codi Postal"
    },
    save: {
        "en-GB": "Save",
        "es-ES": "Guardar",
        "ca-ES": "Guardar"
    },
    saving: {
        "en-GB": "Saving...",
        "es-ES": "Guardando...",
        "ca-ES": "Guardant..."
    }
}

interface IBillingAddressLiterals {
    title: string;
    billingAddress: string;
    country: string;
    save: string;
    saving: string;
    companyName: string;
    cif: string;
    cifNotValidMessage: string;
    city: string;
    addressLine1: string;
    addressLine2: string;
    postCode: string;
}

export { BillingAddressLiterals };
export type { IBillingAddressLiterals };