import React from "react";
import { HelpLiterals } from "../../literals/help/HelpLiterals";
import { getLiteralsForCulture } from "../../literals/Literals";
import { AppProps } from "../AppState";

const Help = (appProps: AppProps) => 
{
    const literals = getLiteralsForCulture(HelpLiterals, appProps.appState.currentCulture) as any;
    return (<div>
                <h2>{literals.helpLabel}</h2>
                <h3><i aria-hidden="true" className="fa fa-envelope"></i> info@dynamicsvalue.com</h3>
                <h4>{literals.businessHoursLabel}: 9:00 - 18:30 CET ({literals.mondayFridayLabel})</h4>
            </div>);
}

export { Help };