import React, { Component, FocusEvent } from 'react';

import { ValidationResult, TextInputProps, InputState} from './types';

export class TextInput extends Component<TextInputProps, {}> {

    constructor(props: TextInputProps) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    validate = (value: string) : ValidationResult => 
    {
        if(this.props.isMandatory === true && value.trim() === '') 
        {
            return {
                isValid: false,
                errorMessage: this.props.mandatoryErrorMessage
            };  
        }

        if(this.props.maxLength && value.trim().length > this.props.maxLength) 
        {
        return {
            isValid: false,
            errorMessage: this.props.validationErrorMessage
        };  
        }

        if(this.props.validationRegex && this.props.validationRegex !== '')
        {
            var regExp = new RegExp(this.props.validationRegex);
            if(!regExp.test(value)) {
                return {
                isValid: false,
                errorMessage: this.props.validationErrorMessage
                }; 
            }
                
        }

        if(this.props.validationFn)
        {
            return this.props.validationFn(value);
        }

        return {isValid: true};
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        var newValue = e.target.value;
        var inputState = new InputState();
        inputState.value = newValue;

        var validationResult = this.validate(newValue);
        inputState.isValid = validationResult.isValid;
        inputState.validationErrorMessage = validationResult.errorMessage;
        
        this.props.onChange(inputState);
    }

    render () {
        var validationResult = this.validate(this.props.value ? this.props.value : "");

        if(validationResult.isValid || !this.props.displayValidation)
        {
            return (
                <div>
                    <input type="text" className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value} autoComplete="off" />
                </div>
            );
        }
        else 
        {
            return (
                <div className="has-error">
                <input type="text" className="form-control" id={this.props.id} name={this.props.name} placeholder={this.props.placeHolder} onChange={this.onChange} onBlur={this.onChange} value={this.props.value} autoComplete="off" />
                <label data-formvalidator-renderedmessage>{validationResult.errorMessage}</label>
                </div>
            );
        }
    }
}
