import React, { Component } from 'react';
import { getLiteralsForCulture } from '../../literals/Literals';
import { NewsLetterLiterals } from '../../literals/newsletter/NewsLetterLiterals';
import { AppProps } from '../AppState';
import { Help } from '../Help/Help';

export class NewsletterSubscribed extends Component<AppProps> {
  constructor(props: any)
  {
      super(props);
  }

  render () {
    const literals = getLiteralsForCulture(NewsLetterLiterals, this.props.appState.currentCulture) as any;
    
    return (
      <div>
          <section className="fit theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-2 col-md-10">
                      <h2>{literals.doneLabel}</h2>

                      <p>{literals.doneParagraphLabel}</p>
                  </div>
              </div>
              <div className="row">
                  <div className="form-group col-md-offset-2 col-md-10">
                    <Help appState={this.props.appState}></Help>
                  </div>
              </div>
          </section>
      </div>
    );
  }
}
