import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppState } from './components/AppState';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const rootElement = document.getElementById('root');

let appState: AppState = new AppState();

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App initialAppState={appState} />
  </BrowserRouter>,
  rootElement);

const readyCallback = () => {
  console.log("index: worker is ready");
  if(appState.checkingNewVersionFn)
    appState.checkingNewVersionFn(false);
};

const serviceWorkercallback = (newVersionAvailable: boolean) => {
  console.log("index: worker completed");
  console.log("newVersionAvailable: " + newVersionAvailable);
  if(appState.newVersionAvailableFn)
    appState.newVersionAvailableFn(newVersionAvailable);

  if(appState.checkingNewVersionFn)
    appState.checkingNewVersionFn(false);
};

registerServiceWorker(serviceWorkercallback, readyCallback);

