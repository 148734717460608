import React from 'react';
import { Link } from 'react-router-dom';
import { HomeLiterals } from '../../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export const LandingTop = (appProps: AppProps) => {

    const literals = getLiteralsForCulture(HomeLiterals, appProps.appState.currentCulture) as any;

    return (
        <section className="fit home" style={{overflowX: "hidden"}}>
        <div style={{margin: "0px", backgroundColor: "rgba(0, 0, 0, 0.75)", height: "100%", paddingTop: "10%"}}>
            <div className="row" style={{marginTop: "0px"}}>
                <div className="col-md-12 animation-element fade-in" style={{padding: "0"}}>
                    <h1 className="launch-heading">{literals.keepYourCodeLabel} <u className="green">{literals.greenLabel}</u></h1>
                    <h2 className="launch-heading">{literals.dynamicsCrmAutomatedFrameworkLabel}</h2>
                    <h3 className="launch-heading">{literals.easyFastFreeFoereverLabel}</h3>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 animation-element fade-in">
                    <div style={{marginLeft: "0 auto", marginRight: "0 auto", textAlign: "center"}}>
                        <Link className="btn btn-lg btn-primary" to="/get-started/overview">{literals.getStartedWithFakeXrmEasyLabel}</Link>
                        <h4 className="launch-heading">{literals.backedByLabel}</h4>
                    </div>
                    
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 animation-element fade-in text-center">
                    <i style={{fontSize: '38px'}} className="bi bi-arrow-down-square-fill"></i> 
                </div>
            
            </div>
        </div>
    </section>
    );
    
}
