import React, { Component } from 'react';
import { GetStarted } from './GetStarted';
import { AnimationComponentUtils } from '../Utils/ComponentUtils';
import $ from "jquery";

export class OtherExamples extends Component {

  render () {
    return (
      <GetStarted isOtherExamples={true}>
        <div className="row">
            <h1>Other Examples</h1>
        </div>

        <div className="row">
            <section className="theme-white">
                <div className="row">
                    <p>Given FakeXrmEasy mocks IOrganizationService calls for you, the use of the library is not limited to just testing plugins or codeactivities, but to test ANY .NET application using the IOrganizationService interface. </p>

                    <p> Therefore, you can, for example, develop and test the backend of web portals, console apps, SSIS packages, ... to just name a few. </p>
                    
                    <p style={{marginTop: "20px", fontSize: "12px"}}>Note: This section is only available in English. If you would like this section translated to other languages, please, <a href="/contact">get in touch</a> ant let us know. </p>
                    <p style={{fontSize: "12px"}}>Nota: Esta sección sólo está disponible en inglés. Si desea esta sección traducida a otros idiomas, por favor, <a href="/contact">contacte con nosotros</a> para hacérnoslo saber. </p>
                    <p style={{fontSize: "12px"}}>Nota: Aquesta secció només està disponible an anglès. Si desitja aquesta secció traduïda a altres idiomes, si us plau, <a href="/contact">contacti amb nosaltres</a> per fer-nos-ho saber. </p>
                </div>
            </section>
        </div>
        
      </GetStarted>

      
    );
  }

  componentDidMount()
  {
    AnimationComponentUtils.registerEvents(window);
    AnimationComponentUtils.checkIfInView($(window));
  }
}
