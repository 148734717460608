import React, { Component } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Contact } from './components/Contact/Contact';

import './css/site.css';
import './css/prism.css';
import './css/pricing-switch.css';
import './css/testimonials-gallery.css';
import './css/gauge.css';
import './css/cards.css';

import { LegalNotice } from './components/Legal/LegalNotice';
import { CookiesPolicy } from './components/Legal/CookiesPolicy';
import { PrivacyPolicy } from './components/Legal/PrivacyPolicy';
import { ContactSent } from './components/Contact/ContactSent';
import { GetStarted } from './components/GetStarted/GetStarted';
import { Installation } from './components/GetStarted/Installation';
import { Overview } from './components/GetStarted/Overview';
import { YourFirstTest } from './components/GetStarted/YourFirstTest';
import { HowToTestAPlugin } from './components/GetStarted/HowToTestAPlugin';
import { HowToTestACodeActivity } from './components/GetStarted/HowToTestACodeActivity';
import { Queries } from './components/GetStarted/Queries';
import { ManyToManyRelationships } from './components/GetStarted/ManyToManyRelationships';
import { OtherExamples } from './components/GetStarted/OtherExamples';
import { Training } from './components/Training/Training';
import { Pricing } from './components/Pricing/Pricing';
import { QuoteSent } from './components/Quote/QuoteSent';
import { SignUp } from './components/Account/SignUp';
import { SignIn } from './components/Account/SignIn';
import { Registered } from './components/Account/Registered';
import { Dashboard } from './components/Account/Dashboard';
import { AppState } from './components/AppState';
import { NotFound } from './components/NotFound';
import { QuoteTemp } from './components/Quote/QuoteTemp';
import ScrollToTop from './components/ScrollToTop';
import { WhitePaper } from './components/WhitePaper/WhitePaper';
import { WhitePaperSent } from './components/WhitePaper/WhitePaperSent';
import { CustomerStories } from './components/CustomerStories/CustomerStories';
import { Newsletter } from './components/Newsletter/Newsletter';
import { NewsletterSubscribed } from './components/Newsletter/NewsletterSubscribed';
import { AppSettings } from './AppSettings';
import { ConfirmEmail } from './components/Account/ConfirmEmail';
import * as qs from 'query-string';
import { ForgotPassword } from './components/Account/ForgotPassword';
import { ForgotPasswordSent } from './components/Account/ForgotPasswordSent';
import { ResetPassword } from './components/Account/ResetPassword';
import { ResetPasswordSent } from './components/Account/ResetPasswordSent';
import { Forbidden } from './components/Forbidden';
import { AppError } from './components/Error';
import { Billing } from './components/Account/Billing';
import { Licensing } from './components/Legal/Licensing';

interface AppProperties {
  initialAppState: AppState;
}
export default class App extends Component<AppProperties, AppState> {
  static displayName = App.name;

  constructor(props: any)
  {
      super(props);
      let initialState = this.props.initialAppState;
      initialState.changeCultureFn = this.changeCulture.bind(this);
      initialState.setAuthenticatedFn = this.setAuthenticated.bind(this);
      initialState.handleApiErrorFn = this.handleApiError.bind(this);
      initialState.checkingNewVersionFn = this.checkingNewVersionFn.bind(this);
      initialState.newVersionAvailableFn = this.newVersionAvailableFn.bind(this);
      this.state = initialState;
  }

  checkingNewVersionFn(isCheckingNewVersion: boolean) {
    this.setState({
      checkingNewVersion: isCheckingNewVersion
    });
  }

  newVersionAvailableFn(newVersionAvailable: boolean) {
    this.setState({
      newVersionAvailable: newVersionAvailable
    });
  }

  changeCulture(newCulture: string) 
  {
    this.setState({
      currentCulture: newCulture
    });
  }

  setAuthenticated(authenticated: boolean)
  {
    this.setState({
      isLoggedIn: authenticated
    });
  }

  

  handleApiError(response: Response)
  {
      switch(response.status)
      {
        case 401:
          document.location.href = '/account/signin';
          break;
      
        case 403:
          document.location.href = '/forbidden';
          break;

        case 404:
          document.location.href = '/notfound';
          break;

        case 400:
        case 500:
        default:
          this.setState({
            appError: "Ooops! An unexpected error occurred."
          });
          break;
      }
     
  }

  render () {
    return (
      <Layout appState={this.state} newVersionAvailable={this.state.newVersionAvailable} checkingNewVersion={this.state.checkingNewVersion}>
        <ScrollToTop appState={this.state}>
        {this.state.appError !== "" && <AppError appState={this.state} />}
        {this.state.appError === "" && <Switch >
          <Route exact path='/' render={() => <Home appState={this.state} />} />
          <Route exact path='/home' render={() => <Home appState={this.state} />} />
          <Route exact path='/cookies-policy' render={() => <CookiesPolicy appState={this.state} />} />
          <Route exact path='/privacy-policy' render={() => <PrivacyPolicy appState={this.state} />} />
          <Route exact path='/licensing' render={() => <Licensing appState={this.state} />} />
          <Route exact path='/contact' render={() => <Contact appState={this.state} />} />
          <Route exact path='/contact/sent' render={() => <ContactSent appState={this.state} />} />
          <Route exact path='/training' render={() => <Training appState={this.state} />} />
          <Route exact path='/customer-stories' render={() => <CustomerStories appState={this.state} />} />
          <Route exact path='/pricing'  render={() => <Pricing appState={this.state} />} />
          <Route exact path='/quote' render={() => <QuoteTemp appState={this.state} />} />
          <Route exact path='/quote/sent' render={() => <QuoteSent appState={this.state} />} />
          <Route exact path='/account/signup' render={() => <SignUp appState={this.state} />} />
          <Route exact path='/account/registered' render={() => <Registered appState={this.state} />} />
          <Route exact path='/account/signin' render={() => <SignIn appState={this.state} />} />
          <Route exact path='/account/confirm-email' render={() => <ConfirmEmail appState={this.state} />} />
          <Route exact path='/account/forgot-password' render={() => <ForgotPassword appState={this.state} />} />
          <Route exact path='/account/forgot-password/sent' render={() => <ForgotPasswordSent appState={this.state} />} />
          <Route exact path='/account/reset-password' render={() => <ResetPassword appState={this.state} />} />
          <Route exact path='/account/reset-password/sent' render={() => <ResetPasswordSent appState={this.state} />} />
          <Route exact path='/account/dashboard' render={() => <Dashboard appState={this.state} />} />
          <Route exact path='/account/billing' render={() => <Billing appState={this.state} />} />
          <Route exact path='/get-started/overview' component={Overview} />
          <Route exact path='/get-started/installation' component={Installation} />
          <Route exact path='/get-started/your-first-test' component={YourFirstTest} />
          <Route exact path='/get-started/plugins' component={HowToTestAPlugin} />
          <Route exact path='/get-started/codeactivities' component={HowToTestACodeActivity} />
          <Route exact path='/get-started/queries' component={Queries} />
          <Route exact path='/get-started/nn-relationships' component={ManyToManyRelationships} />
          <Route exact path='/get-started/others' component={OtherExamples} />
          <Route path='/get-started' component={GetStarted} />
          <Route exact path='/white-paper' render={() => <WhitePaper appState={this.state} />} />
          <Route exact path='/white-paper/sent' render={() => <WhitePaperSent appState={this.state} />} />
          <Route exact path='/newsletter' render={() => <Newsletter appState={this.state} />} />
          <Route exact path='/newsletter/subscribed' render={() => <NewsletterSubscribed appState={this.state} />} />
          <Route exact path='/forbidden' render={() => <Forbidden appState={this.state} />} />
          <Route exact path='/notfound' render={() => <NotFound appState={this.state} />} />
        </Switch>}
        </ScrollToTop>
      </Layout>
      
    );
  }
}
