import React, { Component } from 'react';
import { EmailInput } from '../Input/EmailInput';
import { getLiteralsForCulture } from '../../literals/Literals';
import { CheckBoxInputState, InputState } from '../Input/types';
import { AppProps } from '../AppState';
import { Help } from '../Help/Help';
import { Redirect } from 'react-router';
import { AppModal } from '../Modal/AppModal';
import { NewsLetterLiterals } from '../../literals/newsletter/NewsLetterLiterals';
import { PostJson } from '../../services/FetchService';
import { CheckBoxInput } from '../Input/CheckBoxInput';

interface SubmitModel 
{
  email: string;
};

class NewsletterState 
{
    emailState?: InputState = undefined;
    acceptedTermsOfService?: CheckBoxInputState = undefined;
    isSubmitting: boolean = false;
    validateForm: boolean = false;
    
    showSubmitError: boolean = false;
    submitError: string = "";

    redirect?: string = undefined;

    constructor()
    {

    }

    
};

export class Newsletter extends Component<AppProps, NewsletterState> {
  constructor(props: any)
  {
      super(props);
      this.emailOnChange = this.emailOnChange.bind(this);
      this.submit = this.submit.bind(this);
      this.hideError = this.hideError.bind(this);

      this.state = new NewsletterState();
  }

  emailOnChange = (newState: InputState) => {
    this.setState({
        emailState: newState
    });
  }

  acceptTermsOfServiceOnChange = (checkBoxState: CheckBoxInputState) => {
    this.setState({
      acceptedTermsOfService: checkBoxState
    });
  }
  
  toSubmitModel() : SubmitModel {
    return {
      email: this.state.emailState ? this.state.emailState.value : ""
    };
  }

  hideError() : void {
    this.setState({
      showSubmitError: false
    });
  }
  submit = () => {

    var self = this;

    this.setState({
      validateForm: true
    });

    
    if(this.state.emailState?.isValid
      && this.state.acceptedTermsOfService?.isValid)

      {
        this.setState({
          isSubmitting: true
        });

        PostJson("/api/newsletter/submit", self.toSubmitModel(), this.props.appState, result => {
          if(result.succeeded)
              {
                this.setState({
                  redirect: "/newsletter/subscribed"
                });
              }
              else {
                this.setState({
                  isSubmitting: false,
                  submitError: result.errorMessage,
                  showSubmitError: true
                });
              }
        }, this.props.appState.handleApiErrorFn);
      }
  }

  render () {
    if(this.state.redirect)
    {
      return (<Redirect to={this.state.redirect} />);
    }

    const literals = getLiteralsForCulture(NewsLetterLiterals, this.props.appState.currentCulture) as any;
    var shouldValidateForm = this.state.validateForm;

    return (
      <div>
         <AppModal isOpen={this.state.showSubmitError} title={literals.contactLabel} bodyText={this.state.submitError} onClickOk={this.hideError} />

          <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                      <h2>{literals.title}</h2>

                      <p>{literals.description}</p>                     
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-6">
                        <form id="contact">
                        <div className="row">
                            <div className="form-group col-md-offset-2 col-md-5">
                                <label htmlFor="email">{literals.emailAddressLabel }</label>
                                <EmailInput id="email" name="email" placeHolder={literals.yourEmailPlaceHolder} 
                                    displayValidation={shouldValidateForm} isMandatory={true} mandatoryErrorMessage={literals.emailMandatoryErrorLabel} 
                                    validationErrorMessage={literals.emailNotValidLabel} 
                                    onChange={this.emailOnChange} 
                                    value={this.state.emailState?.value}></EmailInput>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group  col-md-offset-2 col-md-10">
                                    <CheckBoxInput id="acceptTermsOfServiceConfirmation" name="acceptTermsOfServiceConfirmation" 
                                      isMandatory={true} displayValidation={shouldValidateForm} mandatoryErrorMessage={literals.mustAcceptedTosLabel} 
                                      onChange={this.acceptTermsOfServiceOnChange}
                                      checked={this.state.acceptedTermsOfService?.checked}
                                      >
                                        <span dangerouslySetInnerHTML={{__html: literals.consentText}}></span>
                                    </CheckBoxInput>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-md-offset-2 col-md-4">
                            {this.state.isSubmitting && <button className="btn btn-primary" disabled={true}>{literals.submittingLabel}</button>}
                            {!this.state.isSubmitting && <button className="btn btn-primary" onClick={this.submit} >{literals.submitLabel}</button>}
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="row">
                      <div className="col-md-offset-2 col-md-10">
                          <Help appState={this.props.appState} />
                      </div>
                    </div>
                  </div>
               </div>
          </section>
      </div>
    );
  }
}
