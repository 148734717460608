import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HomeLiterals } from '../../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export const HomeWhyFakeXrmSection = (appProps: AppProps) => 
{
    const literals = getLiteralsForCulture(HomeLiterals, appProps.appState.currentCulture) as any;
    return (
        <section className="theme-green bordered" style={{overflowX: "hidden"}}>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>{literals.whyFakeXrmEasyLabel}</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>{literals.forYourBusinessLabel}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 text-center animation-element slide-left">
                    <span className="fa fa-check fa-4x green"></span>
                    <h2>{literals.qualityLabel}</h2>
                    <p>{literals.qualityDescLabel}</p>
                </div>
                <div className="col-md-6 text-center animation-element slide-right">
                    <span className="fa fa-upload fa-4x green"></span>
                    <h2>{literals.confidenceLabel}</h2>
                    <p>{literals.confidenceDescLabel}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 text-center animation-element slide-left">
                    <span className="fa fa-hourglass-half fa-4x green"></span>
                    <h2>{literals.efficiencyLabel}</h2>
                    <p>{literals.efficiencyDescLabel}</p>
                </div>
                <div className="col-md-6 text-center animation-element slide-right">
                    <span className="fa fa-line-chart fa-4x green"></span>
                    <h2>{literals.addedvalueLabel}</h2>
                    <p>{literals.addedValueDescLabel}</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 text-center">
                    <h2>{literals.forDevelopersLabel}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h3>{literals.testLabel}</h3>
                </div>
                <div className="col-md-6 text-center animation-element slide-left">
                    <span className="fa fa-code fa-4x green"></span>
                    <h2>{literals.fromVisualStudioLabel}</h2>
                    <p>{literals.fromVisualStudioDescLabel}</p>
                </div>
                <div className="col-md-6 text-center animation-element slide-right">
                    <span className="fa fa-user-secret fa-4x green"></span>
                    <h2>{literals.withoutBeingAnExpertLabel}</h2>
                    <p>{literals.withoutBeingAnExpertDescLabel}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 text-center animation-element slide-left">
                    <span className="fa fa-bolt fa-4x green"></span>
                    <h2>{literals.superFastLabel}</h2>
                    <p>{literals.superFastDescLabel}</p>
                </div>
                <div className="col-md-6 text-center  animation-element slide-right">
                    <span className="fa fa-smile-o fa-4x green"></span>
                    <h2>{literals.enjoyLabel}</h2>
                    <p>{literals.enjoyDescLabel}</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <div style={{marginLeft: "0 auto", marginRight: "0 auto", textAlign: "center"}}>
                        <Link className="btn btn-lg btn-primary" to="/get-started/overview">{literals.getStartedWithFakeXrmEasyLabel}</Link>
                    </div>
                </div>
            </div>
        </section>
    );
    
}