import $ from "jquery";

var AnimationComponentUtils =
{
    checkIfInView($window: any)  
    {
      var $animation_elements = $('.animation-element');
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);
    
      $.each($animation_elements, function () {
          var $element = $(this);
          var element_height = $element.outerHeight() as number;
          var element_top_position = ($element.offset() as JQueryCoordinates).top;
          var element_bottom_position = (element_top_position + element_height);
    
          //check to see if this current container is within viewport
          if ((element_bottom_position >= window_top_position) &&
              (element_top_position <= window_bottom_position)) {
              $element.addClass('in-view');
          } else {
              $element.removeClass('in-view');
          }
      });
    },
    registerEvents(window: Window)
    {
        window.addEventListener('scroll', (event) => {
            var $window = $(window);
            AnimationComponentUtils.checkIfInView($window);
          });
      
          window.addEventListener('resize', (event) => {
            var $window = $(window);
            AnimationComponentUtils.checkIfInView($window);
          });
    }
};

export { AnimationComponentUtils };
