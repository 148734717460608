const RegisterLiterals = {

    registerLabel: {
        "en-GB": "Sign Up",
        "es-ES": "Registro",
        "ca-ES": "Registre"
    },
    registerSubHeadingLabel: {
        "en-GB": "Please enter your email address and a brand new password to create an account.",
        "es-ES": "Por favor introduzca su email y una nueva contraseña para crear su cuenta.",
        "ca-ES": "Si us plau, introdueixi el seu email i una contrassenya nova per crear el seu compte."
    },
    emailLabel: {
        "en-GB": "Email",
        "es-ES": "Email",
        "ca-ES": "Email"
    },
    passwordLabel: {
        "en-GB": "Password",
        "es-ES": "Contraseña",
        "ca-ES": "Contrassenya"
    },
    confirmPasswordLabel: {
        "en-GB": "Confirm Password",
        "es-ES": "Confimar Contraseña",
        "ca-ES": "Confirmar Contrassenya"
    },
    submitLabel: {
        "en-GB": "Submit",
        "es-ES": "Enviar",
        "ca-ES": "Envia"
    },
    submittingLabel: {
        "en-GB": "Submitting...",
        "es-ES": "Enviando...",
        "ca-ES": "Enviant..."
    },
    iAgreeToTheLabel: {
        "en-GB": "I agree to the",
        "es-ES": "He leído y acepto las",
        "ca-ES": "He llegit i accepto les"
    },
    legalNoticeLabel: {
        "en-GB": " Terms of Service ",
        "es-ES": " Condiciones del Servicio ",
        "ca-ES": " Condicions del Servei "
    },
    andTheLabel: {
        "en-GB": " and the ",
        "es-ES": " y la ",
        "ca-ES": " i la "
    },
    privacyPolicyLabel: {
        "en-GB": " Privacy Policy",
        "es-ES": " Política de Privacidad",
        "ca-ES": " Política de Privacitat"
    },
    mustAcceptedTosLabel: {
        "en-GB": " Please, you must accept the terms of service and privacy policy",
        "es-ES": " Por favor, debe aceptar las condiciones de uso y política de privacidad",
        "ca-ES": " Si us plau, ha d'acceptar les condicions d'ús i la política de privacitat"
    },
    sentLabel: {
        "en-GB": " Thank You! We've received your request, you should receive an email to validate your account soon.",
        "es-ES": " Gracias! Hemos recibido to solicitud correctamente. En breve recibirás un correo para validar tu cuenta.",
        "ca-ES": " Gràcies! Hem rebut la teva sol·licitud correctament. En breu rebràs un correu per validar el teu compte."
    },
    minPasswordLengthError: {
        "en-GB": "Please, password must be at least 8 characters",
        "es-ES": "Por favor, la contraseña debe tener al menos 8 caracteres.",
        "ca-ES": "Si us plau, la contrassenya ha de tenir com a mínim 8 caracters"
    },
    passwordsDontMatch: {
        "en-GB": "Passwords don't match",
        "es-ES": "Las contraseñas no coinciden",
        "ca-ES": "Les contrassenyes no coincideixen"
    },
    emailMandatoryErrorLabel: {
        "en-GB": "Please, email is mandatory",
        "es-ES": "Por favor, el email es obligatorio",
        "ca-ES": "Si us plau, el email és obligatori"
    },
    passwordMandatoryErrorLabel: {
        "en-GB": "Please, password is mandatory",
        "es-ES": "Por favor, la contraseña es obligatoria",
        "ca-ES": "Si us plau, la contrassenya és obligatòria"
    },
    confirmPasswordMandatoryErrorLabel: {
        "en-GB": "Please, confirm password is mandatory",
        "es-ES": "Por favor, la confirmación de contraseña es obligatoria",
        "ca-ES": "Si us plau, la confirmació de contrassenya és obligatòria"
    },
    emailNotValidErrorLabel: {
        "en-GB": "Please, email is not valid",
        "es-ES": "Por favor, el email no es válido",
        "ca-ES": "Si us plau, el email no és vàlid"
    },
    passwordNotValidErrorLabel: {
        "en-GB": "Please, password is not valid. Make sure it's at least 8 characters long, with at least 1 lowercase alphabetical character, 1 uppercase alphabetical character, 1 numeric character, and one special character from this list: [!,@,#,$,%,^,&,*]",
        "es-ES": "Por favor, la contraseña no es válida. Debe tener al menos 8 caracteres, con mínimo 1 carácter alfanumérico minúscula, 1 carácter alfanumérico mayúscula, 1 carácter numérico, y un carácter especial de la siguiente lista: [!,@,#,$,%,^,&,*]",
        "ca-ES": "Si us plau, la contrassenya no és vàlida. Ha de tenir com a mínim 8 caracters, amb mínim 1 lletra minúscula, 1 lletra majúscula, 1 dígit, i un caràcter especial de llista següent: [!,@,#,$,%,^,&,*]"
    },
};

export { RegisterLiterals };