import React, { Component, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { HomeLiterals } from '../../literals/home/HomeLiterals';
import { getLiteralsForCulture } from '../../literals/Literals';
import { AppProps } from '../AppState';

export const Community = (appProps: AppProps) => {

    const literals = getLiteralsForCulture(HomeLiterals, appProps.appState.currentCulture) as any;
    
    var colStyle = {marginLeft: "0 auto", marginRight: "0 auto", textAlign: "center", width: "100%", marginTop: "10px", marginBottom: "10px"} as CSSProperties;

    return (
        <section className="theme-dark-gray bordered" style={{overflowX: "hidden"}}>
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1>{literals.communityLabel}</h1>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-md-6">
                    <div className="twitter-tweet twitter-tweet-rendered" style={colStyle}>
                        <blockquote><p lang="en" dir="ltr"><a href="https://twitter.com/jordimontana?ref_src=twsrc%5Etfw">@jordimontana</a> showing how easy is to unit test <a href="https://twitter.com/hashtag/Dynamics365?src=hash&amp;ref_src=twsrc%5Etfw">#Dynamics365</a> with <a href="https://twitter.com/hashtag/fakexrmeasy?src=hash&amp;ref_src=twsrc%5Etfw">#fakexrmeasy</a> Great library! <a href="https://twitter.com/hashtag/crmsaturday?src=hash&amp;ref_src=twsrc%5Etfw">#crmsaturday</a> <a href="https://t.co/9aR5KuzzTz">pic.twitter.com/9aR5KuzzTz</a></p>&mdash; Marco Amoedo (@marcoamoedo) <a href="https://twitter.com/marcoamoedo/status/825386756035977217?ref_src=twsrc%5Etfw">January 28, 2017</a></blockquote>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="twitter-tweet twitter-tweet-rendered" style={colStyle}>
                        <blockquote><p lang="en" dir="ltr">Unit testing <a href="https://twitter.com/hashtag/MSDYN365?src=hash&amp;ref_src=twsrc%5Etfw">#MSDYN365</a> by <a href="https://twitter.com/jordimontana?ref_src=twsrc%5Etfw">@jordimontana</a> <a href="https://twitter.com/hashtag/fakexrmeasy?src=hash&amp;ref_src=twsrc%5Etfw">#fakexrmeasy</a> <a href="https://twitter.com/hashtag/crmsaturday?src=hash&amp;ref_src=twsrc%5Etfw">#crmsaturday</a> <a href="https://twitter.com/hashtag/londonislovinit?src=hash&amp;ref_src=twsrc%5Etfw">#londonislovinit</a> <a href="https://t.co/kLU2EMthWC">pic.twitter.com/kLU2EMthWC</a></p>&mdash; POWER COMMUNITY ⚡ #Microsoft365 Bootcamp 20th AUG (@Powerthon) <a href="https://twitter.com/Powerthon/status/825389248715116544?ref_src=twsrc%5Etfw">January 28, 2017</a></blockquote>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="twitter-tweet twitter-tweet-rendered" style={colStyle}>
                        <blockquote><p lang="en" dir="ltr">UnitTesting CRM 2011+ is made much less trying and infuriating using <a href="https://twitter.com/hashtag/FakeXrmEasy?src=hash&amp;ref_src=twsrc%5Etfw">#FakeXrmEasy</a>. Its saved me hours already this week.</p>&mdash; mauro masucci (@chairman_mau) <a href="https://twitter.com/chairman_mau/status/826830580365025282?ref_src=twsrc%5Etfw">February 1, 2017</a></blockquote>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="twitter-tweet twitter-tweet-rendered" style={colStyle}>
                        <blockquote><p lang="en" dir="ltr">Perfect framework for unit testing of Dynamics CRM! This makes life so much easier! Open source AND great documentation! <a href="https://twitter.com/hashtag/FakeXrmEasy?src=hash&amp;ref_src=twsrc%5Etfw">#FakeXrmEasy</a></p>&mdash; Arjen Stortelder (@arjenstortelder) <a href="https://twitter.com/arjenstortelder/status/828933452250615808?ref_src=twsrc%5Etfw">February 7, 2017</a></blockquote>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <p>You can also share your  <i className="fa fa-heart" style={{color: "#ea6e97"}}></i>  for #FakeXrmEasy on Twitter and LinkedIn</p>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 text-center">
                    <div  style={{marginLeft: '0 auto', marginRight: '0 auto', textAlign: 'center'}}>
                        <Link className="btn btn-lg btn-primary" to={"/get-started/overview"}>{literals.getStartedWithFakeXrmEasyLabel}</Link>
                        <h4 className="launch-heading">{literals.backedByLabel}</h4>
                    </div>
                </div>
            </div>

        </section>
    );
    
}