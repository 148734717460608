import React, { Component } from 'react';
import { AppProps } from './AppState';


export const NotFound = (appProps: AppProps) => 
 
    (<div>
        <section className="theme-dark-gray">
              <div className="row">
                  <div className="form-group col-md-offset-1 col-md-10">
                      <h2>404: Ouch, something wasn't found</h2>
                  </div>
              </div>
        </section>
    </div>);

